import React, { useEffect, useState } from "react";
import unCheck from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import arrow from "../../assets/icon/chevron-up-small.svg";
import { useNavigate } from "react-router-dom";
import { saveProfileSettingToFirebase, getProfileSettingFirebase, getUserData } from "../../firebase/firebase";
import { useAccount } from "wagmi";
import { toast } from "react-toastify";

export default function ProfileSetting(props) {
  const navigate = useNavigate()
  const { address } = useAccount();
  const [isWhiteList, setIsWhiteList] = useState(false);
  const [privateStatus, setPrivateStatus] = useState(false);


  useEffect(() => {
    const checkIfUserIsWhiteListed = async () => {
      let res = await (getUserData(address));


      if (res?.isWhiteListed) {
        setIsWhiteList(true);
      }
    }
    checkIfUserIsWhiteListed();

  }, [address])

  // storing profile setting to firebase

  const handleNotifications = () => {
    if (isWhiteList) {
      toast.info(`As a whitelisted artist, your profile is visible to everyone. `);
      setPrivateStatus(false);
      return;
    }

    const updatedValue = !privateStatus;
    setPrivateStatus(updatedValue);
    // Call saveProfileSettingToFirebase with the updated state
    saveProfileSettingToFirebase(updatedValue, address);
    toast.success(`Settings updated successfully`);

  };

  // get profile setting from firebase

  const getProfileSetting = async () => {
    const profileSetting = await getProfileSettingFirebase(address);
    if (profileSetting && profileSetting.hasOwnProperty("private")) {
      setPrivateStatus(profileSetting.private);
    }
  };

  useEffect(() => {
    getProfileSetting();
  }, []);

  return (
    <div>
      <section className={`settings site-container ${props.activeClass}`}>

        <h6
          className="  mt-0 v-center hide-on-desktop bb-lightest-grey pb-4"
          onClick={() => navigate("/account-settings")}
        >
          <img src={arrow} alt="arrow" className="me-3 rotate-270  " />
          Profile settings
        </h6>

        <div className="notification">
          {/* Private profile */}
          <div className="notification-box mh-80 v-center">
            <p
              className={`body-large fw-normal v-center pointer ${privateStatus ? "selected" : ""}`}
              onClick={handleNotifications}
            >
              <img src={privateStatus ? checked : unCheck} alt="checkbox" />
              Private profile
            </p>
            <p className="text-medium-grey">Private</p>
          </div>
        </div>
      </section>
    </div>
  );
}
