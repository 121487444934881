import React, { useState, useEffect, useContext } from "react";
import { getCollections } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import Button from "./button";
import LayoutGrid from "./profile/layoutGrid";
import ArtistPopUp from "./shared/artistpopup";
import verified from "../assets/icon/verified-artist-small.svg";
import profile from "../assets/icon/profile-picture.svg";
import Profile from "../assets/icon/profile-picture.svg";
import filter from "../assets/icon/filter.svg";
import imgg from "../assets/images/collectionPlaceholder.png";
import sortby from "../assets/icon/sort-by.svg";
import tick from "../assets/icon/tick-large-black.svg";
import close from "../assets/icon/close.svg";
import Footer from "./footer";
import CardSkelton from "./shared/cardSkelton";
import { ClearAllContext } from "./explore/ClearAllContext";
import { useAccount } from "wagmi";
import {
  getAllUsers,
  getCollectionStats,
  getUserData,
} from "../firebase/firebase";
import { useLocation } from "react-router-dom";
import { Popover } from "antd";
import { Offcanvas } from "react-bootstrap";
import AsideFilterExplore from "./explore/asideFilterExplore";
import AsideCollectionFilter from "./explore/asideCollectionFilter";
import { toast } from "react-toastify";

function AllCollections(props) {
  const { clearAll, setClearAll } = useContext(ClearAllContext);
  const [collections, setCollections] = useState([]);
  const navigate = useNavigate();
  const [hide, setHide] = useState(false);
  const [Flag, setFlag] = useState(false);
  const location = useLocation();
  const { address } = useAccount();
  const [userProfile, setProfile] = useState(Profile);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(true);
  const getUserCollections = async () => {
    const usercollections = await getCollections();
    const filterCollection = usercollections?.filter(
      (item) => item?.data?.isWhiteList === true
    );
    setCollections(filterCollection);
    setLoading(false);
  };
  useEffect(() => {
    getUserCollections();
  }, []);
  // const { clearAll, setClearAll } = useContext(ClearAllContext);
  const getprofile = async (address) => {
    if (address) {
      let res = await getUserData(address);
      if (res) {
        return res?.image;
      } else {
        return null;
      }
    }
  };


  const [userData, setUserData] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);

  const [collectionArray, setCollectionArray] = useState([]);

  useEffect(() => {
    collections.map(async (collection) => {
      const collectionData = await getCollectionStats(collection?.documentId);
      setCollectionArray((prev) => [...prev, collectionData]);
    });
    setCollectionDetails(collectionArray);
  }, [collections]);


  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);

  const getArtistNamebyAdress = (ArtistAddress) => {
    let artistName = "";
    if (address === ArtistAddress) {
      return "You";
    }

    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistName = user?.userName;
      }
    });

    return "@" + artistName;
  };

  const getArtistNamebyAdressProfile = (ArtistAddress) => {
    let artistName = "";
    if (address === ArtistAddress) {
      return "You haven't";
    }

    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistName = user?.userName;
      }
    });

    return "@" + artistName + " hasn't";
  };

  const getImageByAddress = (ArtistAddress) => {
    let artistImage = Profile;

    userData?.map((user) => {
      if (user?.id === ArtistAddress) {
        artistImage = user?.image;
      }
    });

    return artistImage;
  };

  // ************************** Filter
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showSideFilter, setShowSideFilter] = useState(false);

  // to show and hide collection multi filter
  const showMobileSideFilter = () => {
    setHide(!hide);
    if (windowWidth < 1000) {
      setShowSideFilter(true);
    } else {
      setFlag(!Flag);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 992) {
        setShowSideFilter(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  // ======================collection sorting filter
  const collectionSortFilter = [
    { value: "Newest", label: "Newest" },
    { value: "Oldest", label: "Oldest" },
  ];
  const [collectionMobileFilter, setCollectionMobileFilter] = useState(false);
  const [activeCollection, setActiveCollection] = useState("");
  const hideMobileSortFilter = () => setCollectionMobileFilter(false);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  const showMobileSortFilter = () => {
    setCollectionMobileFilter(true);
  };

  const handleCollectionTick = (tick) => {
    setActiveCollection(tick);
    setCollectionMobileFilter(false);
  };
  const [sortedCollections, setSortedCollections] = useState([]);


  useEffect(() => {
    let sortedCollections = [...collections];

    if (activeCollection === "Newest") {
      sortedCollections.sort((a, b) => b.data.timestamp - a.data.timestamp);
    } else if (activeCollection === "Oldest") {
      sortedCollections.sort((a, b) => a.data.timestamp - b.data.timestamp);
    }

    const filCollections = sortedCollections?.filter((col) => {
      // Find the user with the matching ID
      const owner = userData.find((user) => user.id === col.data.address);

      // Check if the owner is found and is not blacklisted
      return owner && !owner.isBlackListed;
    });


    if (props.loc === "profile") {
      const filteredCollections = filCollections.filter(
        (e) => e.data.address === props.data.id
      );
      setSortedCollections(filteredCollections);
    } else {
      setSortedCollections(filCollections);
    }

    // setCollections(sortedCollections);
  }, [activeCollection, collections, props]);

  const [filteredCollections, setFilteredCollections] = useState([]);
  const [filtersSelected, setFiltersSelected] = useState(false);
  const handleFilterChange = (newFilter) => {
    const selectedCurrencies = newFilter.selectCurrency;


    // Check which currency is selected
    const selectedCurrency = Object.keys(selectedCurrencies).find(
      (currency) => selectedCurrencies[currency] === true
    );


    if (selectedCurrency) {
      setFiltersSelected(true);
    }
    else {
      setFiltersSelected(false);
    }

    if (selectedCurrency === "allChains") {
      // If "allChains" is selected, set all collections
      setFilteredCollections(sortedCollections);
      return;
    }
    if (selectedCurrency) {
      // Filter collections based on the selected currency
      const filtered = sortedCollections.filter((item) => {
        let network = (item?.data?.selectedNetwork === "Flare Mainnet") ? "flr" : "sgb";

        return network === selectedCurrency;
      });

      setFilteredCollections(filtered);
    }
  };

  // Trigger filtering logic on component mount and when sortedCollections change
  useEffect(() => {
    // Simulate the filter change with the initial default state
    const initialFilter = {
      selectCurrency: {
        allChains: true,
        flr: false,
        sgb: false,
        Coston: false,
        Coston2: false,
      },
    };

    handleFilterChange(initialFilter);
  }, [sortedCollections]); // Re-run if sortedCollections change


  const clearFilter = () => {
    setClearAll(true);
  };

  const [whitelistedCollection, setWhitelistedCollection] = useState([]);

  useEffect(() => {

    const filteredNfts = filteredCollections?.filter((col) => {


      // Find the user with the matching ID
      const owner = userData.find((user) => user.id === col.data.address);


      // Check if the owner is found and is not blacklisted
      return owner && owner.isBlackListed == false;
    });



    // Update the state with the new array
    setWhitelistedCollection(filteredNfts);


  }, [filteredCollections, userData])
  return (
    <>
      {props.loc === "profile" &&
        filteredCollections.length === 0 && !filtersSelected &&
        !loading ? (
        <div className="no-content mt-5 pt-5  ">
          <p className="body-large">
            {getArtistNamebyAdressProfile(props?.data?.id)} <span>created</span>{" "}
            any collections yet.
          </p>
          <Button
            text="Create collection"
            width="166px"
            height="36px"
            className="btn-prime btn-primary"
            onClick={() => {
              navigate("/create-collections");
            }}
          />
        </div>
      ) : (
        <div className="collection-filter">
          <div className="left">
            <div
              className="show-filter cursor-pointer"
              onClick={showMobileSideFilter}
            >
              <span>
                <img src={filter} alt="filter" id="filter" />
              </span>
              <label
                htmlFor="filter"
                className="medium text-black cursor-pointer ms-1 hide-on-mobile"
                onClick={() => setShow(!show)}
              >
                {hide ? "Hide Filters" : "Show Filters"}
              </label>
            </div>
            <div className="t-items">
              {hide ? (
                <label onClick={clearFilter} className="medium ms-4 pointer">
                  Clear All
                </label>
              ) : (
                <label className="medium ms-4">
                  {whitelistedCollection.length} Items
                </label>
              )}
            </div>
          </div>

          <div className="right">
            <div className="recent-collection filter dropdown hide-on-mobile">
              <p
                className="body-medium dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="hide-on-mobile">
                  {
                    collectionSortFilter.find(
                      (option) => activeCollection === option.value
                    )?.label
                  }
                </span>
                <span>
                  <img src={sortby} alt="sortby" />
                </span>
              </p>
              <ul className="dropdown-menu">
                {collectionSortFilter.map((option) => (
                  <li
                    className="dropdown-item"
                    key={option.value}
                    onClick={() => handleCollectionTick(option.value)}
                  >
                    <img
                      src={tick}
                      alt="tick"
                      className={`${activeCollection === option.label
                        ? "active opacity-100"
                        : "opacity-0"
                        }`}
                    />
                    {option.label}
                  </li>
                ))}
              </ul>
            </div>

            <div className="recent-collection filter hide-on-desktop">
              <p className="body-medium" onClick={showMobileSortFilter}>
                <span className="hide-on-mobile">
                  {
                    collectionSortFilter.find(
                      (option) => option.value === option.value
                    )?.label
                  }
                </span>
                <span>
                  <img src={sortby} alt="sortby" />
                </span>
              </p>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className={`grid-display artworkGrid ? "w-75 active" : ""`}>
          <CardSkelton />
          <CardSkelton />
          <CardSkelton />
          <CardSkelton />
        </div>
      ) : (
        <>
          <div
            className={
              hide ? "d-flex align-item-center justify-content-between" : ""
            }
          >
            <div className={`my-filter ${hide ? "w-25" : "d-none"}`}>
              <AsideCollectionFilter
                onSelectedFilterChange={handleFilterChange}
              />
            </div>

            <div
              className={`grid-display artworkGrid ${hide ? "w-75 active" : ""
                }`}
            >
              {whitelistedCollection?.map((item, index) => {
                let artCount = "--";
                let collectorCount = "--";
                collectionArray.map((collection) => {
                  if (collection?.collectionId === item?.documentId) {
                    artCount = collection?.artworkCount;
                    collectorCount = collection?.owners?.length
                      ? collection?.owners?.length - 1
                      : 0;
                  }
                });
                return (
                  <div className="collection-grid-card" key={index}>
                    <div className="card-head">
                      <div className="user-img">
                        {item ? (
                          <img
                            src={
                              getImageByAddress(item?.data?.address) || Profile
                            }
                            className="img-100 rounded-circle"
                          />
                        ) : (
                          <img
                            src={Profile}
                            className="img-100 rounded-circle"
                          />
                        )}
                      </div>

                      <div className="user-name">
                        <p
                          onClick={() =>
                            navigate(
                              `/explore-collections/${item?.documentId}/`
                            )
                          }
                          className="body-large hover-underline pointer ellipsis-text-collection"
                        >
                          <span className="ellipsis-collection-name">
                            {item?.data?.name}
                          </span>


                        </p>

                        <Popover
                          placement="top"
                          trigger="hover"
                          content={
                            <>
                              {address && (
                                <ArtistPopUp
                                  userProfile={
                                    getImageByAddress(item?.data?.address) ||
                                    Profile
                                  }
                                  verified={verified}
                                  artistName={getArtistNamebyAdress(
                                    item?.data?.address
                                  )}
                                />
                              )}
                            </>
                          }
                        >
                          <p
                            onClick={() => navigate(`/profile/${item?.data?.address}/`)}
                            className="fw-bold text-medium-grey hoverBlack show-artist-popup ellipsis-container"
                          >
                            <span className="ellipsis-text-collection">
                              {getArtistNamebyAdress(item?.data?.address)}
                            </span>
                            <img
                              src={verified}
                              alt="verified"
                              className="img-18 ms-1"
                            />
                          </p>

                        </Popover>
                      </div>
                    </div>

                    <div
                      className="card-body"
                      onClick={() =>
                        navigate(`/explore-collections/${item.documentId}/`)
                      }
                    >
                      <div className="art-img">
                        {!item.data?.image ? (
                          <img src={imgg} className="img-100 artwork-hover" />
                        ) : (
                          <img
                            src={item?.data?.image}
                            alt="art"
                            className="img-100 artwork-hover"
                          />
                        )}
                      </div>
                    </div>

                    <div className="card-footer">
                      <div className="owner" style={{ maxWidth: "200px" }}>
                        <p className="body-medium text-medium-grey">
                          Collection Size
                        </p>
                        <p className="body-medium text-black fw-semibold">
                          {artCount} Artworks
                        </p>
                      </div>

                      <div className="offer">
                        <p className="body-medium text-medium-grey">
                          Collectors
                        </p>
                        <label className="medium text-black">
                          {collectorCount} Collectors
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}

      <Offcanvas
        show={collectionMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {collectionSortFilter.map((option) => (
              <label
                key={option.value}
                className={`no-text-transform h-64 text-black ${activeCollection === option.value ? "fw-bold" : "fw-normal"
                  }`}
                onClick={() => handleCollectionTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${activeCollection === option.value
                    ? "active opacity-100"
                    : "opacity-0"
                    }`}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>

      <Offcanvas
        show={showSideFilter}
        onHide={showMobileSideFilter}
        placement="bottom"
        className="sub-menu-offcanvas collection-multi-filter aside-filter-offcanvas"
      >
        <div className="more-menu-sm price-more-menu ">
          <div className="menu-head">
            <label htmlFor="" className="text-black">
              {/* {nftFilteredDetails?.length ? nftFilteredDetails?.length : "0"}{" "} */}
              Currency
            </label>
            {/* <label className="text-black multi-filter-head">Currency</label> */}
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={() => setShowSideFilter(false)}
              />
            </div>
          </div>

          <AsideCollectionFilter onSelectedFilterChange={handleFilterChange} />
        </div>
      </Offcanvas>
    </>
  );
}

export default AllCollections;
