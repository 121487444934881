import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import sale from "../../assets/icon/list-for-sale.svg";
import transfer from "../../assets/icon/transfer.svg";
import copyLink from "../../assets/icon/link.svg";
import view from "../../assets/icon/eye.svg";
import edit from "../../assets/icon/edit.svg";
import share from "../../assets/icon/share.svg";
import report from "../../assets/icon/report.svg";
import refresh from "../../assets/icon/refresh-metadata.svg";
import { useNavigate } from "react-router-dom";
import art from "../../assets/images/artwork2.png";
import Modal from "react-bootstrap/Modal";
import Button from "../button";
import Input from "../inputs";
import wallet from "../../assets/icon/wallet.svg";
import error from "../../assets/icon/close-small.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import tick from "../../assets/icon/tick-small-white.svg";
import config from "../../config";
import { networkType } from "../utility/urlStruc";
import Web3 from "web3";
import marketplaceContractABI from "../../abis/Marketplace/v2/abi.json";
import { handleCollectionHistory } from "../../firebase/firebase";
import { useSwitchNetwork, useNetwork } from "wagmi";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import { useAccount } from "wagmi";
import { NFTContext } from "../context/appContext";
import { useWalletClient } from "wagmi";

import {
  getCollectionDetailsFirebase,
  saveArtworkHistory,
  getUserData,
  handleNotifications,
  getAllUsers,
  getArtIdByUrl,
} from "../../firebase/firebase";

import { toast } from "react-toastify";
import { add } from "lodash";
import InputBox from "./inputBox";
const Dropdown = ({
  listed,
  item,
  nftDetails,
  row,
  owner,
  tokenId,
  LocationArt,
  artId,
}) => {
  const { data: signer } = useWalletClient();

  const { address } = useAccount();
  const [contractAddress, setContractAddress] = useState("");
  const [price, setPrice] = useState();
  const [isOwner, setIsOwner] = useState(false);
  const [Address, setAddress] = useState("");
  const [ArtName, setArtName] = useState(null);
  const [ArtImage, setArtImage] = useState(null);
  const [transferStatus, setTransferStatus] = useState("");
  const [user, setUser] = useState(null);
  const { reloadAfterTransfer, updateTransfer, sgbUsd, flrUsd } =
    useContext(NFTContext);
  const [details, setDetails] = useState([]);
  const [collectionName, setCollectionName] = useState(null);
  const [nftPrice, setNftPrice] = useState(null);
  const [listingModal, setListingModal] = useState("initial");
  const [type, setType] = useState();

  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  const handleSwitchChain = async () => {
    const switchTo = chains.find(
      (c) => c.name === item?.metadata?.data.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };

  useEffect(() => {
    if (nftDetails) {
      setCollectionName(nftDetails?.metadata?.data?.selectedCollection);
      setNftPrice(nftDetails?.listedData?.price);
    }
  }, [nftDetails]);
  useEffect(() => {
    if (nftDetails) {
      setDetails(nftDetails);
    }
  }, [nftDetails]);
  const getUser = async () => {
    let res = await getUserData(address);

    setUser(res);
  };

  useEffect(() => {
    getUser();
  }, [address]);

  useEffect(() => {
    if (LocationArt === "singleArt") {
      if (item?.metadata?.data.image) {
        setArtImage(item?.metadata?.data.image);
      } else {
        setArtImage(item?.metadata?.data.previewImg);
      }
      setArtName(item?.metadata?.data.artName);
    } else {
      if (item?.data.image) {
        setArtImage(item?.data.image);
      } else {
        setArtImage(item?.data.previewImg);
      }
      setArtName(item?.data.artName || item?.metadata?.data?.artName);
    }

    if (row === "row") {
      setArtImage(item?.metadata?.data?.image);
    }
  }, [item, nftDetails, LocationArt]);

  const handlePriceChange = (event) => {
    setPrice(event.target.value);
  };

  const getCollectionDetails = async () => {
    try {
      try {
        let result;
        if (LocationArt === "singleArt") {
          // alert("here");
          result = await getCollectionDetailsFirebase(
            item?.selectedCollectionId
          );
          // alert(result?.contractAddress);
          setContractAddress(result?.contractAddress);
        } else {
          result = await getCollectionDetailsFirebase(
            item?.data.selectedCollectionId || item?.selectedCollectionId
          );
        }
        if (result) {
          if (owner === address) {
            setIsOwner(true);
            setContractAddress(result?.contractAddress);
          }
        } else {
          setIsOwner(false);
        }
      } catch (error) {
        return;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getCollectionDetails();
  }, [item, Address]);

  const location = useLocation();

  const { marketplace, erc20 } = config;

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  // marketplace contract address
  const contractABI = mintContractABI;
  // const web3 = new Web3(window.ethereum);

  const [web3, setWeb3] = useState(null);

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const transferOwner = async (item) => {
    setArtTransfer(true);
  };
  const transferArt = async () => {
    if (address === Address) {
      toast.error("You cannot transfer Nft to your own address");
      return;
    }

    try {
      let web3;

      let contractAddress;
      let collectionId;
      let artData;
      // let artId;
      let artName;
      let selectedBlockchain;

      if (LocationArt === "singleArt") {
        // Single Art Case
        artData = item?.metadata?.data;
        collectionId = artData?.selectedCollectionId;
        // artId = artData?.artId;
        artName = artData?.artName;
        selectedBlockchain = artData?.selectedBlockchain;
      } else {
        // (Or Non-Single Art)
        artData = item?.data;
        collectionId = artData?.selectedCollectionId;
        // artId = artData?.artId;
        artName = artData?.artName;
        selectedBlockchain = artData?.selectedBlockchain;
      }

      if (selectedBlockchain === config.networks.songbird) {
        web3 = new Web3(config.rpc.songbird);
      }
      if (selectedBlockchain === config.networks.flare) {
        web3 = new Web3(config.rpc.flr);
      }

      // Fetch collection details from Firebase
      const result = await getCollectionDetailsFirebase(collectionId);

      contractAddress = result?.contractAddress;

      // Smart Contract Interaction
      const contract = new web3.eth.Contract(contractABI, contractAddress);

      let transaction = {
        from: address,
        data: contract.methods
          ._transferFrom(address, Address, tokenId)
          .encodeABI(),
        to: contractAddress,
      };

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });

                    const transactionHash = hash;

                    // Save transfer history
                    const data = {
                      action: "transfer",
                      artworkUri: artData,
                      from: address,
                      to: Address,
                      tokenId: tokenId,
                      transactionHash: transactionHash,
                    };

                    try {
                      await saveArtworkHistory(
                        transactionHash,
                        address,
                        "transfer",
                        user?.userName,
                        artId,
                        null,
                        Address
                      );
                    } catch (error) {
                      return;
                    }
                    handleCollectionHistory(collectionId, data);

                    const artLink = `/artwork/${networkType(
                      selectedBlockchain
                    )}/${collectionId}/${tokenId}`;
                    handleNotifications(
                      address,
                      "transfer",
                      null,
                      artName,
                      transactionHash,
                      selectedBlockchain,
                      artLink,
                      Address
                    );

                    setTransferStatus("success");
                    updateTransfer(true);

                    break;
                  } else {
                    reject({ success: false });
                    console.error("Transaction failed:", error);
                    toast.error("Transaction Failed!");
                  }

                  break;
                }
              } catch (error) {
                console.error("Transaction failed:", error);
                toast.error("Transaction Failed!");
              }
            }
          })

          .catch((error) => {
            reject({ success: false, error });
            console.error("Transaction failed:", error);
            toast.error("Transaction Failed!");
          });
      });
    } catch (error) {
      console.error("Transaction failed:", error);
      toast.error("Transaction Failed!");
    }
  };

  const handleCancelListing = () => {
    // Simulate blockchain confirmation process
    setListingModal("confirm");

    setTimeout(() => {
      // Simulate success or error response
      let success; // Change to false to simulate error
      try {
        handleCancelList();
        success = true;
      } catch (error) {}
    }, 2000);
  };
  const getArtistNamebyAdress2 = async (address) => {
    let artistName = null;
    let res = await getAllUsers(); // Assuming this fetches all users

    res?.forEach((user) => {
      if (user?.id === address) {
        artistName = user?.userName;
      }
    });

    // Show alert after the loop has completed
    return artistName;
  };

  const [ArtId, setArtId] = useState(null);
  const getArtId = async (nftUri) => {
    let res = await getArtIdByUrl(nftUri);
    setArtId(res);
    return res;
  };

  const handleCancelList = async () => {
    // Fetch the Art ID based on the item URI
    let ArtId = await getArtId(item?.data?.uri || item?.uri);

    // Initialize web3 and fetch accounts
    let web3_1;

    let MarketplaceAddress;
    let auctionListId;
    let blockchain;
    let collectionId;

    // Determine the blockchain and collection ID based on the item
    blockchain = item?.metadata?.data?.selectedBlockchain;
    collectionId = item?.metadata?.data?.selectedCollectionId;

    // Fetch the artist's address (current owner) and name
    let adr = item.newOwner || item.data.newOwner;
    let artistName = await getArtistNamebyAdress2(adr);

    // Set the Marketplace Address based on the selected blockchain
    if (blockchain === config?.networks.songbird) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
      web3_1 = new Web3(config.rpc.songbird);
    } else if (blockchain === config?.networks.flare) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
      web3_1 = new Web3(config.rpc.flr);
    }

    // Get auction list ID from the item
    auctionListId = item.listCount || item.data.listCount;

    // Create a new contract instance
    const contract = new web3_1.eth.Contract(
      marketplaceContractABI,
      MarketplaceAddress
    );

    try {
      // Create a transaction to cancel the listing

      let transaction = {
        from: address,
        to: MarketplaceAddress,
        data: contract.methods.CancelListForSale(auctionListId).encodeABI(),
        gasLimit: 3000000,
      };

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3_1.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });
                    setListingModal("successListing");
                    let action = "cancelListing";

                    // Save artwork history
                    try {
                      await saveArtworkHistory(
                        hash,
                        address,
                        action,
                        artistName,
                        ArtId,
                        price,
                        null
                      );
                    } catch (error) {
                      return;
                    }

                    // Send notifications
                    try {
                      const artLink = `/artwork/${blockchain}/${collectionId}/${tokenId}`;

                      await handleNotifications(
                        item.newOwner || item.data.newOwner,
                        "cancelListing",
                        price,
                        item?.metadata?.data?.artName || item?.data?.artName,
                        hash,
                        blockchain,
                        artLink,
                        item.newOwner || item.data.newOwner
                      );
                    } catch (error) {
                      return;
                    }

                    setEditListModal(false);

                    break;
                  } else {
                    reject({ success: false });
                    setListingModal("error");
                    toast.error("Transaction Failed!");
                  }

                  break;
                }
              } catch (error) {
                return;
              }
            }
          })

          .catch((error) => {
            reject({ success: false, error });
            setListingModal("error");
            toast.error("Transaction Failed!");
          });
      });

      // On success, update the modal state and record the history
    } catch (error) {
      // Handle transaction failure
      setListingModal("error");
      toast.error("Transaction Failed!");
    }
  };

  const pathToHideDiv = "/artwork";
  const shouldHideDiv = location.pathname === pathToHideDiv;
  const navigate = useNavigate();
  const [artTransfer, setArtTransfer] = useState(false);

  const [editListModal, setEditListModal] = useState(false);
  const [changePrice, setChangePrice] = useState(false);

  const handleEditListModal = () => {
    setEditListModal(false);

    setListingModal("initial");
  };
  const handleArtTransfer = () => {
    setArtTransfer(!artTransfer);
    setTransferStatus("");
    window.location.reload();
  };

  const showEditListing = () => {
    setEditListModal(true);
  };
  const navigateToSingleArtwork = async () => {
    const formatData = {
      ...item,
      meta: {
        data: item.data,
      },
    };

    const result = await getCollectionDetailsFirebase(
      item?.metadata?.data?.selectedCollectionId
    );
    let contractAddress = result?.contractAddress;

    navigate(
      `/artwork/${networkType(
        item.metadata.data.selectedBlockchain
      )}/${contractAddress}/${tokenId}`
    );
  };

  const navigateToSingleArtwork2 = async () => {
    const formatData = {
      ...item,
      meta: {
        data: item.data,
      },
    };

    const result = await getCollectionDetailsFirebase(
      item?.data?.selectedCollectionId
    );
    let contractAddress = result?.contractAddress;

    navigate(
      `/artwork/${networkType(
        item.data.selectedBlockchain
      )}/${contractAddress}/${tokenId}`
    );
  };

  const copyLinkToClipboard = async () => {
    const result = await getCollectionDetailsFirebase(
      item?.metadata?.data?.selectedCollectionId
    );
    let contractAddress = result?.contractAddress;

    let link;
    if (row === "row") {
      link = `https://www.the-catalyst.xyz/artwork/${networkType(
        item?.metadata?.data?.selectedBlockchain
      )}/${contractAddress}/${tokenId}`;
    } else {
      link = `${window.location.href}`;
    }
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copied");
      })
      .catch((error) => {
        toast.warning("Link copying Failed", error);
      });
  };
  const handlePrice = () => {
    if (!price) {
      toast.error("Enter price to continue");
      return;
    }
    // Simulate blockchain confirmation process
    setListingModal("confirm");
    HandlechangePrice();
  };
  const HandlechangePrice = async () => {
    let ArtId = await getArtId(item?.data?.uri || item?.uri);

    // Initialize web3 and accounts
    let web3_1;

    let MarketplaceAddress;
    let auctionListId;
    let blockchain;

    let collectionId;

    // Determine if it's singleArt or not and extract necessary data

    blockchain = item?.metadata?.data.selectedBlockchain;

    collectionId = item.metadata.data.selectedCollectionId;

    let adr = item.newOwner || item.data.newOwner;
    let artistName = await getArtistNamebyAdress2(adr);

    // Set Marketplace Address based on selected blockchain
    if (blockchain === config?.networks?.songbird) {
      web3_1 = new Web3(config.rpc.songbird);
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (blockchain === config?.networks?.flare) {
      web3_1 = new Web3(config.rpc.flr);
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    // Get auction list ID (common for both cases)
    auctionListId = item.listCount || item.data.listCount;

    // Create contract instance
    const contract = new web3_1.eth.Contract(
      marketplaceContractABI,
      MarketplaceAddress
    );

    const priceInWei = web3_1.utils.toWei(price.toString(), "ether");

    // Prepare transaction data
    const data = contract.methods.editListForSale(auctionListId, priceInWei);
    let transaction = {
      from: address,
      to: MarketplaceAddress,
      data: data.encodeABI(),
      gasLimit: 3000000,
    };
    return new Promise((resolve, reject) => {
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          for (let index = 0; index > -1; index++) {
            try {
              const receipt = await web3_1.eth.getTransactionReceipt(hash);

              if (receipt != null) {
                if (receipt.status) {
                  resolve({ success: true, hash });

                  setListingModal("successPrice");

                  let action = "priceChange";

                  try {
                    await saveArtworkHistory(
                      hash,
                      address,
                      action,
                      artistName,
                      ArtId,
                      price,
                      null
                    );
                  } catch (error) {
                    return;
                  }

                  // Save artwork history

                  try {
                    // Send notifications
                    const artLink = `/artwork/${networkType(
                      blockchain
                    )}/${collectionId}/${tokenId}`;

                    // Outputs the full URL of the current page
                    await handleNotifications(
                      item.newOwner || item.data.newOwner,
                      "priceChange",
                      price,
                      item?.metadata?.data?.artName || item?.data?.artName,
                      hash,
                      blockchain,
                      artLink,
                      item.newOwner || item.data.newOwner
                    );
                  } catch (error) {
                    return;
                  }

                  // Show success message
                  toast.success();
                  break;
                } else {
                  reject({ success: false });
                  setListingModal("error");
                  // Show error message on transaction failure
                  toast.error("Transaction Failed!");
                }

                break;
              }
            } catch (error) {
              break;
            }
          }
        })

        .catch((error) => {
          reject({ success: false, error });
          setListingModal("error");
          // Show error message on transaction failure
          toast.error("Transaction Failed!");
        });
    });
  };

  const [artistName, setArtistName] = useState("");

  const getArtistNamebyAdress = async (address) => {
    let artistName;
    let res = await getAllUsers();
    res?.forEach((user) => {
      if (user?.id === address) {
        artistName = user?.userName;
      }
    });

    setArtistName(artistName);
  };
  useEffect(() => {
    if (transferStatus === "success") {
      getArtistNamebyAdress(Address);
    }
  }, [transferStatus, Address]);

  return (
    <div>
      {owner !== address && row !== "row" ? (
        <div className="collection-option">
          <div
            className="collection-item"
            onClick={() => navigate("/report-issue")}
          >
            <img src={report} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Report
            </label>
          </div>

          <div
            onClick={() => copyLinkToClipboard(item)}
            className="collection-item"
          >
            <img src={share} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Share
            </label>
          </div>
          <div
            className="collection-item"
            onClick={() => window.location.reload()}
          >
            <img src={refresh} alt="sale" className="img-18" />
            <label className="text-black no-text-transform ms-2 pointer">
              Refresh Metadata
            </label>
          </div>
          {row == "row" && (
            <div
              onClick={() => navigateToSingleArtwork()}
              className="collection-item"
            >
              <img src={view} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                View item
              </label>
            </div>
          )}

          {row == "row1" && (
            <div
              onClick={() => navigateToSingleArtwork2()}
              className="collection-item"
            >
              <img src={view} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                View item
              </label>
            </div>
          )}
          {/* <div onClick={() => copyLinkToClipboard(item)} className="collection-item">
            <img src={copyLink} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Copy link
            </label>
          </div> */}
          {isOwner && (
            <div onClick={showEditListing} className="collection-item">
              <img src={edit} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Edit Listing
              </label>
            </div>
          )}
        </div>
      ) : (
        <div className="collection-option">
          {row !== "row" && owner === address && listed === "noListed" && (
            <>
              <div
                onClick={() => {
                  navigate(`/list-forSale`, {
                    state: {
                      data:
                        LocationArt === "singleArt"
                          ? item?.metadata?.data
                          : item?.data,
                      nftDetails: details,
                    },
                  });
                }}
                className="collection-item"
              >
                <img src={sale} alt="sale" />
                <label className="text-black no-text-transform ms-2 pointer">
                  List for sale
                </label>
              </div>
            </>
          )}
          {row == "row" && listed === "noListed" && (
            <>
              <div
                onClick={() => {
                  navigate(`/list-forSale`, {
                    state: {
                      data: item?.metadata?.data,
                      nftDetails: details,
                    },
                  });
                }}
                className="collection-item"
              >
                <img src={sale} alt="sale" />
                <label className="text-black no-text-transform ms-2 pointer">
                  List for sale
                </label>
              </div>
            </>
          )}
          {listed === "listed" && (
            <div onClick={showEditListing} className="collection-item">
              <img src={edit} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Edit Listing
              </label>
            </div>
          )}
          {listed === "listed" && (
            <div className="collection-item  ">
              <img src={refresh} alt="sale" className="img-18" />
              <label className="text-black no-text-transform ms-2 pointer">
                Refresh Metadata
              </label>
            </div>
          )}
          {listed === "noListed" && (
            <div
              onClick={() => transferOwner(item)}
              className="collection-item"
            >
              <img src={transfer} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                Transfer
              </label>
            </div>
          )}

          <div
            onClick={() => copyLinkToClipboard(item)}
            className="collection-item"
          >
            <img src={copyLink} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              Copy link
            </label>
          </div>
          {row == "row" && (
            <div
              onClick={() => navigateToSingleArtwork(item)}
              className="collection-item"
            >
              <img src={view} alt="sale" />
              <label className="text-black no-text-transform ms-2 pointer">
                View item
              </label>
            </div>
          )}
          {/* <div onClick={() => navigateToSingleArtwork(item)} className="collection-item">
            <img src={view} alt="sale" />
            <label className="text-black no-text-transform ms-2 pointer">
              View item
            </label>
          </div> */}
        </div>
      )}

      {/* Edit listing */}

      <Modal
        show={editListModal}
        onHide={handleEditListModal}
        className="sign-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              EDIT LISTING
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {listingModal === "initial" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="listed-item">
                <div className="v-center">
                  <div className="left">
                    {item?.metadata?.data?.image ? (
                      <img
                        src={item?.metadata?.data?.image}
                        alt="art"
                        className="img-100px "
                      />
                    ) : (
                      <video
                        className="img-100"
                        loop
                        playsInline
                        autoPlay
                        muted
                      >
                        <source
                          src={item?.metadata?.data?.videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {/* <img src={art} alt="img" className="img-100px" /> */}
                  </div>
                  <div className="right ms-3">
                    <label className="larger fw-bold text-black no-text-transform">
                      {nftDetails?.artName || item?.metadata?.data?.artName}
                    </label>
                    <p className="body-medium">{collectionName}</p>

                    <h6 className="fw-bold fst-normal mt-2"></h6>
                  </div>
                </div>
              </div>

              <p className="mt-30 text-center">
                Would you like to change the price of the listing or cancel the
                listing and remove it from sale?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                {item?.metadata?.data.selectedBlockchain !== chain?.name ? (
                  <Button
                    text="Switch Network"
                    className="btn-prime btn-primary"
                    width="156px"
                    height="36px"
                    onClick={handleSwitchChain}
                  />
                ) : (
                  <>
                    <Button
                      text="Change price"
                      className="btn-prime btn-primary"
                      width="156px"
                      height="36px"
                      onClick={() => {
                        setListingModal("changePrice");
                        setType("changePrice");
                      }}
                    />
                    <Button
                      text="Cancel listing"
                      className="btn-prime btn-secondary"
                      width="156px"
                      height="36px"
                      onClick={() => {
                        setListingModal("cancelListing");
                        setType("cancelListing");
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}

          {listingModal === "changePrice" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {item?.metadata.data?.image ? (
                  <img
                    src={item?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={item?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
                {/* <img src={art} alt="img" className="img-65 border-solid-black pad-2" /> */}
              </div>
              <p className="mt-40 text-center">
                Set new price for{" "}
                <strong>
                  “{nftDetails?.artName || item?.metadata?.data?.artName}”
                </strong>
              </p>
              <div className="pricing bg-transparent border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between">
                <Input
                  className="body-medium ps-2 text-black bg-transparent br-30 w-100"
                  placeholder="Price"
                  type="number"
                  value={price}
                  onChange={(e) => setPrice(e.target.value)}
                />
                <span>
                  {item?.metadata?.data.selectedBlockchain ===
                  config?.networks?.songbird
                    ? "SGB"
                    : "FLR"}
                </span>
              </div>
              <p className="mt-2 body-medium text-medium-grey">
                ~ $
                {(item?.metadata?.data.selectedBlockchain ===
                config?.networks?.songbird
                  ? price * sgbUsd || 0
                  : price * flrUsd || 0
                ).toFixed(2)}
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Continue"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={handlePrice}
                />
              </div>
            </div>
          )}

          {listingModal === "cancelListing" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {item?.metadata.data?.image ? (
                  <img
                    src={item?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={item.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <p className="mt-40 text-center">
                Cancel listing:
                <strong>
                  {" "}
                  {nftDetails?.artName || item?.metadata?.data?.artName}
                </strong>
              </p>
              <p className="mt-30 text-center">
                Are you sure you want to cancel this listing?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Go back"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("initial")}
                />
                <Button
                  text="Yes, cancel listing"
                  className="btn-prime btn-secondary text-error bg-error-20 border-0"
                  width="156px"
                  height="36px"
                  onClick={handleCancelListing}
                />
              </div>
            </div>
          )}

          {listingModal === "confirm" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Confirm this transaction in your wallet.
              </p>
              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>
              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {listingModal === "successPrice" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully updated price for
                <span className="fw-bold"> "{ArtName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={() => {
                  handleEditListModal();
                  window.location.reload();
                }}
                // onClick={() => { handleEditListModal(); }}
              />
            </div>
          )}

          {listingModal === "successListing" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully cancelled listing for
                <span className="fw-bold"> "{ArtName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={() => {
                  handleEditListModal();
                  window.location.reload();
                }}
                // onClick={() => { handleEditListModal(); }}
              />
            </div>
          )}

          {listingModal === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                {type === "changePrice"
                  ? `Sorry, we couldn't change the price for "${ArtName}".`
                  : `Sorry, we couldn't cancel your listing for "${ArtName}" at this time.`}
              </p>
              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                imageClassName="me-2 img-18 invert1"
                onClick={() => {
                  setListingModal("initial");
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/* Transfer Owner */}

      <Modal
        show={artTransfer}
        onHide={handleArtTransfer}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Transfer
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {transferStatus === "" && (
            <div className="art-transfer v-center flex-column pt-5 mt-2">
              <div className="upload-file mt-40">
                <img src={ArtImage} alt="art" className="img-100px border" />
              </div>

              <p className="body-medium mt-40 mb-3 pb-3">
                Transfer <span className="fw-bold">“{ArtName}” </span> to
              </p>
              {/* <p className="body-medium mt-40 mb-3 pb-3">
                Transfer <span className="fw-bold">“{ArtName}” </span> to
              </p> */}

              <div style={{ maxWidth: "487px" }} className="w-100  ">
                <InputBox
                  type="text"
                  placeholder="Address e.g 0x1H3a..."
                  labelClass="d-none"
                  value={Address}
                  onChange={handleAddressChange}
                />
              </div>
              <div className="h-center gap-3 mt-5 mb-70">
                <Button
                  text="Cancel"
                  className="btn-prime btn-secondary"
                  width="131px"
                  height="36px"
                  onClick={handleArtTransfer}
                />{" "}
                {row == "row" ? (
                  <Button
                    text="Transfer"
                    className={`btn-prime border-0 ${
                      Address.length > 0 ? "btn-primary" : "btn-ternary"
                    }`}
                    width="131px"
                    height="36px"
                    onClick={transferArt}
                    disabled={!Address.length}
                  />
                ) : (
                  <Button
                    text="Transfer"
                    className={`btn-prime border-0 ${
                      Address.length > 0 ? "btn-primary" : "btn-ternary"
                    }`}
                    width="131px"
                    height="36px"
                    onClick={transferArt}
                    disabled={!Address.length}
                  />
                )}
              </div>
            </div>
          )}

          {transferStatus === "success" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center  ">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Successfully tranfered your listing {ArtName} to {artistName}
              </p>

              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleArtTransfer}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Dropdown;
