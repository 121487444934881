import React, { useEffect, useState, useContext, useRef } from "react";
import "../../style/main.scss";
import Button from "../button";
import Accordion from "react-bootstrap/Accordion";
import tick from "../../assets/icon/tick-small-white.svg";
import wallet from "../../assets/icon/wallet.svg";
import error from "../../assets/icon/close-small.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import dot from "../../assets/icon/grey-dot.svg";
import refresh from "../../assets/icon/refresh-metadata-white.svg";
import art from "../../assets/images/artwork2.png";
import art1 from "../../assets/images/artwork.jpg";
import like from "../../assets/icon/likes-large 1.svg";
import liked from "../../assets/icon/liked.svg";
import FLR from "../../assets/icon/FLR.svg";
import SGB from "../../assets/icon/SGB.svg";
import option from "../../assets/icon/more-horizontal.svg";
import offerMade from "../../assets/icon/offer-made.svg";
import listed from "../../assets/icon/list-for-sale.svg";
import arrow from "../../assets/icon/arrow-angle-right-grey.svg";
import verified from "../../assets/icon/verified-artist.svg";
import lock from "../../assets/icon/lock.svg";
import Modal from "react-bootstrap/Modal";
import Input from "../inputs";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import UserProfile from "../shared/userProfile";
import thumb from "../../assets/icon/profile-picture.svg";
import { useTagContext } from "./TagContext";
import Dropdown from "../shared/dropdown";
import ArtistPopUp from "../shared/artistpopup";
import CustomCheckBox from "../shared/customTags";
import useScrollToTop from "../../customHooks/scrollToTop";
import { useLocation } from "react-router-dom";
import marketplaceContractABI from "../../abis/marketplace.json";
import mintContractABI from "../../abis/SafeMint/v2/abi.json";
import axios, { all } from "axios";
import { useSearchParams } from "react-router-dom";
import { NFTContext } from "../context/appContext";
import _, { add, result } from "lodash";
import mintAddress from "../../abis/erc721.json";
import { fetchNFTMetadata } from "../../Services/nft";
import Footer from "../footer";
import wrappedAbi from "../../abis/wrappedAbi.json";

import Web3 from "web3";

import {
  getNftDetailsFirebase,
  handleFavorite,
  handleNotifications,
  handleCollectionHistory,
  getCollectionStats,
  saveCollectionStats,
  saveLikedArtwork,
  getAllLikedArtwork,
  saveArtistStats,
  getCollectionDetailsFirebase,
  getAllUsers,
  saveArtworkHistory,
  getArtworkHistoryById,
  getUserDataByUserName,
  saveArtLastPriceToFirebase,
  getArtLastPriceFromFirebase,
  saveOfferHash,
  getOffersByArtId,
  getArtIdByUrl,
  getUserData,
  getCollectionIdByContractAddress,
  getContractAddressByCollectionId,
} from "../../firebase/firebase";

import { useSwitchNetwork, useNetwork, useFeeData, useBalance } from "wagmi";
import { useAccount } from "wagmi";
import { Popover, Skeleton, Card, Space, Row, Col } from "antd";
import { Image } from "antd";
import InputBox from "../shared/inputBox";
import erc20abi from "../../abis/erc20.json";
import { transactionType } from "viem";
import config from "../../config";
import { ContractTransactionResponse } from "ethers";
import { off } from "firebase/database";
import { confirmPasswordReset } from "firebase/auth";
import { useWalletClient } from "wagmi";

const SingleArt2 = () => {
  useScrollToTop();
  const { Meta } = Card;
  const { data: signer } = useWalletClient();

  const { setTagName } = useTagContext();
  const { artModal, udpateArtModal, userMembership } = useContext(NFTContext);
  const { showModal, updateModal, updateProfAddress } = useContext(NFTContext);
  const { reloadAfterTransfer, updateTransfer, updateCreated } =
    useContext(NFTContext);

  const location = useLocation();
  const { address } = useAccount();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();
  const [totalSupply, setTotalSupply] = useState(null);
  const [balance, setBalance] = useState(null);
  const [allowance, setAllowance] = useState(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [createFinish, setCreateFinish] = useState(false);
  const [transferAddress, setTransferAddress] = useState("");
  const [artTransfer, setArtTransfer] = useState(false);
  const [buyArtModal, setBuyArtModal] = useState(false);
  const [makeOfferModal, setMakeOfferModal] = useState(false);
  const [bidModal, setBidModal] = useState(false);
  const [editListModal, setEditListModal] = useState(false);
  const [finishListing, setFinishListing] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [offerStatus, setOfferStatus] = useState("");
  const [disableOfferBtn, setDisableOfferBtn] = useState(true);
  const [buyStatus, setBuyStatus] = useState("");
  const [lastPrice, setLastPrice] = useState(null);
  const [listingModal, setListingModal] = useState("initial");
  const [connectedAccount, setConnectedAccount] = useState(false);
  const [offerHistory, setOfferHistory] = useState([]);
  const [listedType, setListedType] = useState(0);
  const [selectedUser, setSelectedUser] = useState(0);
  const [selectUserAddress, setSelectUserAddress] = useState(null);
  const [isClaimUser, setIsClaimUser] = useState(false);
  const [selectedbid, setSelectedBid] = useState(null);
  const [owner, setOwner] = useState(false);
  const [nftUri, setNftUri] = useState([]);
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [nftData, setNftData] = useState([]);
  const [listedNfts, setListedNfts] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [state, setState] = useState([]);
  const [isNftApproved, setIsNftApproved] = useState(false);
  const [bidUserDetail, setBidUserDetail] = useState([]);
  const [inCompleteProfile, setInCompleteProfile] = useState(true);
  const [artId, setArtId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [artworkHistory, setArtworkHistory] = useState([]);

  const [isNewtworkConnected, setIsNewtworkConnected] = useState(null);
  const [web3, setWeb3] = useState(null);
  const [offerPrice, setOfferPrice] = useState(0);
  const [searchParams] = useSearchParams();
  const [collectionId, setCollectionId] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [biddingCount, setBiddingCount] = useState(null);
  const navigate = useNavigate();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { id } = useParams();
  const username = localStorage?.getItem("userName");
  const [isLiked, setIsLiked] = useState(false);
  const [nftDetails, setNftDetails] = useState(null);
  const [nftCollectionDetails, setNftCollectionDetails] = useState([]);
  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);
  const [buyDisable, setBuyDisable] = useState(0);
  const [showOffers, setShowOffers] = useState(false);
  const [nftCreatedAt, setNftCreatedAt] = useState(null);

  const [newPrice, setNewPrice] = useState("");
  const [approveTokens, setApproveTokens] = useState(false);

  const [isSaleListed, setIsSaleListed] = useState(null);
  const [isOfferListed, setIsOfferListed] = useState(null);

  const [saleType, setSaleType] = useState(null);
  const { marketplace, erc20 } = config;

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  useEffect(() => {
    updateProfAddress(address);
  }, [address]);

  const [wflr, setWflr] = useState();
  const [wsgb, setWsgb] = useState();

  // setting rpc url
  useEffect(() => {
    if (
      nftData[0]?.metadata?.data?.selectedBlockchain ===
      config.networks.songbird
    ) {
      setWeb3(new Web3(config.rpc.songbird));
    }
    if (
      nftData[0]?.metadata?.data?.selectedBlockchain === config.networks.flare
    ) {
      setWeb3(new Web3(config.rpc.flr));
    }
  }, [nftData, location.pathname]);

  const handleCreateFinish = () => {
    udpateArtModal();
    setCreateFinish(!createFinish);
  };

  const handleFinishListing = () => {
    updateModal(true);
    setFinishListing(false);
  };

  const handleArtTransfer = () => setArtTransfer(!artTransfer);

  useEffect(() => {
    if (artModal === true) {
      setCreateFinish(true);
    }

    if (showModal === true) {
      setFinishListing(true);
    }
  }, [artModal, showModal]);

  const handleBuyArtModal = () => {
    setBuyStatus("");
    setBuyArtModal(!buyArtModal);
  };
  const handleOfferModal = () => {
    setOfferStatus("");
    setMakeOfferModal(!makeOfferModal);
    setOfferPrice(null);
  };

  const handleBidModal = () => setBidModal(!bidModal);
  const handleEditListModal = () => {
    setEditListModal(!editListModal);
    setListingModal("initial");
  };

  useEffect(() => {
    const getHistory = async () => {
      let res = await getArtworkHistoryById(artId);
      const reversedHistory = res.history.reverse();
      setArtworkHistory(reversedHistory);
    };

    getHistory();
  }, [artId]);

  // get all user data from firebase
  const fetchUserData = async () => {
    const users = await getAllUsers();

    if (users) {
      for (let a of users) {
        if (a?.id === address) {
          if (a.userName) {
            setInCompleteProfile(false);
          }
        }
      }
    }

    setUserData(users);
  };
  useEffect(() => {
    fetchUserData();
  }, []);

  // set selected blockchain
  useEffect(() => {
    if (!isLoading) {
      if (nftData[0]?.metadata.data?.selectedBlockchain === chain?.name) {
        setIsNewtworkConnected(true);
      } else {
        const timer = setTimeout(() => {
          setIsNewtworkConnected(false);
        }, 3000);
        return () => clearTimeout(timer);
        // setIsNewtworkConnected(false);
      }
    }
  }, [chain, state, nftData, isLoading]);

  // switch network
  const handleSwitchChain = async () => {
    setIsLoading(true);
    const switchTo = chains.find(
      (c) => c.name === nftData[0]?.metadata.data.selectedBlockchain
    );
    if (switchTo) {
      setIsNewtworkConnected(true);
      switchNetwork?.(switchTo.id);
      window.location.reload();
      setIsLoading(false);
    }
  };

  useState(() => {
    if (location?.state?.loc === "mint") {
      window.onpopstate = () => {
        // alert("here");

        navigate("/explore");
      };
    }
  }, [location, window]);

  useEffect(() => {
    setConnectedAccount(address);
  }, [state, nftData]);

  const [offerError, setOfferError] = useState(false);

  const handlePriceChange = (e) => {
    const value = parseFloat(e.target.value); // Parse the input value as a number
    setShowError(false);
    setOfferPrice(e.target.value);

    let balance;

    if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      balance = wflr;
    } else {
      balance = wsgb;
    }

    // Check if value is invalid, greater than or equal to balance, or negative
    if (
      value >= balance || // If the value is greater than or equal to balance
      value === "" || // If the value is an empty string
      isNaN(value) || // If the value is not a number (e.g., empty, undefined)
      value <= 0 // If the value is negative or zero
    ) {
      setDisableOfferBtn(true);
    } else {
      setDisableOfferBtn(false);
    }

    // Check if the value contains a decimal point
    if (e.target.value.includes(".")) {
      setOfferError(true);
    } else {
      setOfferError(false);
    }
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const toggleFullScreen = () => {
    if (windowWidth >= 1024) {
      setIsFullScreen(!isFullScreen);
    } else setIsFullScreen(false);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // get collection id and token id from path
  useEffect(() => {
    let result1 = location.pathname.split("/")[3];
    let result2 = location.pathname.split("/")[4];
    const getCollectionId = async () => {
      let id = await getCollectionIdByContractAddress(result1);
      setCollectionId(id);
    };
    getCollectionId();

    setTokenId(result2);
  }, [location.pathname]);

  // check if the nft is approved or not from blockchain
  const checkIfNftsApproved = async () => {
    try {
      let web3;

      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        web3 = new Web3(config.rpc.songbird);
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        web3 = new Web3(config.rpc.flr);
      }

      let MarketplaceAddress;
      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      if (web3 !== null) {
        const contract = new web3.eth.Contract(
          mintContractABI,
          collectionDetails?.contractAddress
        );

        const data = await contract.methods
          .isApprovedForAll(address, MarketplaceAddress)
          .call();
        return data;
      }
    } catch (error) {
      return;
    }
  };

  // get bidding history for offer
  const getOfferHistory = async () => {
    try {
      if (web3 !== null) {
        let MarketplaceAddress;
        if (
          nftData[0]?.metadata.data.selectedBlockchain ===
          config.networks.songbird
        ) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (
          nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
        ) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

        const auctionListId = state[0]?.offerCountIndex;

        try {
          const result = await contract.methods
            .getBiddingHistory(auctionListId)
            .call();

          // Create a mutable copy of the result array and reverse it
          const mutableResult = [...result].reverse();

          // Map over each offer and check if the balance is sufficient
          const updatedOfferHistory = await Promise.all(
            mutableResult.map(async (offer) => {
              const isVisible = await checkOfferBalance(
                offer.user,
                offer.price / 1000000000000000000
              );
              return { ...offer, isVisible };
            })
          );

          // Set the updated offer history with visibility flags
          setOfferHistory(updatedOfferHistory);

          return result;
        } catch (error) {
          return;
        }
      }
    } catch (error) {
      return;
    }
  };
  // update price function
  const HandlechangePrice = async () => {
    let MarketplaceAddress;
    if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

    const priceInwei = web3.utils.toWei(newPrice.toString(), "ether");

    const data = contract.methods.editListForSale(
      state[0]?.saleCountIndex,
      priceInwei
    );

    let transaction = {
      from: address,
      to: MarketplaceAddress,
      data: data.encodeABI(),
      gasLimit: 3000000,
    };
    let username = getUserName();

    return new Promise((resolve, reject) => {
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          for (let index = 0; index > -1; index++) {
            try {
              const receipt = await web3.eth.getTransactionReceipt(hash);
              if (receipt != null) {
                if (receipt.status) {
                  setListingModal("successPrice");

                  getAllListNFTData();
                  handlecollectionStatsListing(
                    address,
                    newPrice,
                    nftData[0]?.metadata.data.selectedBlockchain,
                    nftData[0]?.metadata.data.artId
                  );

                  let action = "priceChange";
                  await saveArtworkHistory(
                    hash,
                    address,
                    action,
                    username,
                    artId,
                    newPrice,
                    null
                  );
                  const artLink = location.pathname;

                  await handleNotifications(
                    address,
                    "priceChange",
                    newPrice,
                    nftData[0]?.metadata.data?.artName,
                    hash,
                    nftData[0]?.metadata.data.selectedBlockchain,
                    artLink,
                    state[0]?.newOwner
                  );
                  getAllListNFTData();
                  getOfferHistory();
                  break;
                } else {
                  reject({ success: false, error });
                  setListingModal("error");
                  break;
                }
              }
            } catch (error) {
              reject({ success: false, error });
              setListingModal("error");

              toast.error("Transaction Failed!");
            }
          }
        })

        .catch((error) => {
          reject({ success: false, error });
          setListingModal("error");

          toast.error("Transaction Failed!");
        });
    });
  };
  // check if nft is approved or not
  const getstatus = async () => {
    let res = await checkIfNftsApproved();
    setIsNftApproved(res);
  };

  useEffect(() => {
    getstatus();
  }, [collectionDetails]);

  // handle buy nft function
  const handleBuyNow = async () => {
    await fetchUserData();
    if (BuyNow()) {
    }
  };

  // make offer function
  const handleMakeAnOffer = () => {
    setOfferStatus("confirming");
    let minimumBid = 0;

    // Convert offerPrice and minimumBid to numbers
    let price = parseFloat(offerPrice);

    // if (price > Number(data?.formatted)) {
    //   toast.info("Insufficient balance!");
    //   return;
    // }

    minimumBid = parseFloat(minimumBid);

    // Check if both offerPrice and minimumBid are valid numbers
    if (!isNaN(price) && !isNaN(minimumBid)) {
      // Compare offerPrice with minimumBid
      if (price < minimumBid) {
        // Show error message
        toast.error(
          `Offer price ${price} is lower than the minimum bid ${minimumBid}`
        );
        // Set showError state to true if needed
        setShowError(true);
        // Return or perform any other necessary action
        return;
      } else {
        if (makeOffer()) {
        }
      }
    } else {
      toast.error(`Invalid offer price ${price} or minimum bid ${minimumBid}`);
    }
  };

  useScrollToTop();

  // get like data
  const getAllLikes = async () => {
    const result = await getAllLikedArtwork(address);
  };

  useEffect(() => {
    getAllLikes();
    const checkLike = async () => {
      if (username) {
        const result = await getAllLikedArtwork(address);

        if (result?.likedArtworks?.includes(state[0]?.uriData)) {
          setIsLiked(true);
        } else {
          setIsLiked(false);
        }
      }
    };
    checkLike();
  }, [username, state]);

  // add nft to favourite

  const handleLikeClick = async () => {
    try {
      if (!address) {
        toast.info("Sign in to like artwork");
        return;
      }
      const result = await saveLikedArtwork(address, state[0]?.uri);

      if (result) {
        getAllLikes();
        setIsLiked(true);
        let artLink = location.pathname;

        handleNotifications(
          address,
          "favourite",
          null,
          nftData[0]?.metadata.data?.artName,
          null,
          nftData[0]?.metadata.data.selectedBlockchain,
          artLink,
          state[0]?.newOwner
        );
        toast.success("Artwork added to your favourites");
      } else {
        getAllLikes();

        setIsLiked(false);
      }
    } catch (error) {
      toast.error("An error occurred while processing your request.");
    }
  };

  const getNftMetadata = async (tokens_uri) => {
    try {
      const json = await fetchNFTMetadata(tokens_uri);

      return {
        data: json?.data,
      };
    } catch (error) {
      return;
    }
  };
  const getAllListNFTData = async () => {
    try {
      setIsLoading(true);

      if (collectionDetails) {
        let MarketplaceAddress;

        if (collectionDetails?.selectedNetwork === config.networks.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (
          collectionDetails?.selectedNetwork === config.networks.flare
        ) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        const contract = new web3.eth.Contract(
          marketplaceContractABI,
          MarketplaceAddress
        );

        const data = await contract.methods.getAllListedNfts().call();

        setListedNfts(data);

        setLoading(false);
      } else {
        setListedNfts([]);
        setLoading(false);
      }
    } catch (error) {
      return;
      setLoading(false);
    }
  };

  useEffect(() => {
    let res = getAllListNFTData();
  }, [collectionDetails]);

  // get toked ids of each collection

  useEffect(() => {
    const getArtId = async () => {
      let res = await getArtIdByUrl(nftUri);
      setArtId(res);
    };
    if (nftUri) {
      getArtId();
    }
  }, [nftUri]);

  const getUserNftsFromContract = async () => {
    try {
      let web3;

      if (collectionDetails?.selectedNetwork === config.networks.songbird) {
        web3 = new Web3(config.rpc.songbird);
      } else if (collectionDetails?.selectedNetwork === config.networks.flare) {
        web3 = new Web3(config.rpc.flr);
      }

      let contractAddress;
      let Address;

      if (collectionDetails) {
        contractAddress = collectionDetails?.contractAddress;
        Address = collectionDetails?.address;
      }
      let singleNft = [];

      try {
        if (web3 !== null) {
          const contract = new web3.eth.Contract(
            mintContractABI,
            contractAddress
          );
          const contractOwner = await contract.methods.owner().call();

          const result2 = await contract.methods
            .getTokenId(Address)
            .call({ from: contractOwner });

          const result = await contract.methods
            .getTokenIdsByCollection(collectionId)
            .call({ from: contractOwner });

          if (result?.length > 0) {
            for (let a of result) {
              if (a.tokenId == tokenId) {
                const tokens_uri = a.uri;
                setNftUri(tokens_uri);

                try {
                  // Fetch NFT metadata
                  const metadata = await getNftMetadata(tokens_uri);

                  // Push the metadata to singleNft array
                  singleNft.push({ uri: a.uri, metadata });

                  // Set nftData to singleNft array

                  setNftData(singleNft);

                  setNftDetails(singleNft[0].metadata.data);
                  // setIsLoading(false);
                  setLoading(false);

                  return singleNft;
                } catch (error) {
                  setLoading(false);
                  return;
                }
              }
            }
          } else {
            setLoading(false);
            setNftUri([]);
          }
        }
      } catch (error) {
        setLoading(false);
        return;
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getNfts = async () => {
      setLoading(true);
      let res = await getUserNftsFromContract();
      setLoading(false);
    };
    getNfts();
  }, [tokenId, location.pathname, collectionDetails]);

  useEffect(() => {
    const getNfts = async () => {
      await getUserNftsFromContract();
    };
    getNfts();
  }, []);

  // get collection details from firebase

  const getCollectionDetails = async () => {
    if (collectionId) {
      const result = await getCollectionDetailsFirebase(collectionId);
      setCollectionDetails(result);
    }
  };

  useEffect(() => {
    getCollectionDetails();
  }, [collectionId]);

  // get listed data
  const getListNFTData = async () => {
    try {
      if (collectionDetails && web3 !== null) {
        let MarketplaceAddress;
        if (collectionDetails?.selectedNetwork === config.networks.songbird) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (
          collectionDetails?.selectedNetwork === config.networks.flare
        ) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }

        const contract = new web3.eth.Contract(
          marketplaceContractABI,
          MarketplaceAddress
        );
        const data = await contract.methods
          .getNFTDetail(address, [`${collectionDetails?.contractAddress}`])
          .call({ from: address });

        setNftCollectionDetails(data[0]);
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getListNFTData();
  }, [collectionDetails, web3]);

  useEffect(() => {
    try {
      if (web3 !== null) {
        getListNFTData();
        getAllListNFTData();
      }
    } catch (error) {
      return;
    }
  }, [collectionDetails, web3]);

  // map and set listed data
  useEffect(() => {
    setIsLoading(true);
    try {
      if (nftData !== undefined || listedNfts) {
        let nftListDetails = [];

        nftData?.forEach((nftItem) => {
          let isSaleListed = false;
          let isOfferListed = false;
          let saleCountIndex = null;
          let offerCountIndex = null;
          let firstClaim = null; // Initialize firstClaim

          // Check for sale listings
          if (listedNfts[0]) {
            listedNfts[0]?.forEach((nft) => {
              if (nft?.uriData === nftItem?.uri) {
                isSaleListed = true;
                saleCountIndex = nft.listCount; // Store the index for sale listing
                nftListDetails.push({
                  ...nft,
                  ...nftItem,
                  isListed: true,
                  isSaleListed: true,
                  saleCountIndex, // Add saleCountIndex to the object
                  listedType: "listed",
                });
              }
            });
          }

          // Check for offer listings
          if (listedNfts[1]) {
            listedNfts[1]?.forEach((nft) => {
              if (nft?.uriData === nftItem?.uri) {
                isOfferListed = true;
                offerCountIndex = nft.listCount; // Store the index for offer listing
                firstClaim = nft.listedData?.firstClaim; // Get firstClaim

                const existingIndex = nftListDetails.findIndex(
                  (nftDetail) => nftDetail.uri === nftItem.uri
                );

                if (existingIndex !== -1) {
                  nftListDetails[existingIndex] = {
                    ...nftListDetails[existingIndex],
                    isOfferListed: true,
                    offerCountIndex, // Add offerCountIndex to the object
                    firstClaim, // Add firstClaim to the existing item
                  };
                } else {
                  nftListDetails.push({
                    ...nft,
                    ...nftItem,
                    isListed: true,
                    isOfferListed: true,
                    offerCountIndex, // Add offerCountIndex to the object
                    firstClaim, // Add firstClaim to the object
                    listedType: "listed",
                  });
                }
              }
            });
          }

          // Ensure both flags and indices are set correctly for NFTs listed in both categories
          if (isSaleListed || isOfferListed) {
            const existingIndex = nftListDetails.findIndex(
              (nftDetail) => nftDetail.uri === nftItem.uri
            );
            if (existingIndex !== -1) {
              nftListDetails[existingIndex] = {
                ...nftListDetails[existingIndex],
                isListed: true,
                isSaleListed,
                isOfferListed,
                saleCountIndex: isSaleListed
                  ? saleCountIndex
                  : nftListDetails[existingIndex].saleCountIndex,
                offerCountIndex: isOfferListed
                  ? offerCountIndex
                  : nftListDetails[existingIndex].offerCountIndex,
                firstClaim: isOfferListed
                  ? firstClaim
                  : nftListDetails[existingIndex].firstClaim, // Add firstClaim
              };
            }
          }

          // If the NFT is not listed in either sale or offer listings
          if (!isSaleListed && !isOfferListed) {
            const nftCollectionDetail = nftCollectionDetails.find(
              (detail) => detail.uri === nftItem?.uri
            );

            nftListDetails.push({
              ...nftItem,
              listedData: nftCollectionDetail,
              isListed: false,
              listedType: "noListed",
            });
          }
        });

        if (nftListDetails[0]?.firstClaim == false) {
          setSaleType("primary");
        } else {
          setSaleType("secondary");
        }

        // if(nftListDetails[0]?.listedData.isListed){
        setNftCreatedAt(nftListDetails[0]?.listedData?.listTime);

        // }

        if (nftListDetails.length > 0) {
          setIsLoading(false);
          setLoading(false);
          setState(nftListDetails);
        }
      } else {
        return;
      }
    } catch (error) {
      // setIsLoading(false);
      return;
    }
  }, [listedNfts, nftCollectionDetails, nftData, userData]);

  useEffect(() => {
    if (address) {
      getUserNftsFromContract();
    }
  }, []);
  const [timeRemaining, setTimeRemaining] = useState(null);
  const [canPurchase, setCanPurchase] = useState(false);
  const [earlyAccess, setEarlyAccess] = useState(false);
  const [activeMembership, setActiveMembership] = useState(null);
  const [eligibleBuyer, setEligibleBuyer] = useState(false);

  useEffect(() => {
    const checkAccess = () => {
      if (saleType !== "primary") {
        setEligibleBuyer(true);
        setTimeRemaining(0);
        setEarlyAccess(false);
        return;
      }
      const now = new Date();
      const createdTime = new Date(nftCreatedAt * 1000);
      const timeElapsed = now - createdTime;
      const hoursElapsed = Math.floor(timeElapsed / (1000 * 60 * 60));

      // Check if early access period is still active (less than 24 hours)
      if (hoursElapsed < 24) {
        setEarlyAccess(true); // Early access is still active
      } else {
        setEarlyAccess(false); // Early access has ended
      }

      let totalRemainingTime;
      let purchaseWindow = false;
      let currentMembership = null; // Will track which membership window is active

      // Check access based on time elapsed and user membership
      if (hoursElapsed < 6) {
        currentMembership = "Gold"; // First 6 hours is for GOLD members
        purchaseWindow = userMembership === "Gold"; // Only GOLD can buy
        totalRemainingTime = 6 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 6 && hoursElapsed < 12) {
        currentMembership = "Silver"; // Next 6 hours is for Silver and GOLD members
        purchaseWindow = ["Gold", "Silver"].includes(userMembership);
        totalRemainingTime = 12 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 12 && hoursElapsed < 18) {
        currentMembership = "Bronze"; // Next 6 hours is for BRONZE, Silver, and Gold members
        purchaseWindow = ["Gold", "Silver", "Bronze"].includes(userMembership);
        totalRemainingTime = 18 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 18 && hoursElapsed < 24) {
        currentMembership = "Standard"; // Last 6 hours for public access (all memberships)
        purchaseWindow = ["Gold", "Silver", "Bronze", "Standard"].includes(
          userMembership
        );
        totalRemainingTime = 24 * 60 * 60 * 1000 - timeElapsed;
      } else if (hoursElapsed >= 24) {
        currentMembership = ""; // After 24 hours, public access
        purchaseWindow = true; // All users can buy
        totalRemainingTime = null;
      }

      setActiveMembership(currentMembership); // Set the active membership level
      setCanPurchase(purchaseWindow); // Set the purchase window

      // If totalRemainingTime is not null, calculate remaining hours, minutes, and seconds
      if (totalRemainingTime !== null) {
        const hours = Math.floor(totalRemainingTime / (1000 * 60 * 60));
        const minutes = Math.floor(
          (totalRemainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((totalRemainingTime % (1000 * 60)) / 1000);
        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeRemaining(null);
      }

      // Set eligibility based on active membership and user membership
      if (purchaseWindow) {
        setEligibleBuyer(true);
      } else if (currentMembership === "" || null) {
        setEligibleBuyer(true); // All users are eligible in public access window
      } else {
        setEligibleBuyer(false);
      }
    };

    if (isSaleListed) {
      checkAccess();
      const timer = setInterval(checkAccess, 1000);

      return () => clearInterval(timer);
    }
  }, [nftCreatedAt, userMembership, isSaleListed]);

  // useEffect(() => {
  //   const checkAccess = () => {
  //     console.log(nftCreatedAt, "nft Created at");

  //     if (saleType !== "primary") {
  //       setEligibleBuyer(true);
  //       setTimeRemaining(0);
  //       setEarlyAccess(false);
  //       return;
  //     }

  //     const now = new Date();
  //     const createdTime = new Date(nftCreatedAt * 1000);
  //     const timeElapsed = now - createdTime;
  //     const minutesElapsed = Math.floor(timeElapsed / (1000 * 60));

  //     console.log(userMembership, "userMembership");
  //     console.log(minutesElapsed, "nft created at");

  //     // Check if early access period is still active (less than 4 minutes)
  //     if (minutesElapsed < 4) {
  //       setEarlyAccess(true); // Early access is still active
  //     } else {
  //       setEarlyAccess(false); // Early access has ended
  //     }

  //     let totalRemainingTime;
  //     let purchaseWindow = false;
  //     let currentMembership = null; // Will track which membership window is active

  //     // Check access based on time elapsed and user membership
  //     if (minutesElapsed < 1) {
  //       currentMembership = "Gold"; // First 1 minute is for GOLD members
  //       purchaseWindow = userMembership === "Gold"; // Only GOLD can buy
  //       totalRemainingTime = 1 * 60 * 1000 - timeElapsed;
  //     } else if (minutesElapsed >= 1 && minutesElapsed < 2) {
  //       currentMembership = "Silver"; // Next 1 minute is for Silver and GOLD members
  //       purchaseWindow = ["Gold", "Silver"].includes(userMembership);
  //       totalRemainingTime = 2 * 60 * 1000 - timeElapsed;
  //     } else if (minutesElapsed >= 2 && minutesElapsed < 3) {
  //       currentMembership = "Bronze"; // Next 1 minute is for BRONZE, Silver, and Gold members
  //       purchaseWindow = ["Gold", "Silver", "Bronze"].includes(userMembership);
  //       totalRemainingTime = 3 * 60 * 1000 - timeElapsed;
  //     } else if (minutesElapsed >= 3 && minutesElapsed < 4) {
  //       currentMembership = "Standard"; // Last 1 minute for Standard access (all memberships)
  //       purchaseWindow = ["Gold", "Silver", "Bronze", "Standard"].includes(userMembership);
  //       totalRemainingTime = 4 * 60 * 1000 - timeElapsed;
  //     } else if (minutesElapsed >= 4) {
  //       currentMembership = ""; // After 4 minutes, public access
  //       purchaseWindow = true; // All users can buy
  //       totalRemainingTime = null;
  //       setEarlyAccess(false);
  //     }

  //     console.log(currentMembership, "current membership");
  //     setActiveMembership(currentMembership); // Set the active membership level
  //     setCanPurchase(purchaseWindow); // Set the purchase window

  //     // If totalRemainingTime is not null, calculate remaining minutes and seconds
  //     if (totalRemainingTime !== null) {
  //       const minutes = Math.floor(totalRemainingTime / (1000 * 60));
  //       const seconds = Math.floor((totalRemainingTime % (1000 * 60)) / 1000);
  //       setTimeRemaining(`${minutes}m ${seconds}s`);
  //     } else {
  //       setTimeRemaining(null);
  //     }

  //     // Set eligibility based on active membership and user membership
  //     if (userMembership === currentMembership) {
  //       setEligibleBuyer(true);
  //     } else if (currentMembership === "") {
  //       setEligibleBuyer(true); // All users are eligible in public access window
  //     } else {
  //       setEligibleBuyer(false);
  //     }
  //   };

  //   console.log(isSaleListed, "is sale listed");

  //   if (isSaleListed) {
  //     checkAccess();
  //     const timer = setInterval(checkAccess, 1000); // 1-minute interval

  //     return () => clearInterval(timer);
  //   }
  // }, [nftCreatedAt, userMembership, isSaleListed]);

  // get selected by owner  (from bidding)
  const getIsUserSelected = async () => {
    try {
      if (web3 !== null) {
        let MarketplaceAddress;
        if (
          nftData[0]?.metadata.data.selectedBlockchain ===
          config.networks.songbird
        ) {
          MarketplaceAddress = Marketplace_coston_contractAddress;
        } else if (
          nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
        ) {
          MarketplaceAddress = Marketplace_coston2_contractAddress;
        }
        const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
        const auctionListId = state[0]?.offerCountIndex;

        const mintContractAddress = collectionDetails?.contractAddress;

        // get selected user for that auction id
        const result = await contract.methods
          .SelectedUser(auctionListId)
          .call();

        if (result == 0) {
          setBiddingCount(null);
        } else {
          setBiddingCount(result);
        }

        // get bidding details of that bid (selected bid)
        if (result) {
          const result2 = await contract.methods
            .Bidding(mintContractAddress, tokenId, result)
            .call();

          // setBidUserDetail(result2);
        }
        return result;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getIsUserSelected();
  }, [nftData, state]);

  // get owner data for nft

  const getOwner = async () => {
    try {
      let contractAddress;
      let Address;

      if (collectionDetails) {
        contractAddress = collectionDetails?.contractAddress;
        Address = collectionDetails?.address;

        if (Address) {
          try {
            if (web3 !== null) {
              const contract = new web3.eth.Contract(
                mintContractABI,
                contractAddress
              );

              const contractOwner = await contract.methods.owner().call();

              try {
                const result = await contract.methods
                  .ownerOf(tokenId)
                  .call({ from: contractOwner });

                setOwner(result);
              } catch (error) {
                return;
              }
            }
          } catch (error) {
            return;
          }
        }
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    if (owner) {
      setLoading(true);
      const Owner = userData.find((user) => user.id === owner);
      // Check if the owner is found and is not blacklisted
      let isBlackListed = owner !== undefined && Owner.isBlackListed === false;
      if (isBlackListed == false) {
        navigate("/");
      }
      setLoading(false);
    }
  }, [owner]);

  useEffect(() => {
    try {
      const getOwnerofnft = async () => {
        await getOwner();
      };
      if (reloadAfterTransfer === true) {
        getOwnerofnft();
        updateTransfer(false);
      }
    } catch (error) {
      return;
    }
  }, [reloadAfterTransfer]);

  useEffect(() => {
    try {
      const getOwnerofnft = async () => {
        await getOwner();
      };

      getOwnerofnft();
    } catch (error) {
      return;
    }
  }, [location, tokenId, state[0]]);

  // map nft details

  const getNftDetails = async () => {
    try {
      if (state[0]) {
        let uri;

        setNftDetails(nftData[0].metadata.data);

        if (state[0]?.isSaleListed) {
          setListedType(1);
          setIsSaleListed(true);
          getOfferHistory();
        }
        if (state[0]?.isOfferListed) {
          setIsOfferListed(true);
          setListedType(2);
          getOfferHistory();
        }

        setNftDetails(nftData[0]?.metadata.data);

        uri = nftData[0]?.uri;
        const result = await getNftMetadata(uri);
        setNftDetails(nftData[0]?.metadata.data);
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    getNftDetails();
    if (state[0]?.isOfferListed) {
      if (getOfferHistory() !== undefined) {
        getNftDetails();
        getSelectedUser();
      }
    } else {
      getNftDetails();
    }
  }, [web3, tokenId, location, nftData, listedNfts, state]);

  const getArtistNamebyAdress = (accountAddress) => {
    let artistName = "";
    if (address === accountAddress) {
      return "You";
    } else {
      userData?.forEach((user) => {
        if (user?.id === accountAddress) {
          artistName = user?.userName;
        }
      });
    }

    return "@" + artistName;
  };
  const getArtistNamebyAdress2 = (accountAddress) => {
    let artistName = "";

    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        artistName = user?.userName;
      }
    });

    return artistName;
  };
  // save artist to firebase
  const handleSaveArtistStats = async (
    artistAddress,
    sale,
    selectedNetwork
  ) => {
    const artistStats = await saveArtistStats(
      artistAddress,
      sale,
      selectedNetwork
    );
  };
  const [totalPrice, setTotalPrice] = useState(null);
  const [marketPlacePercentage, setMarketPlacePercentage] = useState(null);
  useEffect(() => {
    let basePrice = state[0]?.listedData?.price / 1000000000000000000; // Default price if not available
    let percentage = 0;

    switch (userMembership) {
      case "Gold":
        percentage = 0; // 0% fee
        break;
      case "Silver":
        percentage = 2; // 2% fee
        break;
      case "Bronze":
        percentage = 3; // 3% fee
        break;
      case "Standard":
        percentage = 4; // 4% fee
        break;
      default:
        percentage = 5; // Default 5% fee for other or null memberships
    }

    // Calculate the price as a percentage of the base price (percentage is divided by 100 to make it a decimal)
    const calculatedPrice = basePrice * (percentage / 100);

    setTotalPrice(percentage); // Set the calculated price
    setMarketPlacePercentage(calculatedPrice); // Set the percentage value for reference
  }, [userMembership, state[0]]);

  // buy now function of blockchain

  const BuyNow = async () => {
    fetchUserData();

    if (!eligibleBuyer) {
      setBuyStatus("success");
      return;
    }
    if (inCompleteProfile) {
      toast.info("Your profile is incomplete");
      return;
    }

    setBuyStatus("confirming");

    let MarketplaceAddress;
    if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    let username = await getUserName();
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

    const priceInWei = state[0]?.listedData?.price;

    const marketplaceFeeInWei = web3.utils.toWei(
      marketPlacePercentage.toString(),
      "ether"
    );

    // Calculate the total price in Wei and ensure it's an integer
    const totalPriceInWei = parseInt(
      web3.utils.toBN(priceInWei).add(web3.utils.toBN(marketplaceFeeInWei))
    );

    const royality = 2; // Example royalty value
    if (state[0]?.listedData?.count) {
      let transaction = {
        from: address,
        to: MarketplaceAddress,
        data: contract.methods
          .buyNft(state[0]?.listedData?.count, priceInWei, marketplaceFeeInWei)
          .encodeABI(),
        gas: 30000,
      };

      console.log(transaction, "transactio");

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);

                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });

                    let transactionHash = hash;
                    await getOwner();
                    setBuyStatus("success");
                    toast.success("Artwork purchased successfully");
                    let price = state[0]?.listedData?.price;

                    const historyData = {
                      action: "sold",
                      user: username,
                      artworkUri: nftDetails,
                      from: state[0]?.newOwner,
                      to: address,
                      price: priceInWei,
                      tokenId: state[0]?.listedData?.tokenId,
                      transactionHash: transactionHash,
                    };
                    const artLink = location.pathname;

                    await getOwner();
                    handleNotifications(
                      address,
                      "purchase",
                      priceInWei,
                      nftData[0]?.metadata.data?.artName,
                      transactionHash,
                      nftData[0]?.metadata.data.selectedBlockchain,
                      artLink,
                      state[0]?.newOwner
                    );

                    let selectedNetwork =
                      nftData[0]?.metadata.data.selectedBlockchain;

                    await handleCollectionHistory(
                      nftDetails?.selectedCollectionId,
                      historyData
                    );
                    await handlecollectionStats(
                      address,
                      priceInWei,
                      nftData[0]?.metadata.data.selectedBlockchain
                    );

                    await handleSaveArtistStats(
                      state[0]?.listedData?.artist,
                      priceInWei,
                      selectedNetwork
                    );

                    let action = "sold";
                    await saveArtworkHistory(
                      transactionHash,
                      address,
                      action,
                      username,
                      artId,
                      price,
                      null
                    );
                    await saveArtLastPriceToFirebase(artId, price);

                    await getAllListNFTData();
                    window.location.reload();
                  } else {
                    reject({ success: false });

                    setBuyStatus("error");
                    toast.error("Transaction Failed!");
                  }
                  break;
                }
              } catch (error) {
                setBuyStatus("error");
                toast.error("Transaction Failed!");
              }
            }
          })
          .catch((error) => {
            reject({ success: false, error });
            setBuyStatus("error");
            toast.error("Transaction Failed!");
          });
      });
    }
  };

  const ifTokensApproved = async () => {
    try {
      const selectedBlockchain = nftData[0]?.metadata?.data?.selectedBlockchain;
      let tokenAddress;
      let MarketplaceAddress;

      if (selectedBlockchain === config.networks.songbird) {
        tokenAddress = coston_Token;
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedBlockchain === config.networks.flare) {
        tokenAddress = coston2_Token;
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      // if (!tokenAddress || !MarketplaceAddress) {
      //   throw new Error("Invalid blockchain selected");
      // }

      const contract = new web3.eth.Contract(erc20abi, tokenAddress);

      const balance = await contract.methods.balanceOf(address).call();

      const totalSupply = await contract.methods.totalSupply().call();
      const allowance = await contract.methods
        .allowance(address, MarketplaceAddress)
        .call();

      setTotalSupply(totalSupply);
      setBalance(balance);
      setAllowance(allowance);
    } catch (error) {
      return;
    }
  };

  const checkOfferBalance = async (offerAdr, offerPrice) => {
    const selectedBlockchain = nftData[0]?.metadata.data.selectedBlockchain;
    let tokenAddress;
    let MarketplaceAddress;

    if (selectedBlockchain === config.networks.songbird) {
      tokenAddress = coston_Token;
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (selectedBlockchain === config.networks.flare) {
      tokenAddress = coston2_Token;
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    // if (!tokenAddress || !MarketplaceAddress) {
    //   throw new Error("Invalid blockchain selected");
    // }

    const contract = new web3.eth.Contract(erc20abi, tokenAddress);

    const balance = await contract.methods.balanceOf(offerAdr).call();

    // Check if balance is greater than or equal to the offer price
    return Number(balance) >= offerPrice;
  };
  useEffect(() => {
    if (bidUserDetail) {
      if (balance === 0) {
        return;
      }

      const allowanceValue = parseFloat(allowance);
      const bidPrice = parseFloat(bidUserDetail?.price);
      if (!allowance) {
        setApproveTokens(false);
        return;
      }

      if (allowanceValue <= 0 || allowanceValue < bidPrice) {
        setApproveTokens(false);
      } else {
        setApproveTokens(true);
      }
    }
  }, [totalSupply, balance, isOwner, bidUserDetail, allowance, address]);

  useEffect(() => {
    const check = async () => {
      await ifTokensApproved();
    };
    check();
  }, [nftData[0], address, web3]);

  const getTokenApproved = async () => {
    try {
      let balance;

      const selectedBlockchain = nftData[0]?.metadata.data.selectedBlockchain;
      let tokenAddress;
      let MarketplaceAddress;

      if (selectedBlockchain === config.networks.songbird) {
        tokenAddress = coston_Token;
        balance = wsgb;
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (selectedBlockchain === config.networks.flare) {
        tokenAddress = coston2_Token;
        MarketplaceAddress = Marketplace_coston2_contractAddress;
        balance = wflr;
      }

      if (!tokenAddress || !MarketplaceAddress) {
        throw new Error("Invalid blockchain selected");
      }

      const contract = new web3.eth.Contract(erc20abi, tokenAddress);
      let transaction = {
        from: address,
        gasLimit: 3000000,
        to: tokenAddress,
        data: await contract.methods
          .approve(MarketplaceAddress, balance)
          .encodeABI(),
      };

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });
                    toast.success("Tokens Approved Successfully");
                    await ifTokensApproved();

                    break;
                  } else {
                    reject({ success: false });
                    return;
                    toast.error("Transaction Failed!");
                  }

                  break;
                }
              } catch (error) {
                return;
              }
            }
          })

          .catch((error) => {
            reject({ success: false, error });

            toast.error("Transaction Failed!");
          });
      });
    } catch (error) {
      return;
    }
  };

  const mintToApprove = async () => {
    try {
      // Get the accounts from the web3 instance

      // Define the token address based on the selected blockchain
      let tokenAddress;
      if (
        nftData[0]?.metadata.data.selectedBlockchain ===
        config.networks.songbird
      ) {
        tokenAddress = coston_Token;
      } else if (
        nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
      ) {
        tokenAddress = coston2_Token;
      }
      const contract = new web3.eth.Contract(erc20abi, tokenAddress);

      const transaction = await contract.methods.mint().call();
    } catch (error) {
      return;
    }
  };

  // bid
  // accept offer
  // before claim approve tokens
  // claim

  const ClaimNft = async () => {
    let memberShipFee;

    let MarketplaceAddress;
    if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    // Set the membership fee percentage based on user status
    switch (userMembership) {
      case "Gold":
        memberShipFee = 0;
        break;
      case "Silver":
        memberShipFee = 2;
        break;
      case "Bronze":
        memberShipFee = 3;
        break;
      case "Standard":
        memberShipFee = 4;
        break;
      default:
        memberShipFee = 5; // Default value
    }

    if (web3) {
      const contract = new web3.eth.Contract(
        marketplaceContractABI,
        MarketplaceAddress
      );

      const priceToEther = bidUserDetail?.price;
      const auctionListId = state[0]?.offerCountIndex;
      try {
        let transaction = {
          from: address,
          to: MarketplaceAddress,
          data: contract.methods
            .ClaimNFT(auctionListId, priceToEther, memberShipFee)
            .encodeABI(),
          gas: 3000000,
        };

        return new Promise((resolve, reject) => {
          signer
            .sendTransaction(transaction)
            .then(async (hash) => {
              for (let index = 0; index > -1; index++) {
                try {
                  const receipt = await web3.eth.getTransactionReceipt(hash);
                  if (receipt != null) {
                    if (receipt.status) {
                      resolve({ success: true, hash });
                      toast.success("Artwork claimed successfully");

                      setBidModal(false);

                      const historyData = {
                        action: "claimNft",
                        user: username,
                        artworkUri: nftDetails,
                        from: state[0]?.newOwner,
                        to: address,
                        price: priceToEther,
                        tokenId: tokenId,
                        transactionHash: hash,
                      };

                      let action = "claimNft";
                      await saveArtworkHistory(
                        hash,
                        address,
                        action,
                        username,
                        artId,
                        priceToEther,
                        null
                      );

                      let selectedNetwork =
                        nftData[0]?.metadata.data.selectedBlockchain;

                      await handleCollectionHistory(
                        nftDetails?.selectedCollectionId,
                        historyData
                      );

                      await handlecollectionStats(
                        address,
                        priceToEther,
                        selectedNetwork
                      );

                      await handleSaveArtistStats(
                        state[0]?.listedData?.artist,
                        priceToEther,
                        selectedNetwork
                      );

                      await getAllListNFTData();
                      await getOfferHistory();
                      await getSelectedUser();

                      const artLink = location.pathname;

                      await handleNotifications(
                        address,
                        "claim",
                        null,
                        nftData[0]?.metadata.data?.artName,
                        hash,
                        selectedNetwork,
                        artLink,
                        state[0]?.newOwner
                      );

                      window.location.reload();
                      break;
                    } else {
                      reject({ success: false });

                      toast.error("Transaction Failed!");
                    }

                    break;
                  }
                } catch (error) {
                  return;
                }
              }
            })

            .catch((error) => {
              reject({ success: false, error });

              toast.error("Transaction Failed!");
            });
        });
      } catch (error) {
        toast.error("Transaction Failed!");
      }
    }
  };

  // store collection stats data to fireabse

  const handlecollectionStatsListing = async (
    owner,
    newPrice,
    selectedNetwork
  ) => {
    const collectionId = nftDetails?.selectedCollectionId;
    const collectionStats = await getCollectionStats(collectionId);

    if (collectionStats) {
      let volume = collectionStats?.volume || [];
      volume.push(`${offerPrice}-${new Date().getTime()}`);

      let owners = collectionStats?.owners || [];
      if (!owners.includes(owner)) {
        owners.push(owner);
      }
      let SGBvolume = parseFloat(collectionStats?.SGBvolume) || 0;
      let FLRvolume = parseFloat(collectionStats?.FLRvolume) || 0;

      if (selectedNetwork === config.networks.songbird) {
        SGBvolume += parseFloat(offerPrice);
      } else if (selectedNetwork === config.networks.rpc) {
        FLRvolume += parseFloat(offerPrice);
      }

      let floorPrice = collectionStats?.floorPrice || [];

      // Remove the matching offerPrice from floorPrice
      floorPrice = floorPrice.filter((fp) => {
        const price = parseFloat(fp.split("-")[0]);
        return price !== parseFloat(offerPrice / 1000000000000000000);
      });

      // Determine the minimum floor price from the updated floorPrice array
      let minFloorPrice;

      if (floorPrice?.length > 0) {
        minFloorPrice = Math.min(
          ...floorPrice.map((fp) => parseFloat(fp.split("-")[0]))
        );
      } else if (floorPrice?.length == 1) {
        minFloorPrice = floorPrice[0];
      } else {
        minFloorPrice = 0; // or set to a default value if needed
      }

      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats?.artworkCount,
        createdAt: collectionStats?.createdAt,
        creatorEarning: collectionStats?.creatorEarning,
        volume: volume,
        SGBvolume: SGBvolume,
        FLRvolume: FLRvolume,
        USDvolume: collectionStats?.USDvolume,
        floorPrice: floorPrice,
        listedCount: (collectionStats?.listedCount || 0) - 1, // Adjusting listedCount
        saleCount: (collectionStats?.saleCount || 0) + 1, // Incrementing saleCount
        owners: owners,
        minFloorPrice: minFloorPrice, // Optional: to store the minimum floor price separately
      };

      await saveCollectionStats(data);
    }
  };

  const handlecollectionStats = async (owner, offerPrice, selectedNetwork) => {
    const collectionId = nftDetails?.selectedCollectionId;

    const collectionStats = await getCollectionStats(collectionId);

    if (collectionStats) {
      let volume = collectionStats?.volume || [];
      volume.push(`${offerPrice}-${new Date().getTime()}`);

      let owners = collectionStats?.owners || [];
      if (!owners.includes(owner)) {
        owners.push(owner);
      }
      let SGBvolume = parseFloat(collectionStats?.SGBvolume) || 0;
      let FLRvolume = parseFloat(collectionStats?.FLRvolume) || 0;

      if (selectedNetwork === config.networks.songbird) {
        SGBvolume += parseFloat(offerPrice);
      } else if (selectedNetwork === config.networks.flare) {
        FLRvolume += parseFloat(offerPrice);
      }

      // Removed the floorPrice and minFloorPrice logic

      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats?.artworkCount,
        createdAt: collectionStats?.createdAt,
        creatorEarning: collectionStats?.creatorEarning,
        volume: volume,
        SGBvolume: SGBvolume,
        FLRvolume: FLRvolume,
        USDvolume: collectionStats?.USDvolume,
        listedCount: (collectionStats?.listedCount || 0) - 1, // Adjusting listedCount
        saleCount: (collectionStats?.saleCount || 0) + 1, // Incrementing saleCount
        floorPrice: 0,
        owners: owners,
        // Removed minFloorPrice from data
      };

      await saveCollectionStats(data);
    }
  };

  // if profile is not complete don't allow offer (or make bid)
  const openOffer = async () => {
    setMakeOfferModal(true);
  };
  const [bidCount, setBidCount] = useState(null);

  useEffect(() => {
    if (offerHistory?.length <= 0) {
      setBidCount(0);
      return;
    }
    const bidCounts = offerHistory.map((e) => parseInt(e[4], 10)); // Assuming 'bidCount' is at the 4th position
    const maxBidCount = Math.max(...bidCounts);

    setBidCount(maxBidCount);
  }, [offerHistory]);

  //  make offer to blockchain

  const makeOffer = async () => {
    if (inCompleteProfile) {
      toast.info("Your Profile is not complete");
      return;
    }

    let MarketplaceAddress;
    if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      nftData[0]?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
    const contractOwner = await contract.methods.owner().call();

    const auctionListId = state[0]?.offerCountIndex;
    const priceToEther = web3.utils.toWei(offerPrice, "ether");

    const username = getUserName();

    let transaction = {
      from: address,
      to: MarketplaceAddress,
      data: contract.methods
        .NftOffers(auctionListId, username, priceToEther)
        .encodeABI(),
      gas: 300000,
    };

    return new Promise((resolve, reject) => {
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          for (let index = 0; index > -1; index++) {
            try {
              const receipt = await web3.eth.getTransactionReceipt(hash);
              if (receipt != null) {
                if (receipt.status) {
                  resolve({ success: true, hash });
                  const notifiedUsers = new Set();

                  let transactionHash = hash;

                  if (result) {
                    setOfferStatus("success");
                    try {
                      if (offerHistory) {
                        offerHistory?.forEach((item) => {
                          const currentPriceEther = item?.price / 1e18;
                          const newOfferPriceEther = priceToEther / 1e18;

                          // Check if the new offer price is higher and if the user hasn't been notified yet
                          if (
                            newOfferPriceEther > currentPriceEther &&
                            !notifiedUsers.has(item?.user)
                          ) {
                            // Add the user to the Set so they don't get notified again
                            notifiedUsers.add(item?.user);

                            // User has placed a higher offer, so notify about outbid
                            const artLink = location.pathname; // Get the current URL path

                            // Call the notification handler
                            handleNotifications(
                              item?.user, // The user who placed the current offer
                              "outBid", // Type of notification
                              newOfferPriceEther, // New bid price in Ether
                              nftData[0]?.metadata.data?.artName, // The name of the artwork
                              transactionHash, // Transaction hash
                              nftData[0]?.metadata.data.selectedBlockchain, // Blockchain info
                              artLink, // Link to the artwork
                              state[0]?.newOwner // New owner (if applicable)
                            );
                          }
                        });
                        try {
                          // await mintToApprove();
                        } catch (error) {
                          return;
                        }

                        const historyData = {
                          action: "offer",
                          user: username,
                          artworkUri: nftDetails,
                          from: address,
                          to: state[0]?.newOwner,
                          price: priceToEther,
                          tokenId: state[0]?.listedData?.tokenId,
                          transactionHash: transactionHash,
                        };
                        let action = "offer2";

                        let price = priceToEther;
                        let count;
                        if (bidCount) {
                          count = bidCount + 1;
                        } else {
                          count = 1;
                        }

                        saveOfferHash(artId, count, transactionHash);
                        handleCollectionHistory(
                          nftDetails?.selectedCollectionId,
                          historyData
                        );
                        // setMakeOfferModal(false);
                        getOfferHistory();

                        const artLink = location.pathname;

                        handleNotifications(
                          address,
                          "newOffer",
                          priceToEther / 1000000000000000000,
                          nftData[0]?.metadata.data?.artName,
                          transactionHash,
                          nftData[0]?.metadata.data.selectedBlockchain,
                          artLink,
                          state[0]?.newOwner
                        );
                        return true;
                      }
                    } catch (error) {
                      return;
                    }
                  }

                  toast.success("Offer made successfully");
                  break;
                } else {
                  reject({ success: false });
                  setOfferStatus("error");
                  toast.error("Transaction Failed!");
                }

                break;
              }
            } catch (error) {
              reject({ success: false });
              setOfferStatus("error");
              toast.error("Transaction Failed!");
            }
          }
        })

        .catch((error) => {
          reject({ success: false, error });
          setOfferStatus("error");
          toast.error("Transaction Failed!");
        });
    });
  };

  // accept offer (owner can accept offer of user and that user will be selected user)

  const handleAcceptOffer = async (bidCount, name, price) => {
    let username = getUserName();
    // alert(bidCount);

    let MarketplaceAddress;
    const tokenData = state[0];
    if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

    const auctionListId = tokenData?.offerCountIndex;

    try {
      // alert("heeree");

      let transaction = {
        to: MarketplaceAddress,
        from: address,
        data: contract.methods.selectUser(auctionListId, bidCount).encodeABI(),
        gas: 200000,
      };

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });
                    setBidModal(false);
                    getOfferHistory();
                    getIsUserSelected();
                    getSelectedUser();

                    const artLink = location.pathname;

                    handleNotifications(
                      name,
                      "offerAccepted",
                      null,
                      nftData[0]?.metadata.data?.artName,
                      hash,
                      nftData[0]?.metadata.data.selectedBlockchain,
                      artLink
                    );

                    let username = getUserName();

                    let action = "acceptOffer";
                    await saveArtworkHistory(
                      hash,
                      address,
                      action,
                      username,
                      artId,
                      price,
                      name
                    );
                    break;
                  } else {
                    reject({ success: false });
                    toast.error("Transaction Failed!");
                  }

                  break;
                }
              } catch (error) {
                toast.error("Transaction Failed!");
              }
            }
          })

          .catch((error) => {
            reject({ success: false, error });
            toast.error("Transaction Failed!");
          });
      });
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };

  const handleRemoveOffer = async (bidCount, name) => {
    let MarketplaceAddress;
    const tokenData = state[0];
    if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);

    const auctionListId = tokenData?.offerCountIndex;

    try {
      // alert("heeree");
      let transaction = {
        to: MarketplaceAddress,
        from: address,
        data: contract.methods
          .cancelOfferPrice(auctionListId, bidCount)
          .encodeABI(),
        gas: 200000,
      };

      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });

                    setBidModal(false);
                    getOfferHistory();
                    getIsUserSelected();
                    getSelectedUser();
                    toast.success("Offer cancelled succesfully!");
                    break;
                  } else {
                    reject({ success: false });
                    toast.error("Transaction Failed!");
                  }
                  break;
                }
              } catch (error) {
                toast.error("Transaction Failed!");
                break;
              }
            }
          })
          .catch((error) => {
            reject({ success: false, error });
            toast.error("Transaction Failed!");
          });
      });
    } catch (error) {
      toast.error("Transaction Failed!");
    }
  };

  // get selected user for which offer is accepted by owner

  const getSelectedUser = async () => {
    if (web3 !== null) {
      let MarketplaceAddress;
      const tokenData = state[0];
      if (
        tokenData?.metadata.data.selectedBlockchain === config.networks.songbird
      ) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (
        tokenData?.metadata.data.selectedBlockchain === config.networks.flare
      ) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }
      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
      const auctionListId = tokenData?.offerCountIndex;
      const result = await contract?.methods
        ?.SelectedUser(auctionListId)
        ?.call();

      setSelectedUser(result);
      if (result !== 0) {
        offerHistory.map((item) => {
          if (item?.bidCount === result) {
            setSelectedBid(item);
            setSelectUserAddress(item?.user);
            setBidUserDetail(item);

            if (item?.user === address) {
              setIsClaimUser(true);
            }
          }
        });
      }
    }
  };

  useEffect(() => {
    getSelectedUser();
  }, [offerHistory, address]);

  const handleCancelList = async () => {
    setLoading(true);

    let MarketplaceAddress;
    const tokenData = state[0];

    const priceInEther = state[0]?.listedData?.price; // Assuming the price is in Wei

    if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      tokenData?.metadata.data.selectedBlockchain === config.networks.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);
    const auctionListId = tokenData?.saleCountIndex;

    let data = contract.methods.CancelListForSale(auctionListId);

    let transaction = {
      from: address,
      to: MarketplaceAddress,
      data: data.encodeABI(),
      gasLimit: 3000000,
    };

    return new Promise((resolve, reject) => {
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          for (let index = 0; index > -1; index++) {
            try {
              const receipt = await web3.eth.getTransactionReceipt(hash);
              if (receipt != null) {
                if (receipt.status) {
                  resolve({ success: true, hash });

                  setLoading(false);
                  const artLink = location.pathname;
                  await handleNotifications(
                    address,
                    "cancelListing",
                    null,
                    nftData[0]?.metadata.data?.artName,
                    hash,
                    nftData[0]?.metadata.data.selectedBlockchain,
                    artLink,
                    state[0]?.newOwner
                  );

                  setListingModal("successListing");

                  let price = priceInEther;

                  let username = getUserName();
                  let action = "cancelListing";

                  await saveArtworkHistory(
                    hash,
                    address,
                    action,
                    username,
                    artId,
                    price,
                    null
                  );

                  getAllListNFTData();
                  await handlecollectionStats(
                    address,
                    priceInEther,
                    nftData[0]?.metadata.data.selectedBlockchain
                  );

                  break;
                } else {
                  reject({ success: false });
                  toast.error("Transaction Failed!");
                  setLoading(false);
                  setListingModal("error");
                }

                break;
              }
            } catch (error) {
              toast.error("Transaction Failed!");
              setLoading(false);
              setListingModal("error");
            }
          }
        })

        .catch((error) => {
          reject({ success: false, error });
          toast.error("Transaction Failed!");
          setLoading(false);
          setListingModal("error");
        });
    });
  };

  // Function to convert price from wei to ether
  const convertWeiToEther = (priceInWei) => {
    if (priceInWei) {
      try {
        // Convert the price from wei to ether, ensuring the input is a string
        const priceInEther = web3.utils.fromWei(priceInWei.toString(), "ether");
        return priceInEther;
      } catch (error) {
        return null;
      }
    }
    return null; // Return null or handle cases where priceInWei is falsy
  };

  const getArtistImage = (accountAddress) => {
    let artistImage = thumb;
    userData?.forEach((user) => {
      if (user?.id === accountAddress) {
        if (user?.image) {
          artistImage = user?.image;
        } else {
          artistImage = thumb;
        }
      }
    });

    return artistImage;
  };

  const handleTagClick = (tagName) => {
    setTagName(tagName);
    navigate("/Explore");
  };

  const getUserName = () => {
    let artistName = "";
    userData?.forEach((user) => {
      if (user?.id === address) {
        artistName = user?.userName;
      }
    });

    return artistName;
  };
  const { data, isError } = useBalance({
    address: address,
  });

  function convertUnixTimestampToDate(unixTimestamp) {
    if (unixTimestamp) {
      // Convert Unix timestamp to milliseconds
      const milliseconds = parseInt(unixTimestamp) * 1000;

      // Create a new Date object
      const date = new Date(milliseconds);

      // Get the day and month
      const day = date.toLocaleDateString("en-US", { weekday: "short" });
      const month = date.toLocaleDateString("en-US", { month: "short" });
      // const month = date.toLocaleDateString('en-US', { month: 'short' });

      // Return the formatted date string
      return `${day}, ${month},${date.getFullYear()}`;
    }
  }

  let key = process.env.REACT_APP_CRYPTO_KEY;

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;

      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;

        setSgb(priceInUSD.toFixed(2));
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;

      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;

        setFlr(priceInUSD.toFixed(2));
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);
  const formatAddress = (address) => {
    if (address?.length <= 11) {
      return address; // If address is too short to format, return it as is
    }
    return `${address?.slice(0, 6)}...${address?.slice(-5)}`;
  };

  const navigateToExplorer = (address) => {
    if (collectionDetails?.selectedNetwork === config.networks.flare) {
      window.open(
        `https://flare-explorer.flare.network/token/${address}`,
        "_blank"
      );
    } else {
      window.open(
        `https://songbird-explorer.flare.network/token/${address}`,
        "_blank"
      );
    }
  };

  const [userImages, setUserImages] = useState(null);
  useEffect(() => {
    const fetchUserImages = async () => {
      const imagePromises = artworkHistory.map(async (entry) => {
        const image = await getUserDataByUserName(entry.username);
        return { username: entry.username, image: image?.documentData?.image };
      });

      const images = await Promise.all(imagePromises);
      const imagesMap = images.reduce((acc, { username, image }) => {
        acc[username] = image;
        return acc;
      }, {});

      setUserImages(imagesMap);
    };

    if (artworkHistory?.length > 0) {
      fetchUserImages();
    }
  }, [artworkHistory]);

  const handlePrice = () => {
    if (!newPrice) {
      toast.error("Enter price to continue");
      return;
    }
    // Simulate blockchain confirmation process
    setListingModal("confirm");
    HandlechangePrice();
  };

  const handleCancelListing = () => {
    // Simulate blockchain confirmation process
    setListingModal("confirm");

    setTimeout(() => {
      // Simulate success or error response
      let success; // Change to false to simulate error
      try {
        handleCancelList();
        success = true;
      } catch (error) {
        return;
      }
    }, 2000);
  };
  const [extractedUris, setExtractedUris] = useState([]);

  useEffect(() => {
    const uris = [];

    if (listedNfts[0]) {
      for (let a of listedNfts[0]) {
        // uris.push(a.uriData);
        uris.push({ uri: a.uriData, tokenId: a.listedData.tokenId });
      }

      for (let b of listedNfts[1]) {
        uris.push({ uri: b.uriData, tokenId: b.listedData.tokenId });
        // uris.push(b.uriData);
      }
    }

    setExtractedUris(uris);
  }, [listedNfts, collectionDetails, collectionId]);

  const [nfts, setNfts] = useState([]);
  useEffect(() => {
    Promise.all(
      extractedUris.map((item) => {
        return getNftMetadata(item.uri).then((metadata) => {
          return { tokenId: item.tokenId, metadata: metadata }; // Store token ID and metadata together
        });
      })
    ).then((nftData) => {
      let uniqueTokenIds = new Set();
      let filtered = nftData?.filter((item) => {
        if (
          item?.metadata?.data?.selectedCollectionId === collectionId &&
          artId &&
          item.tokenId !== tokenId && // Exclude the tokenId stored in the tokenId state
          !uniqueTokenIds.has(item.tokenId)
        ) {
          uniqueTokenIds.add(item.tokenId);
          return true;
        }
        return false;
      });
      setNfts(filtered);
    });
  }, [extractedUris, collectionId, artId, tokenId]);

  const getLastPrice = async () => {
    let res = await getArtLastPriceFromFirebase(nftUri);

    setLastPrice(res / 1000000000000000000);
  };
  useEffect(() => {
    getLastPrice();
  }, [nftUri]);

  const getContractAddress = async (id) => {
    let res = await getContractAddressByCollectionId(id);
    if (res) {
      return res;
    } else {
      return null;
    }
  };

  const handleClick = async (item) => {
    let contractAddress = await getContractAddress(
      item?.metadata?.data?.selectedCollectionId
    );

    if (contractAddress) {
      const url = `/artwork/eth/${contractAddress}/${item?.tokenId}`;

      window.location.href = url;
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      return;
    }
  };
  const navigateToexplorer = (entry) => {
    const network = nftData[0]?.metadata.data.selectedBlockchain;

    let url = "";

    if (network === config.networks.flare) {
      url = `https://flare-explorer.flare.network/tx/${entry.transactionHash}`;
    } else {
      url = `https://songbird-explorer.flare.network/tx/${entry.transactionHash}`;
    }

    window.open(url, "_blank");
  };

  const getTransactionHashByBidCount = (bidCount, offers) => {
    for (const offer of offers) {
      if (String(offer.bidCount) === String(bidCount)) {
        return offer.transactionHash;
      }
    }
    return null; // Return null if no match is found
  };
  const navigatetoOfferExplorer = async (item) => {
    try {
      const res = await getOffersByArtId(artId);

      const hash = getTransactionHashByBidCount(item?.bidCount, res);

      if (!hash) {
        return;
      }

      const network = nftData[0]?.metadata.data.selectedBlockchain;

      let url = "";
      if (network === config.networks.songbird) {
        url = `https://songbird-explorer.flare.network/tx/${hash}`;
      } else {
        url = `https://flare-explorer.flare.network/tx/${hash}`;
      }

      window.open(url, "_blank");
    } catch (error) {
      return;
    }
  };
  // useEffect(() => {
  //   console.log(artworkHistory);
  //   console.log(offerHistory);

  //   const filteredArtworkHistory = artworkHistory.filter(item => item.action === 'offer2');
  //   console.log(filteredArtworkHistory, "tesaf");

  //   const updatedOfferHistory = offerHistory.map(offer => {
  //     const [address, account, price, biddingTime, bidCount, details] = offer;
  //     console.log(biddingTime);
  //     for (let a of filteredArtworkHistory) {
  //       console.log(a.timestamp.seconds);

  //     }

  //     const matchingArtwork = filteredArtworkHistory.find(artwork =>
  //       artwork.timestamp.seconds === biddingTime &&
  //       artwork.price === price
  //     );

  //     console.log(matchingArtwork, "matching artwork");

  //     if (matchingArtwork) {
  //       // Remove the matched artwork from filteredArtworkHistory
  //       filteredArtworkHistory.splice(filteredArtworkHistory.indexOf(matchingArtwork), 1);
  //       return [...offer, { ...details, transactionHash: matchingArtwork.transactionHash }];
  //     }

  //     return offer;
  //   });

  //   setMergedOfferHistory(updatedOfferHistory);

  //   console.log('Merged Offer History:', updatedOfferHistory);
  //   console.log('Remaining Artwork History:', filteredArtworkHistory);
  // }, [artworkHistory, offerHistory]);

  // useEffect(() => {
  //   console.log(mergedOfferHistory, "merged offerHistoy");

  // }, [mergedOfferHistory])

  useEffect(() => {
    if (owner === address) {
      setIsOwner(true);
      if (state[0]?.isSaleListed) {
        if (owner === state[0]?.newOwner) {
          setIsOwner(true);
        }
      }
    } else {
      setIsOwner(false);
    }
  }, [owner, address]);

  useEffect(() => {
    if (
      Number(data?.formatted) <
      state[0]?.listedData?.price / 1000000000000000000
    ) {
      setBuyDisable(true);
    } else {
      setBuyDisable(false);
    }
  }, [data, state]);

  const navigateToExArt = async () => {
    if (state[0]) {
      let url;
      const network = nftData[0]?.metadata.data.selectedBlockchain;
      const mintContract = await getContractAddressByCollectionId(
        nftData[0]?.metadata?.data?.selectedCollectionId
      );
      if (network === config.networks.songbird) {
        url = `https://songbird-explorer.flare.network/token/${mintContract}/instance/${tokenId}`;
      } else {
        url = `https://flare-explorer.flare.network/token/${mintContract}/instance/${tokenId}`;
      }
      window.open(url, "_blank");
    }
  };

  const scrollRef = useRef(null);

  const handleMouseDown = (e) => {
    const scrollContainer = scrollRef.current;
    scrollContainer.isMouseDown = true;
    scrollContainer.startX = e.pageX - scrollContainer.offsetLeft;
    scrollContainer.scrollLeftStart = scrollContainer.scrollLeft;
  };

  const handleMouseMove = (e) => {
    const scrollContainer = scrollRef.current;
    if (!scrollContainer.isMouseDown) return;

    const x = e.pageX - scrollContainer.offsetLeft;
    const walk = x - scrollContainer.startX;
    scrollContainer.scrollLeft = scrollContainer.scrollLeftStart - walk;
  };

  const handleMouseUp = () => {
    const scrollContainer = scrollRef.current;
    scrollContainer.isMouseDown = false;
  };

  useEffect(() => {
    if (address) {
      const web3s = new Web3("https://flare-api.flare.network/ext/C/rpc");
      const web3sgb = new Web3("https://songbird-api.flare.network/ext/C/rpc");
      const wflrAddress = "0x1D80c49BbBCd1C0911346656B529DF9E5c2F783d";
      const wsgbAddress = "0x02f0826ef6aD107Cfc861152B32B52fD11BaB9ED";
      const wflrContract = new web3s.eth.Contract(wrappedAbi, wflrAddress);
      const wsgbContract = new web3sgb.eth.Contract(wrappedAbi, wsgbAddress);
      wflrContract.methods
        .balanceOf(address)
        .call()
        .then((balance) => {
          setWflr(balance);
        })
        .catch((err) => {
          return;
        });
      wsgbContract.methods
        .balanceOf(address)
        .call()
        .then((balance) => {
          setWsgb(balance);
        })
        .catch((err) => {
          return;
        });
    }
  }, [address]);

  return (
    <div>
      <div className="artwork-details">
        <div className="artwork-banner site-containers v-center  ">
          <div className="left ">
            {isLoading && isNewtworkConnected !== null ? (
              <Skeleton.Avatar
                active
                size={600}
                shape="square"
                block={true}
                className="ms-1 w-25 mb-3"
              />
            ) : (
              <div className="banner-img">
                {nftData[0]?.metadata.data?.image ? (
                  <>
                    <Image
                      src={nftData[0]?.metadata.data?.image}
                      alt="art"
                      className=" single-artwork-img artwork-hover"
                    />
                  </>
                ) : nftData[0]?.metadata.data?.videoUrl ? (
                  <video
                    className="img-100 "
                    autoPlay
                    loop
                    muted
                    playsInline
                    controls
                  >
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                  </video>
                ) : (
                  <img
                    src={nftData[0]?.metadata.data?.previewImg} // Replace with the path to your preview image
                    className="img-100 artwork-hover"
                  />
                )}

                {address && (
                  <>
                    {isLiked ? (
                      <img
                        src={liked}
                        alt="like"
                        className={`like-icon img-24 pointer   ${
                          isLiked ? "" : ""
                        }`}
                        onClick={handleLikeClick}
                      />
                    ) : (
                      <img
                        src={like}
                        alt="like"
                        className={`like-icon img-24 pointer   ${
                          isLiked ? "invert1" : ""
                        }`}
                        onClick={handleLikeClick}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="right">
            <div className="artwork-user-wrapper  ">
              {isLoading ? (
                <>
                  <Skeleton.Input
                    active
                    size="large"
                    block={true}
                    className="ms-1 w-25 mb-3"
                  />
                </>
              ) : (
                <div className="collection-name v-center">
                  <p
                    className="body-medium fw-bold text-black no-text-transform v-center pointer hover-underline "
                    onClick={() =>
                      navigate(
                        `/explore-collections/${nftDetails?.selectedCollectionId}`
                      )
                    }
                  >
                    {nftData[0]?.metadata.data?.selectedCollection}
                    <img
                      src={verified}
                      alt="verify"
                      className="img-18 img-fluid ms-2"
                    />
                  </p>

                  <label
                    style={{ cursor: "pointer" }}
                    onClick={navigateToExArt}
                    className="no-text-transform br-30"
                  >
                    {nftData[0]?.metadata.data?.selectedBlockchain ===
                    config.networks.flare
                      ? "Flare"
                      : nftData[0]?.metadata.data?.selectedBlockchain ===
                        config.networks.songbird
                      ? "Songbird"
                      : nftData[0]?.metadata.data?.selectedBlockchain}
                  </label>
                </div>
              )}
              <br />
              {isLoading ? (
                <>
                  <Skeleton.Input
                    active
                    size="large"
                    block={true}
                    className="ms-1 w-25 mt-2"
                  />
                </>
              ) : (
                <h3 className="fw-bold">
                  {nftData[0]?.metadata.data?.artName}
                </h3>
              )}

              <div className="artist-owner v-center gap-5 mt-30">
                {isLoading ? (
                  <div className="d-flex align-items-center gap-5">
                    <div className="d-flex align-items-center">
                      <Skeleton.Avatar active size="large" shape="circle" />
                      <Skeleton.Input
                        active
                        size="small"
                        block={false}
                        className="ms-1"
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <Skeleton.Avatar active size="large" shape="circle" />
                      <Skeleton.Input
                        active
                        size="small"
                        block={false}
                        className="ms-1"
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    {(state[0]?.newOwner || owner) && (
                      <>
                        <UserProfile
                          status="artist"
                          address={nftData[0]?.metadata?.data?.artistAddress}
                          username={getUserName()}
                          image={getArtistImage(
                            nftData[0]?.metadata?.data?.artistAddress
                          )}
                        />
                        <UserProfile
                          status="owner"
                          address={state[0]?.newOwner || owner}
                          username={getUserName()}
                          image={getArtistImage(state[0]?.newOwner)}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>

            {address && (
              <div className="pricing-wrapper">
                <div className="list-price mt-40">
                  {isSaleListed && (
                    <>
                      <label className="medium text-grey no-text-transform fw-normal pb-2">
                        List price
                      </label>

                      <div className="price-value v-end">
                        <span className="v-center">
                          <img
                            src={
                              state[0]?.metadata?.data.selectedBlockchain ===
                              config.networks.songbird
                                ? SGB
                                : FLR
                            }
                            alt="flr"
                            className="me-2"
                          />

                          {/* {listedType === 1  ? ( */}
                          <h6 className="font-normal">
                            {convertWeiToEther(state[0]?.listedData?.price)}
                          </h6>
                        </span>
                        <p className="body-medium  mb--2 ms-1">
                          {state[0]?.metadata?.data.selectedBlockchain ===
                          config.networks.songbird ? (
                            <span className="fw-semibold body-large">SGB</span>
                          ) : (
                            <span className="fw-semibold body-large">FLR</span>
                          )}

                          {isSaleListed ? (
                            <span className="text-medium-grey body-medium fw-medium fw-500 ms-1">
                              {" "}
                              ($
                              {state[0]?.metadata?.data.selectedBlockchain ===
                              config.networks.songbird
                                ? (
                                    convertWeiToEther(
                                      state[0]?.listedData?.price
                                    ) * sgb
                                  ).toFixed(2)
                                : (
                                    convertWeiToEther(
                                      state[0]?.listedData?.price
                                    ) * flr
                                  ).toFixed(2)}
                              )
                            </span>
                          ) : (
                            <span className="text-medium-grey ms-1">
                              {" "}
                              ($
                              {state[0]?.metadata?.data.selectedBlockchain ===
                              config.networks.flare
                                ? (
                                    state[0]?.listedData?.minimumBid * sgb
                                  ).toFixed(2)
                                : (
                                    state[0]?.listedData?.minimumBid * flr
                                  ).toFixed(2)}
                              )
                            </span>
                          )}
                        </p>
                      </div>
                    </>
                  )}

                  {/* btns */}
                  {isLoading ? (
                    <Skeleton.Input
                      active
                      size="large"
                      block={true}
                      className="ms-1 w-25 mt-2"
                    />
                  ) : (
                    <>
                      {state &&
                      address &&
                      biddingCount == null &&
                      isNewtworkConnected ? (
                        <div className="artwork-btns v-center gap-10 mt-30">
                          {/* If the item is sale listed */}

                          {!isSaleListed &&
                            (state[0]?.newOwner === address ? (
                              isNewtworkConnected ? (
                                <Button
                                  text="List for sale"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={() => {
                                    navigate(`/list-forSale`, {
                                      state: {
                                        data: nftData[0]?.metadata.data,
                                        nftDetails: state[0],
                                      },
                                    });
                                  }}
                                />
                              ) : (
                                <Button
                                  text="Switch Network To List"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={handleSwitchChain}
                                />
                              )
                            ) : null)}

                          {state[0]?.newOwner !== address &&
                            earlyAccess == false &&
                            (isNewtworkConnected == true ? (
                              <Button
                                text="Make offer"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={openOffer}
                              />
                            ) : (
                              <Button
                                text="Switch Network"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleSwitchChain}
                              />
                            ))}

                          {/* If both sale and offer are listed */}
                          {isSaleListed &&
                            isOfferListed &&
                            (state[0]?.newOwner === address ? (
                              isNewtworkConnected ? (
                                <Button
                                  text="Edit listing"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={handleEditListModal}
                                />
                              ) : (
                                <Button
                                  text="Switch Network"
                                  className="btn-prime btn-primary px-3"
                                  width="176px"
                                  height="36px"
                                  onClick={handleSwitchChain}
                                />
                              )
                            ) : isNewtworkConnected ? (
                              <>
                                <Button
                                  text={"Buy now"}
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={handleBuyArtModal}
                                  imageSrc={
                                    eligibleBuyer || saleType != "primary"
                                      ? ""
                                      : lock
                                  }
                                  imageClassName={"w-18 order-2 ms-1"}
                                />
                              </>
                            ) : (
                              <>
                                {!loading && (
                                  <Button
                                    text="Switch Network"
                                    className="btn-prime btn-primary"
                                    width="176px"
                                    height="36px"
                                    onClick={handleSwitchChain}
                                  />
                                )}
                              </>
                            ))}

                          {/* Claim Pending Button */}
                          {biddingCount &&
                            (state[0]?.newOwner == address ? (
                              <Button
                                text="Claim Pending"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                disabled
                              />
                            ) : selectUserAddress == address ? (
                              approveTokens ? (
                                <Button
                                  text="Claim Pending"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={ClaimNft}
                                />
                              ) : (
                                <Button
                                  text="Approve Tokens"
                                  className={`btn-prime ${
                                    selectedUser === 0
                                      ? "btn-ternary mt-2"
                                      : "btn-primary mt-2"
                                  }`}
                                  width="300px"
                                  height="30px"
                                  onClick={getTokenApproved}
                                />
                              )
                            ) : selectUserAddress !== state[0]?.newOwner ? (
                              <Button
                                text="Pending"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                disabled
                              />
                            ) : isNewtworkConnected ? (
                              approveTokens ? (
                                <Button
                                  text="Claim Pending"
                                  className="btn-prime btn-primary"
                                  width="176px"
                                  height="36px"
                                  onClick={ClaimNft}
                                />
                              ) : (
                                <Button
                                  text="Approve Tokens"
                                  className={`btn-prime ${
                                    selectedUser === 0
                                      ? "btn-ternary mt-2"
                                      : "btn-primary mt-2"
                                  }`}
                                  width="300px"
                                  height="30px"
                                  onClick={getTokenApproved}
                                />
                              )
                            ) : (
                              <Button
                                text="Switch Network"
                                className="btn-prime btn-primary"
                                width="176px"
                                height="36px"
                                onClick={handleSwitchChain}
                              />
                            ))}

                          {/* More Options */}
                          {isNewtworkConnected &&
                            !(
                              state[0]?.isListed === false && owner !== address
                            ) && (
                              <div className="more-opt cursor-pointer dropdown rounded-circle option-btn img-35">
                                <img
                                  src={option}
                                  alt=""
                                  className="dropdown-toggle no-after"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                />
                                <ul className="dropdown-menu no-border bg-transparent">
                                  <Dropdown
                                    listed={
                                      state[0]?.isSaleListed
                                        ? "listed"
                                        : "noListed"
                                    }
                                    item={state[0]}
                                    nftDetails={state[0]}
                                    tokenId={tokenId ? tokenId : null}
                                    owner={state[0]?.newOwner || owner}
                                    LocationArt={"singleArt"}
                                    artId={artId}
                                  />
                                </ul>
                              </div>
                            )}
                        </div>
                      ) : isNewtworkConnected == null ? (
                        <Skeleton.Input
                          active
                          size="large"
                          block={true}
                          className="ms-1 w-25 mt-2"
                        />
                      ) : (
                        !isLoading &&
                        isNewtworkConnected == false && (
                          <Button
                            text="Switch Network"
                            className="btn-prime btn-primary mt-2"
                            width="176px"
                            height="36px"
                            onClick={handleSwitchChain}
                          />
                        )
                      )}
                    </>
                  )}
                  {biddingCount && (
                    <div className="artwork-btns v-center gap-10 mt-30">
                      {state[0]?.newOwner === address ? (
                        <Button
                          text="Claim Pending"
                          className="btn-prime btn-primary"
                          width="176px"
                          height="36px"
                          disabled={true}
                        />
                      ) : selectedUser === address ? (
                        approveTokens ? (
                          <Button
                            text="Claim Pending"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            disabled={false}
                            onClick={ClaimNft}
                          />
                        ) : (
                          <Button
                            text="Approve Tokens"
                            className="btn-prime btn-primary"
                            width="176px"
                            height="36px"
                            disabled={false}
                            onClick={getTokenApproved}
                          />
                        )
                      ) : selectUserAddress !== address ? (
                        <Button
                          text="Pending"
                          className="btn-prime btn-primary"
                          width="176px"
                          height="36px"
                          disabled={true}
                        />
                      ) : approveTokens ? (
                        <Button
                          text="Claim Pending"
                          className="btn-prime btn-primary"
                          width="176px"
                          height="36px"
                          disabled={false}
                          onClick={ClaimNft}
                        />
                      ) : (
                        <Button
                          text="Approve Tokens"
                          className="btn-prime btn-primary"
                          width="176px"
                          height="36px"
                          disabled={false}
                          onClick={getTokenApproved}
                        />
                      )}
                    </div>
                  )}

                  {
                    <div className="artwork-btns  v-center gap-10 d-none">
                      <Button
                        text="Edit listing"
                        className="btn-prime btn-primary"
                        width="176px"
                        height="36px"
                        onClick={handleEditListModal}
                      />
                      {isNewtworkConnected &&
                        !(
                          state[0]?.isListed === false &&
                          owner !== address &&
                          isNewtworkConnected
                        ) && (
                          <div className="more-opt cursor-pointer dropdown rounded-circle option-btn img-35">
                            <img
                              src={option}
                              alt=""
                              className="dropdown-toggle no-after"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <ul class="dropdown-menu no-border bg-transparent">
                              <Dropdown
                                listed={
                                  state[0]?.isSaleListed ? "listed" : "noListed"
                                }
                                item={state[0]?.metadata?.data}
                                nftDetails={state[0]}
                                tokenId={tokenId ? tokenId : null}
                                owner={state[0]?.newOwner || owner}
                                LocationArt={"singleArt"}
                                artId={artId}
                              />
                            </ul>
                          </div>
                        )}
                    </div>
                  }
                </div>
                {earlyAccess && saleType == "primary" && (
                  <div className="membership mt-40">
                    <div className="d-flex align-items-center ">
                      <span className="circle me-2"> </span>
                      <span className="early-access">Early Access</span>

                      <span
                        className={`membership-level member-ship  ${
                          activeMembership === "Standard"
                            ? " standard"
                            : activeMembership === "Gold"
                            ? " gold"
                            : activeMembership === "Silver"
                            ? " silver"
                            : activeMembership === "Bronze"
                            ? " bronze"
                            : ""
                        }`}
                      >
                        {activeMembership != "Standard" ? activeMembership : ""}{" "}
                        Member
                      </span>
                    </div>

                    {timeRemaining && (
                      <span className="time-remaining">{timeRemaining}</span>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="artwork-details-content mt-60">
          <div className="left-content">
            <div className="history ">
              <h5 className="fw-bold no-text-transform">History</h5>
              <div className="history-content">
                {isLoading ? (
                  <Space
                    direction="vertical"
                    size="large"
                    style={{ width: "100%" }}
                  >
                    {/* First Action */}
                    <Space align="start">
                      <Skeleton.Avatar active size="small" shape="circle" />{" "}
                      {/* Icon */}
                      <Space direction="vertical" size="small">
                        <Skeleton.Input active style={{ width: 200 }} />{" "}
                        {/* Action text */}
                        <Skeleton.Input active style={{ width: 150 }} />{" "}
                        {/* Timestamp */}
                      </Space>
                    </Space>

                    {/* Second Action */}
                    <Space align="start">
                      <Skeleton.Avatar active size="small" shape="circle" />{" "}
                      {/* Icon */}
                      <Space direction="vertical" size="small">
                        <Skeleton.Input active style={{ width: 150 }} />{" "}
                        {/* Action text */}
                        <Skeleton.Input active style={{ width: 150 }} />{" "}
                        {/* Timestamp */}
                      </Space>
                    </Space>
                  </Space>
                ) : (
                  <div className="history-list">
                    {[...offerHistory, ...artworkHistory]
                      .sort((a, b) => {
                        const timeA = a?.biddingTime
                          ? a?.biddingTime * 1000
                          : a?.timestamp?.seconds * 1000;
                        const timeB = b?.biddingTime
                          ? b?.biddingTime * 1000
                          : b?.timestamp?.seconds * 1000;
                        return timeB - timeA;
                      })
                      .map((item, index) => {
                        const isOfferHistory = "biddingTime" in item;

                        if (isOfferHistory) {
                          if (!item.isVisible) return null;

                          const isSelectedUser =
                            bidUserDetail?.user === address &&
                            address &&
                            selectUserAddress === address;
                          const isMatchingBiddingCount =
                            item?.bidCount === biddingCount;
                          const isOwner = state[0]?.newOwner === address;
                          const isOfferAccepted = item?.claim === false;

                          return (
                            <div key={index} className="histrory-list">
                              <div className="left img-24 v-center justify-content-center">
                                <img
                                  src={offerMade}
                                  alt="offer"
                                  className="img-14"
                                />
                              </div>
                              <div className="right">
                                <Popover
                                  placement="bottom"
                                  trigger="hover"
                                  content={
                                    <ArtistPopUp
                                      userProfile={getArtistImage(item?.user)}
                                      verified={verified}
                                      artistName={getArtistNamebyAdress(
                                        item?.user
                                      )}
                                    />
                                  }
                                >
                                  <p className="body-medium">
                                    <span
                                      onClick={() => {
                                        navigate(`/profile/${item?.user}`);
                                      }}
                                      className="position-relative pointer hover-underline"
                                    >
                                      {getArtistNamebyAdress(item?.user)}
                                    </span>{" "}
                                    made an offer of{" "}
                                    {convertWeiToEther(item?.price)} FLR
                                  </p>
                                </Popover>

                                <label
                                  onClick={() => navigatetoOfferExplorer(item)}
                                  className="small pointer hover-underline"
                                >
                                  {new Date(
                                    item?.biddingTime * 1000
                                  ).toLocaleString()}
                                  <img
                                    src={arrow}
                                    alt=""
                                    className="img-12 ms-1"
                                  />
                                </label>

                                {item?.withdrawn ? (
                                  <p>withdrawn</p>
                                ) : isSelectedUser && isMatchingBiddingCount ? (
                                  approveTokens ? (
                                    <Button
                                      text="Claim"
                                      className={`btn-prime ${
                                        selectedUser === 0
                                          ? "btn-ternary mt-2"
                                          : "btn-primary mt-2"
                                      }`}
                                      width="100px"
                                      height="30px"
                                      onClick={ClaimNft}
                                    />
                                  ) : (
                                    <Button
                                      text="Approve Tokens"
                                      className={`btn-prime ${
                                        selectedUser === 0
                                          ? "btn-ternary mt-2"
                                          : "btn-primary mt-2"
                                      }`}
                                      width="300px"
                                      height="30px"
                                      onClick={getTokenApproved}
                                    />
                                  )
                                ) : (
                                  <>
                                    {isOwner &&
                                    isOfferAccepted &&
                                    isMatchingBiddingCount ? (
                                      <Button
                                        text="Pending"
                                        className={`btn-prime btn-primary mt-2`}
                                        width="100px"
                                        height="30px"
                                        disabled={true}
                                      />
                                    ) : (
                                      <>
                                        {address &&
                                          isNewtworkConnected &&
                                          !biddingCount && (
                                            <>
                                              {isOwner &&
                                              item?.claim === false &&
                                              !biddingCount &&
                                              !item?.withdrawn ? (
                                                <Button
                                                  text="Accept offer"
                                                  className={`btn-prime ${
                                                    selectedUser === 0
                                                      ? "btn-ternary mt-2"
                                                      : "btn-primary mt-2"
                                                  }`}
                                                  width="100px"
                                                  height="30px"
                                                  disabled={selectedUser === 0}
                                                  onClick={() =>
                                                    handleAcceptOffer(
                                                      item?.bidCount,
                                                      item.user,
                                                      item?.price
                                                    )
                                                  }
                                                />
                                              ) : (
                                                item?.user === address &&
                                                item?.claim === false && (
                                                  <Button
                                                    text="Remove offer"
                                                    className={`btn-prime ${
                                                      selectedUser === 0
                                                        ? "btn-ternary mt-2"
                                                        : "btn-primary mt-2"
                                                    }`}
                                                    width="100px"
                                                    height="30px"
                                                    onClick={() =>
                                                      handleRemoveOffer(
                                                        item?.bidCount,
                                                        item.user
                                                      )
                                                    }
                                                  />
                                                )
                                              )}
                                            </>
                                          )}
                                        {!isNewtworkConnected && address && (
                                          <Button
                                            text="Switch Network"
                                            className="btn-prime btn-primary"
                                            width="190px"
                                            height="36px"
                                            onClick={handleSwitchChain}
                                          />
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return (
                            <React.Fragment key={index}>
                              <div className="history-item d-flex mb-3">
                                <div className="left img-24 v-center justify-content-center">
                                  <img
                                    src={listed}
                                    alt="action"
                                    className="img-14"
                                  />
                                </div>
                                <div className="right">
                                  <p className="body-medium">
                                    {item.action === "Listed for sale" &&
                                      `Listed for sale by `}
                                    {item.action === "Mint" && `Minted by `}
                                    {item.action === "sold" && `Sold to `}
                                    {item.action === "cancelListing" &&
                                      `Listing cancelled by `}
                                    {item.action !== "claimNft" && (
                                      <Popover
                                        placement="bottom"
                                        trigger="hover"
                                        content={
                                          <ArtistPopUp
                                            userProfile={getArtistImage(
                                              item?.address
                                            )}
                                            verified={verified}
                                            artistName={getArtistNamebyAdress(
                                              item?.address
                                            )}
                                          />
                                        }
                                      >
                                        <span
                                          onClick={() => {
                                            navigate(
                                              `/profile/${item?.address}`
                                            );
                                          }}
                                          className="position-relative pointer hover-underline"
                                        >
                                          {getArtistNamebyAdress(item?.address)}
                                        </span>
                                      </Popover>
                                    )}
                                    {item.action === "Listed for sale" &&
                                      ` for ${item.price} ${
                                        collectionDetails?.selectedNetwork ===
                                        config.networks.flare
                                          ? "FLR"
                                          : "SGB"
                                      }`}{" "}
                                    {item.action === "acceptOffer" && (
                                      <>
                                        {` accepted offer of ${
                                          item.price / 1000000000000000000
                                        }  ${
                                          collectionDetails?.selectedNetwork ===
                                          config.networks.flare
                                            ? "FLR"
                                            : "SGB"
                                        } made by  `}
                                        <Popover
                                          placement="bottom"
                                          trigger="hover"
                                          content={
                                            <ArtistPopUp
                                              userProfile={getArtistImage(
                                                item?.to
                                              )}
                                              verified={verified}
                                              artistName={getArtistNamebyAdress(
                                                item?.to
                                              )}
                                            />
                                          }
                                        >
                                          <span
                                            onClick={() => {
                                              navigate(`/profile/${item?.to}`);
                                            }}
                                            className="position-relative pointer hover-underline"
                                          >
                                            {getArtistNamebyAdress(item?.to)}
                                          </span>
                                        </Popover>
                                      </>
                                    )}
                                    {item.action === "sold" &&
                                      ` for ${
                                        item.price / 1000000000000000000
                                      } ${
                                        collectionDetails?.selectedNetwork ===
                                        config.networks.flare
                                          ? "FLR"
                                          : "SGB"
                                      }`}
                                    {item.action === "transfer" && (
                                      <>
                                        {" transferred art to "}
                                        <Popover
                                          placement="bottom"
                                          trigger="hover"
                                          content={
                                            <ArtistPopUp
                                              userProfile={getArtistImage(
                                                item?.to
                                              )}
                                              verified={verified}
                                              artistName={getArtistNamebyAdress(
                                                item?.to
                                              )}
                                            />
                                          }
                                        >
                                          <span
                                            onClick={() =>
                                              navigate(`/profile/${item?.to}`)
                                            }
                                            className="position-relative pointer hover-underline"
                                          >
                                            {getArtistNamebyAdress(item?.to)}
                                          </span>
                                        </Popover>
                                      </>
                                    )}
                                    {item.action === "claimNft" && (
                                      <>
                                        {`Claimed by `}{" "}
                                        <Popover
                                          placement="bottom"
                                          trigger="hover"
                                          content={
                                            <ArtistPopUp
                                              userProfile={getArtistImage(
                                                item?.address
                                              )}
                                              verified={verified}
                                              artistName={getArtistNamebyAdress(
                                                item?.address
                                              )}
                                            />
                                          }
                                        >
                                          <span
                                            onClick={() => {
                                              navigate(
                                                `/profile/${item?.address}`
                                              );
                                            }}
                                            className="position-relative pointer hover-underline"
                                          >
                                            {getArtistNamebyAdress(
                                              item?.address
                                            )}
                                          </span>
                                        </Popover>
                                      </>
                                    )}
                                    {item.action === "priceChange" && (
                                      <>
                                        {`updated the price to ${item?.price}`}{" "}
                                        {collectionDetails?.selectedNetwork ===
                                        config.networks.flare
                                          ? "FLR"
                                          : "SGB"}
                                      </>
                                    )}
                                  </p>

                                  <label
                                    onClick={() => navigateToexplorer(item)}
                                    className="small pointer hover-underline"
                                  >
                                    {new Date(
                                      item.timestamp.seconds * 1000
                                    ).toLocaleString()}
                                    <img
                                      src={arrow}
                                      alt=""
                                      className="img-12 ms-1"
                                    />
                                  </label>
                                </div>
                              </div>
                            </React.Fragment>
                          );
                        }
                      })}
                  </div>
                )}

                {/* offer */}
              </div>
            </div>
          </div>
          <div className="right-content">
            <div className="artwork-accord">
              <Accordion defaultActiveKey={["0", "1", "2", "3"]} alwaysOpen>
                {/* Description  */}
                {nftDetails?.description && (
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <p className="h-64 body-medium text-uppercase  fw-bold text-se  v-center cursor-pointer">
                        Description
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p className="body-small description">
                        {nftDetails?.description}
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* traits */}
                {nftDetails?.traits?.length > 0 && (
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                        traits
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="traits">
                        {nftDetails?.traits && nftDetails.traits?.length > 0 ? (
                          nftDetails.traits.map((trait, index) => {
                            // Check if trait.type and trait.name are not empty
                            if (trait.type && trait.name) {
                              return (
                                <div key={index} className="trait-box">
                                  <label className="small">{trait.type}</label>
                                  <ul>
                                    <li>{trait.name}</li>
                                  </ul>
                                </div>
                              );
                            } else {
                              return null; // Skip rendering if type or name is empty
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}

                {/* details */}
                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                      details
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="details">
                      <label className="small">contract address</label>
                      <label
                        onClick={() =>
                          navigateToExplorer(collectionDetails?.contractAddress)
                        }
                        className="medium text-black text-underline no-text-transform pointer"
                      >
                        {isLoading ? (
                          <Skeleton.Input active style={{ width: 150 }} />
                        ) : (
                          formatAddress(collectionDetails?.contractAddress)
                        )}
                      </label>
                    </div>

                    <div className="details">
                      <label className="small">token standard </label>
                      <label className="medium text-black   ">
                        {isLoading ? (
                          <Skeleton.Input active style={{ width: 150 }} />
                        ) : (
                          <span> Erc-721</span>
                        )}
                      </label>
                    </div>
                    <div className="details">
                      <label className="small">BLOCKCHAIN </label>
                      <label className="medium text-black no-text-transform">
                        {isLoading ? (
                          <Skeleton.Input active style={{ width: 150 }} />
                        ) : (
                          <span>
                            {" "}
                            {collectionDetails?.selectedNetwork ===
                            config.networks.flare
                              ? "Flare "
                              : "Songbird "}
                          </span>
                        )}
                      </label>
                    </div>
                    <div className="details">
                      <label className="small">Creator Earnings </label>
                      <label className="medium text-black  ">
                        {" "}
                        {isLoading ? (
                          <Skeleton.Input active style={{ width: 150 }} />
                        ) : (
                          <span> 5%</span>
                        )}
                      </label>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>

                {/* tags */}
                {nftDetails?.selectedTags?.length > 0 && (
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <p className="h-64 body-medium fw-bold text-uppercase  v-center cursor-pointer">
                        tags
                      </p>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="mt-22">
                        <CustomCheckBox
                          values={nftDetails?.selectedTags}
                          disabled={true}
                          // onTagClick={handleTagClick}
                          type={"singleArt"}
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                )}
              </Accordion>
            </div>
          </div>
        </div>

        <div className="more-collections site-container pb-sm-0">
          {nfts?.length > 0 && (
            <>
              <h5 className="fw-bold text-center no-text-transform hide-on-mobile ">
                More from this collection
              </h5>
              <h6 className="fw-bold text-center no-text-transform hide-on-desktop  text-normal font-normal">
                More from this collection
              </h6>
            </>
          )}
          <div
            className="collection-cards horizontal-scroll-container"
            ref={scrollRef}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseUp}
            onMouseUp={handleMouseUp}
            onMouseMove={handleMouseMove}
          >
            {isLoading ? (
              <div style={{ padding: "20px" }}>
                <h2 style={{ textAlign: "center", marginBottom: "20px" }}></h2>
                <Row gutter={[16, 16]} justify="center">
                  {Array.from({ length: 6 }).map((_, index) => (
                    <Col xs={24} sm={12} md={8} lg={6} xl={4} key={index}>
                      <Card
                        hoverable
                        style={{
                          width: "200px",
                          borderRadius: "none",
                          overflow: "hidden",
                          height: "200px",
                        }}
                      ></Card>
                    </Col>
                  ))}
                </Row>
              </div>
            ) : (
              nfts.map((item, index) => {
                return (
                  <div key={index} className="collection-card">
                    <div
                      onClick={() => handleClick(item)}
                      className="head pointer"
                    >
                      {item?.metadata?.data.image ? (
                        <img src={item?.metadata?.data.image} alt="" />
                      ) : (
                        <img src={item?.metadata?.data.previewImg} alt="" />
                      )}
                    </div>
                    <label className="fw-bold medium text-black no-text-transform ">
                      {item?.metadata?.data.artName?.substring(0, 20)}
                    </label>

                    <label
                      onClick={() => handleClick(item)}
                      className="fw-bold small  v-center pointer hover-underline"
                    >
                      VIEW ARTWORK
                      <img src={arrow} alt="" className="img-12 ms-2" />
                    </label>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>

      <Modal
        show={createFinish}
        onHide={handleCreateFinish}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3 text-bold">All Done</h5>

            <p className="body-medium ">
              You created {nftData[0]?.metadata.data?.artName}
              <span className="fw-500">
                {/* {nftData[0]?.metadata.data?.artName} */}
              </span>
            </p>

            {isLoading ? (
              <Skeleton.Avatar
                active
                size={240}
                shape="square"
                block={true}
                className="  mb-3 d-blocl"
              />
            ) : (
              <img
                src={nftDetails?.image || nftDetails?.previewImg}
                alt="img"
              />
            )}
            <Button
              text="Done"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={handleCreateFinish}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={finishListing}
        onHide={handleFinishListing}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3 text-bold">All Done</h5>

            <p className="body-medium">
              You listed{" "}
              <span className="fw-500">
                {nftData[0]?.metadata.data?.artName}
              </span>
            </p>

            {isLoading ? (
              <Skeleton.Avatar
                active
                size={240}
                shape="square"
                block={true}
                className="  mb-3 d-blocl"
              />
            ) : (
              <img
                src={nftDetails?.image || nftDetails?.previewImg}
                alt="img"
              />
            )}

            <Button
              text="Done"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={handleFinishListing}
            />
          </div>
        </Modal.Body>
      </Modal>
      {/* transfer */}
      <Modal
        show={artTransfer}
        onHide={handleArtTransfer}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Transfer
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="art-transfer v-center flex-column pt-5 mt-2">
            <img src={art} alt="img" className="nft-img" />
            <p className="body-medium mt-40 mb-3 pb-3">
              Transfer <span className="fw-bold">“{nftDetails?.artName}” </span>{" "}
              to
            </p>

            <div style={{ maxWidth: "487px" }} className="w-100  ">
              <InputBox
                type="text"
                placeholder="Address e.g 0x1H3a..."
                labelClass="d-none"
                name="transferAddress"
                value={transferAddress}
                onChange={(e) => setTransferAddress(e.target.value)}
              />
            </div>

            <div className="h-center gap-3 mt-5 mb-70">
              <Button
                text="Cancel"
                className="btn-prime btn-primary"
                width="131px"
                height="36px"
                onClick={handleArtTransfer}
              />{" "}
              <Button
                text="Transfer"
                className={`btn-prime border-0 ${
                  transferAddress?.length > 0 ? "btn-primary" : "btn-ternary"
                }`}
                width="131px"
                height="36px"
                onClick={handleArtTransfer}
                disabled={transferAddress?.length > 0 ? "" : "disabled"}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Buy now modal */}

      <Modal
        show={buyArtModal}
        onHide={handleBuyArtModal}
        className="sign-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              {earlyAccess ? "Early Access" : "Buy Now"}
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <>
            {eligibleBuyer ? (
              <>
                {buyStatus === "" && (
                  <div className="buy-now-modal w-431 m-auto">
                    <div className="listed-item v-center">
                      <div className="left">
                        {nftDetails?.image ? (
                          <img src={nftDetails?.image} alt="img" />
                        ) : (
                          <video
                            className="img-100"
                            loop
                            playsInline
                            autoPlay
                            muted
                          >
                            <source
                              src={nftDetails?.videoUrl}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                      <div className="right ms-3">
                        <div className="new-changes">
                          <div className="name">
                            <label className="larger fw-bold text-black no-text-transform">
                              {nftDetails?.artName}
                            </label>
                            <p className="body-medium">{`${nftDetails?.selectedCollection}`}</p>
                          </div>
                          <div className="price">
                            <p className="fw-bold text-black no-text-transform">
                              {state[0]?.listedData?.price /
                                1000000000000000000}{" "}
                              <span>
                                {" "}
                                {state[0]?.metadata?.data.selectedBlockchain ===
                                config.networks.songbird
                                  ? "SGB"
                                  : "FLR"}
                              </span>
                            </p>
                            <p className="amount-in-dollar">
                              ~{" "}
                              {state[0]?.metadata?.data.selectedBlockchain ===
                              config.networks.songbird
                                ? `$${(
                                    (state[0]?.listedData?.price /
                                      1000000000000000000) *
                                    sgb
                                  ).toFixed(2)}`
                                : `$${(
                                    (state[0]?.listedData?.price /
                                      1000000000000000000) *
                                    flr
                                  ).toFixed(2)}`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="catalyst-fee">
                      {/* Display Catalyst Fees percentage, rounding the totalPrice to two decimal places */}
                      <p>
                        Catalyst Fees ({totalPrice}%){" "}
                        <span
                          className={`membership-level member-ship${
                            userMembership === "Standard"
                              ? " standard"
                              : activeMembership === "Gold"
                              ? " gold"
                              : activeMembership === "Silver"
                              ? " silver"
                              : activeMembership === "Bronze"
                              ? " bronze"
                              : ""
                          }`}
                        >
                          {userMembership !== "Standard" ? userMembership : ""}{" "}
                          {userMembership && "Member"}
                        </span>
                      </p>

                      {/* Ensure marketplace percentage is rounded to two decimal places to avoid floating-point precision issues */}
                      <p>
                        {marketPlacePercentage.toFixed(2)}{" "}
                        {state[0]?.metadata?.data.selectedBlockchain ===
                        config.networks.songbird
                          ? "SGB"
                          : "FLR"}
                      </p>
                    </div>

                    <label className="mt-4 pt-1 text-black">Price</label>

                    <div className="pricing body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between">
                      <Input
                        className="body-medium fw-bold text-black br-30 w-100 ps-1"
                        disabled
                        placeholder="Price"
                        value={
                          state[0]?.listedData?.price / 1000000000000000000
                        }
                      />
                      <span>
                        {state[0]?.metadata?.data.selectedBlockchain ===
                        config.networks.songbird
                          ? "SGB"
                          : "FLR"}
                      </span>
                    </div>
                    <div className="avail-blnc mt-2 pt-1">
                      <p className="body-medium v-center justify-content-between">
                        {state[0]?.metadata?.data.selectedBlockchain ===
                        config.networks.songbird
                          ? `$${(
                              (state[0]?.listedData?.price /
                                1000000000000000000 +
                                totalPrice / 1000000000000000000) *
                              sgb
                            ).toFixed(2)}`
                          : `$${(
                              (state[0]?.listedData?.price /
                                1000000000000000000 +
                                totalPrice / 1000000000000000000) *
                              flr
                            ).toFixed(2)}`}
                        <span>
                          Available balance:
                          <span className="fw-bold">
                            {" "}
                            {Number(data?.formatted).toFixed(2)}{" "}
                            {state[0]?.metadata?.data.selectedBlockchain ===
                            config.networks.songbird
                              ? "SGB"
                              : "FLR"}
                          </span>
                        </span>
                      </p>
                      <p className="body-small text-grey text-center mt-3">
                        Please note, there are 5% royalties associated with all
                        artwork.
                      </p>
                    </div>

                    {isNewtworkConnected ? (
                      <Button
                        text="Buy now"
                        className={`btn-prime ${
                          buyDisable ? "btn-ternary" : "btn-primary"
                        } mt-4`}
                        width="100%"
                        height="36px"
                        onClick={handleBuyNow}
                        disabled={buyDisable}
                      />
                    ) : (
                      <Button
                        text="Switch Network"
                        className="btn-prime btn-primary mt-4"
                        width="100%"
                        height="36px"
                        onClick={handleSwitchChain}
                      />
                    )}
                  </div>
                )}

                {buyStatus === "confirming" && (
                  <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
                    <div className="wallet h-center m-auto">
                      <img src={wallet} alt="wallet" className="img-100" />
                    </div>

                    <p className="text-center body-medium mt-3 pt-1">
                      Confirm this transaction in your wallet.
                    </p>

                    <p className="body-medium fw-bold text-center mt-30">
                      Waiting for blockchain confirmation…
                    </p>

                    <Button
                      text=""
                      className="btn-prime btn-primary mt-50"
                      width="138px"
                      height="36px"
                      imageSrc={loader}
                      imageClassName="rotate-360"
                    />
                  </div>
                )}

                {buyStatus === "error" && (
                  <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
                    <div className="wallet h-center m-auto">
                      <img src={error} alt="wallet" className="img-100" />
                    </div>

                    <p className="text-center body-medium mt-3 pt-1">
                      Sorry, we couldn't complete this request.
                    </p>

                    <p className="body-medium fw-bold text-center mt-30">
                      Please try again
                    </p>

                    <Button
                      text="Try again"
                      className="btn-prime btn-primary mt-50"
                      width="145px"
                      height="36px"
                      imageSrc={refresh}
                      imageClassName="me-2 img-18"
                      onClick={handleBuyNow}
                    />
                  </div>
                )}

                {buyStatus === "success" && (
                  <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
                    <div className="wallet h-center m-auto">
                      <img src={tick} alt="tick" className="img-100 invert1" />
                    </div>

                    <p className="text-center body-medium mt-3 pt-1">
                      Congratulations! You've successfully purchased this item.
                    </p>

                    <p className="body-medium fw-bold text-center mt-30">
                      Purchase successful!
                    </p>

                    <Button
                      text="Done"
                      className="btn-prime btn-primary mt-50"
                      width="145px"
                      height="36px"
                      onClick={handleBuyArtModal}
                    />
                  </div>
                )}
              </>
            ) : (
              <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
                <p className="text-center fw-bold  mt-3 pt-1">
                  Only these members can purchase during this stage:
                </p>
                <p
                  className={`body-medium badge mt-4 member-ship 
                
                ${
                  activeMembership == "Standard"
                    ? "standard"
                    : activeMembership == "Gold"
                    ? "gold"
                    : activeMembership == "Silver"
                    ? "silver"
                    : activeMembership == "Bronze"
                    ? "bronze"
                    : ""
                }`}
                >
                  {activeMembership} Member
                </p>

                <h5 className="larger fw-normal text-black no-text-transform mt-4 pt-1 d-flex align-items-center gap-2">
                  Ends in
                  <img src={dot} alt="" />
                  <span className="fw-semibold">{timeRemaining}</span>
                </h5>

                <p className="text-center body-medium mt-5 pt-1">
                  Learn more about early access and memberships
                </p>

                <Button
                  onClick={() => {
                    window.open(
                      "https://discord.com/invite/vbm6kjSJac",
                      "_blank"
                    );
                  }}
                  text="Learn More"
                  className="btn-prime btn-primary mt-50"
                  width="145px"
                  height="36px"
                />
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>

      {/* Make an offer */}

      <Modal
        show={makeOfferModal}
        onHide={handleOfferModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              MAKE OFFER
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {offerStatus === "" && (
            <div className="buy-now-modal w-431 m-auto ">
              <div className="listed-item  v-center">
                <div className="left">
                  {nftDetails?.image ? (
                    <img src={nftDetails?.image} alt="img" />
                  ) : (
                    <video className="img-100" loop playsInline autoPlay muted>
                      <source src={nftDetails?.videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    {nftDetails?.artName}
                  </label>

                  {/* <p className="body-medium">{`${nftDetails?.selectedCollection}`}</p> */}
                  <p className="body-medium">{`${nftDetails?.selectedCollection}`}</p>
                </div>
              </div>
              <label className="mt-4 pt-1  text-black">Price</label>

              <div className="pricing border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between bg-transparent">
                <Input
                  className="body-medium fw-bold text-black   br-30  w-100 bg-transparent ps-1"
                  placeholder="Price"
                  type="number"
                  value={offerPrice}
                  onChange={handlePriceChange}
                />

                <span>
                  {" "}
                  {state[0]?.metadata?.data?.selectedBlockchain ===
                  config.networks.songbird
                    ? "WSGB"
                    : "WFLR"}
                </span>
              </div>
              {offerError && (
                <p className="text-error ml-2 small-text">
                  Can not contain decimal points
                </p>
              )}

              <div className="avail-blnc mt-2 pt-1">
                <p className="body-medium v-center justify-content-between">
                  {offerPrice > 0 ? (
                    state[0]?.metadata?.data.selectedBlockchain ===
                    config.networks.songbird ? (
                      <span>~ ${offerPrice * (0.011).toFixed(2)}</span>
                    ) : (
                      <span>~ ${offerPrice * (0.032).toFixed(2)}</span>
                    )
                  ) : (
                    <span></span>
                  )}

                  <span>
                    Available balance:{" "}
                    <span className="fw-bold">
                      {state[0]?.metadata?.data.selectedBlockchain ===
                      config.networks.songbird
                        ? (wsgb / 1e18).toFixed(2)
                        : (wflr / 1e18).toFixed(2)}
                    </span>
                  </span>
                </p>
              </div>
              <p className="body-small text-grey text-center mt-3">
                Please note, there are 5% royalties associated with all artwork.
              </p>
              {isNewtworkConnected ? (
                <Button
                  text="Make Offer"
                  className={`btn-prime ${
                    disableOfferBtn ? "btn-ternary" : "btn-primary"
                  }  mt-5`}
                  width="100%"
                  height="36px"
                  onClick={handleMakeAnOffer}
                  disabled={disableOfferBtn && offerError}
                />
              ) : (
                <Button
                  text="Switch Network"
                  className="btn-prime btn-primary mt-50"
                  width="100%"
                  height="36px"
                  onClick={handleSwitchChain}
                />
              )}
            </div>
          )}

          {/* Waiting for blockchain wallet confirmation… */}
          {offerStatus === "confirming" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Confirm this transaction in your wallet.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>

              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {/* if error*/}
          {offerStatus === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Sorry, we couldn't complete this request.
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Please try again
              </p>

              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                onClick={handleMakeAnOffer}
                imageClassName="me-2 img-18"
              />
            </div>
          )}

          {/* on success */}
          {offerStatus === "success" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center ">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>

              <p className=" text-center body-medium  mt-3 pt-1 ">
                Your offer has been sent. Keep an eye on your notifications!
              </p>

              <p className="body-medium fw-bold text-center mt-30">
                Offer sent
              </p>

              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={handleOfferModal}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/*  EDIT LISTING*/}

      <Modal
        show={editListModal}
        onHide={handleEditListModal}
        className="sign-modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              EDIT LISTING
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          {listingModal === "initial" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="listed-item">
                <div className="v-center">
                  <div className="left">
                    {nftData[0]?.metadata.data?.image ? (
                      <img
                        src={nftData[0]?.metadata.data?.image}
                        alt="art"
                        className="img-100px "
                      />
                    ) : (
                      <video
                        className="img-100"
                        loop
                        playsInline
                        autoPlay
                        muted
                      >
                        <source
                          src={nftData[0]?.metadata.data?.videoUrl}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {/* <img src={art} alt="img" className="img-100px" /> */}
                  </div>
                  <div className="right ms-3">
                    <label className="larger fw-bold text-black no-text-transform">
                      {nftDetails?.artName}
                    </label>
                    <p className="body-medium">{collectionDetails?.name}</p>

                    <h6 className="fw-bold fst-normal mt-2">
                      {convertWeiToEther(state[0]?.listedData?.price)}{" "}
                      {state[0]?.metadata?.data.selectedBlockchain ===
                      config.networks.songbird
                        ? "SGB"
                        : "FLR"}
                    </h6>
                  </div>
                </div>
              </div>

              <p className="mt-30 text-center">
                Would you like to change the price of the listing or cancel the
                listing and remove it from sale?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Change price"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("changePrice")}
                />
                <Button
                  text="Cancel listing"
                  className="btn-prime btn-secondary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("cancelListing")}
                />
              </div>
            </div>
          )}

          {listingModal === "changePrice" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {nftData[0]?.metadata.data?.image ? (
                  <img
                    src={nftData[0]?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
                {/* <img src={art} alt="img" className="img-65 border-solid-black pad-2" /> */}
              </div>
              <p className="mt-40 text-center">
                Set new price for <strong>“{nftDetails?.artName}”</strong>
              </p>
              <div className="pricing bg-transparent border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between">
                <Input
                  className="body-medium ps-2 text-black bg-transparent br-30 w-100"
                  placeholder="Price"
                  type="number"
                  value={newPrice}
                  onChange={(e) => setNewPrice(e.target.value)}
                />
                <span>
                  {state[0]?.metadata?.data.selectedBlockchain ===
                  config.networks.songbird
                    ? "SGB"
                    : "FLR"}
                </span>
              </div>
              <p className="mt-2 body-medium text-medium-grey">
                ~ $
                {(state[0]?.metadata?.data.selectedBlockchain ===
                config.networks.songbird
                  ? newPrice * sgb
                  : newPrice * flr
                ).toFixed(2)}
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Continue"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={handlePrice}
                />
              </div>
            </div>
          )}

          {listingModal === "cancelListing" && (
            <div className="buy-now-modal w-431 m-auto">
              <div className="h-center">
                {nftData[0]?.metadata.data?.image ? (
                  <img
                    src={nftData[0]?.metadata.data?.image}
                    alt="art"
                    className="img-65 border-solid-black pad-2"
                  />
                ) : (
                  <video className="img-100" loop playsInline autoPlay muted>
                    <source
                      src={nftData[0]?.metadata.data?.videoUrl}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
              <p className="mt-40 text-center">
                Cancel listing:
                <strong> {nftDetails?.artName}</strong>
              </p>
              <p className="mt-30 text-center">
                Are you sure you want to cancel this listing?
              </p>
              <div className="v-center h-center gap-3 mt-5 w-100">
                <Button
                  text="Go back"
                  className="btn-prime btn-primary"
                  width="156px"
                  height="36px"
                  onClick={() => setListingModal("initial")}
                />
                <Button
                  text="Yes, cancel listing"
                  className="btn-prime btn-secondary text-error bg-error-20 border-0"
                  width="156px"
                  height="36px"
                  onClick={handleCancelListing}
                />
              </div>
            </div>
          )}

          {listingModal === "confirm" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={wallet} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Confirm this transaction in your wallet.
              </p>
              <p className="body-medium fw-bold text-center mt-30">
                Waiting for blockchain confirmation…
              </p>
              <Button
                text=""
                className="btn-prime btn-primary mt-50"
                width="138px"
                height="36px"
                imageSrc={loader}
                imageClassName="rotate-360"
              />
            </div>
          )}

          {listingModal === "successPrice" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully updated price for
                <span className="fw-bold"> "{nftDetails?.artName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={() => {
                  handleEditListModal();
                  window.location.reload();
                }}
              />
            </div>
          )}

          {listingModal === "successListing" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={tick} alt="tick" className="img-100 invert1" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                Successfully cancelled listing for
                <span className="fw-bold"> "{nftDetails?.artName}" </span>!
              </p>
              <Button
                text="Done"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                onClick={() => {
                  handleEditListModal();
                  window.location.reload();
                }}
              />
            </div>
          )}

          {listingModal === "error" && (
            <div className="buy-now-modal w-431 m-auto h-center flex-column v-center">
              <div className="wallet h-center m-auto">
                <img src={error} alt="wallet" className="img-100" />
              </div>
              <p className="text-center body-medium mt-3 pt-1">
                {listingModal === "changePrice"
                  ? `Sorry, we couldn't change the price for "${nftDetails?.artName}".`
                  : `Sorry, we couldn't cancel your listing for "${nftDetails?.artName}" at this time.`}
              </p>
              <Button
                text="Try again"
                className="btn-prime btn-primary mt-50"
                width="145px"
                height="36px"
                imageSrc={refresh}
                imageClassName="me-2 img-18"
                onClick={() => {
                  setListingModal("initial");
                }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Make a  bid*/}

      <Modal
        show={bidModal}
        onHide={handleBidModal}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              Place a offer
            </label>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="p-0">
          <div className="buy-now-modal w-431 m-auto ">
            <div className="listed-item auction-live ">
              <div className="v-center">
                <div className="left">
                  <img src={art} alt="img" className="border-0" />
                </div>
                <div className="right ms-3">
                  <label className="larger fw-bold text-black no-text-transform">
                    Arwork Name
                  </label>
                  <p className="body-medium">Untitled Collection #7156351271</p>
                </div>
              </div>
              <div className="mt-3 d-flex justify-content-between">
                <p className="h-center flex-column">
                  <span className="text-light-grey  fw-500 body-medium">
                    Reserve not met
                  </span>
                  <span className="fw-bold body-medium mt-2">-</span>
                </p>
                <p className="d-flex flex-column align-items-end">
                  <span className="text-light-grey  fw-500 body-medium">
                    Reserve price
                  </span>
                  <span className="fw-semibold mt-2">
                    <img src={FLR} alt="FLR" />
                    330,000 FLR
                  </span>
                </p>
              </div>
            </div>
            <label className="mt-4 pt-1  text-black">Price</label>
            <div className="pricing border-solid-lightGrey body-medium fw-bold mt-2 br-30 v-center w-100 justify-content-between bg-transparent">
              <Input
                className="body-medium fw-bold text-black   br-30  w-100 bg-transparent ps-1"
                placeholder="Price"
                type="number"
              />
              <span>WFLR</span>
            </div>
            <div className="avail-blnc mt-2 pt-1">
              <p className="body-medium v-center justify-content-between">
                ~ $40.52
                <span>
                  Available balance:
                  <span className="fw-bold"> 3721 WFLR</span>
                </span>
              </p>
            </div>
            {/* available balance needs to be greater than price before ‘buy now’
            button becomes primary. */}
            <Button
              text="Place bid"
              className="btn-prime btn-ternary mt-50"
              width="100%"
              height="36px"
              onClick={(e) => toast("bid placed")}
              disabled="disabled"
            />
          </div>

          {/* Waiting for blockchain wallet confirmation… */}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={wallet} alt="wallet" className="img-100" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Confirm this transaction in your wallet.
            </p>

            <p className="body-medium fw-bold text-center mt-30">
              Waiting for blockchain confirmation…
            </p>

            <Button
              text=""
              className="btn-prime btn-primary mt-50"
              width="138px"
              height="36px"
              imageSrc={loader}
              imageClassName="rotate-360"
            />
          </div>

          {/* if error*/}
          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none">
            <div className="wallet h-center m-auto">
              <img src={error} alt="wallet" className="img-100" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Sorry, we couldn't complete this request.
            </p>

            <p className="body-medium fw-bold text-center mt-30">
              Please try again
            </p>

            <Button
              text="Try again"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              imageSrc={refresh}
              imageClassName="me-2 img-18"
            />
          </div>

          {/* on success */}

          <div className="buy-now-modal w-431 m-auto h-center flex-column v-center d-none ">
            <div className="wallet h-center m-auto">
              <img src={tick} alt="tick" className="img-100 invert1" />
            </div>

            <p className=" text-center body-medium  mt-3 pt-1 ">
              Your offer has been sent. Keep an eye on your notifications!
            </p>

            <p className="body-medium fw-bold text-center mt-30">Offer sent</p>

            <Button
              text="Done"
              className="btn-prime btn-primary mt-50"
              width="145px"
              height="36px"
              onClick={handleOfferModal}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* Make a  bid*/}

      <Modal
        show={isFullScreen}
        onHide={toggleFullScreen}
        className="fullScreen-modal "
        centered
      >
        <Modal.Body className="p-0">
          <div className="full-screen-art">
            {/* <div className="screen-alert">
              <img src={Esc} alt="Esc" className="Esc img-fluid" />
            </div> */}
            <img src={nftDetails?.image} alt="art" className="art" />
          </div>
        </Modal.Body>
      </Modal>

      <Footer />
    </div>
  );
};

export default SingleArt2;
