import React, { useEffect, useState, useContext } from "react";
import leftArrow from "../../assets/icon/chevron-left-small.svg";
import Button from "../button";
import Input from "../inputs";
import "../../style/main.scss";
import unchecked from "../../assets/icon/checkbox.svg";
import checked from "../../assets/icon/checkbox-selected.svg";
import { useNavigate } from "react-router-dom";
import UseWindowResize from "../../customHooks/useWindowResize";
import useScrollToTop from "../../customHooks/scrollToTop";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import Web3 from "web3";
import { useSwitchNetwork, useNetwork } from "wagmi";
import { RefreshContext } from "../notificationContext";
import { SaveSiteSetting, getSiteSetting } from "../../firebase/firebase";
import config from "../../config";
import { toast } from "react-toastify";
const SiteSetting = () => {
  const { marketplace, erc20 } = config;
  const Marketplace_coston_contractAddress =
    marketplace.coston;
  const Marketplace_coston2_contractAddress =
    marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;

  const [spotActive, setSpotActive] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [savedSetting, setSavedSetting] = useState('');
  const [changesMade, setChangesMade] = useState(false);

  const navigate = useNavigate


  useEffect(() => {

    const fetchSetting = async () => {
      try {
        let res = await getSiteSetting();
        setMessage(res?.message);
        setSpotActive(res?.spotActive);

        setSavedSetting(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchSetting();

  }, [])

  useEffect(() => {
    if (savedSetting) {
      if (spotActive !== savedSetting?.spotActive || message !== savedSetting?.message) {
        setChangesMade(true);
      } else {
        setChangesMade(false);
      }
    }
  }, [spotActive, message, savedSetting]);

  const handleSaveChanges = async () => {
    setLoading(true);
    const data = {
      spotActive,
      message,
    };

    try {
      let res = await SaveSiteSetting(data);
      setChangesMade(false);
      setLoading(false);
      toast.success("Changes saved successfully", { toastId: "saveSuccess" });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong while saving changes", {
        toastId: "saveError",
      });
      setLoading(false);
    }
  };




  useScrollToTop();
  return (
    <div>
      <div className="siteSetting">
        <div className="admin-content-head v-center justify-content-between header-fixed ">
          {/* Header for desktop */}
          <h3 className="fw-bold text-capitalize for-desktop">Site Settings</h3>
          {/* Header for mobile */}
          <h6
            className="fw-bold text-capitalize for-mobile"
            onClick={() => navigate(-1)}
          >
            <img src={leftArrow} alt="back" className="me-3" />
            Site Settings
          </h6>

          <Button
            width="199px"
            height="47px"
            className={`br-30 ${changesMade ? "btn-primary" : "btn-ternary"}`}
            text="Save changes"
            onClick={handleSaveChanges}
            disabled={!changesMade}
            loading={loading}
          />


        </div>

        <div className="siteSetting-content mt-36">
          {/* site maintenance */}
          <div className="site-maintenance mt-40">
            <p className="body-large fw-bold ">Site Maintenance</p>
            <p className="body-medium  mt-3">
              Enabling maintenance mode temporarily makes the site inaccessible
              to regular users.
            </p>

            <div className="enable mt-4 mb-5 ms-3">
              <label
                className="text-black no-text-transform v-center pointer"
                onClick={(e) => setSpotActive(!spotActive)}
              >
                <img
                  src={spotActive ? checked : unchecked}
                  alt="checkbox"
                  className="me-3"
                />
                Enable maintenance mode
              </label>
            </div>

            <div className="left w-100">
              <label className="medium text-black">Maintenance message</label>
              <div className="input-box br-30 ps-3 mt-3">
                <Input
                  placeholder="Add maintenance message..."
                  value={message}
                  onChange={(e) => {
                    setChangesMade(true);
                    setMessage(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SiteSetting;


