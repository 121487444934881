import { ContractFactory, ethers } from "ethers";
import { toast } from "react-toastify";
import Web3 from "web3";
import config from "../config";
// import mintContractABI from "../abis/erc721.json";
// import mintContractByteCode from "../abis/mint_contract_bytecode.json";
// import mintContractABI from "../abis/NewERC721ABI.json";
// import mintContractByteCode from "../abis/Newbytecode.json";
import mintContractABI from "../abis/SafeMint/v2/abi.json";
import mintContractByteCode from "../abis/SafeMint/v2/bytecode.json";
import { handleAdminNotifications } from "../firebase/firebase";
const { marketplace, erc20 } = config;

const Marketplace_coston_contractAddress =
  marketplace.coston;
const Marketplace_coston2_contractAddress =
  marketplace.coston2;



export default async function CreateContract(signer, collectionName, symbolName, selectedNetwork) {
  let marketPlaceAddress;

  if (selectedNetwork === config.networks.flare) {
    marketPlaceAddress = Marketplace_coston2_contractAddress;
  } else {
    marketPlaceAddress = Marketplace_coston_contractAddress;
  }

  return new Promise(async (resolve, reject) => {
    try {
      const userAddress = signer.account.address;
      let web3;

      if (selectedNetwork === config.networks.songbird) {
        web3 = new Web3(config.rpc.songbird);
      } else if (selectedNetwork === config.networks.flare) {
        web3 = new Web3(config.rpc.flr);
      }
      console.log(web3, "web3 instance initialized");

      const contract = new web3.eth.Contract(mintContractABI);
      contract.options.data = mintContractByteCode[0].bytecode;

      const constructorArgs = [userAddress, collectionName, symbolName, selectedNetwork, marketPlaceAddress];

      const deployTx = contract.deploy({
        arguments: constructorArgs,
      });

      console.log(deployTx, "Deploy transaction created");

      const gasLimit = await deployTx.estimateGas();

      let transaction = {
        from: userAddress,
        data: deployTx.encodeABI(),
        gas: gasLimit + 25000,
      };

      // Sending transaction
      signer
        .sendTransaction(transaction)
        .then(async (hash) => {
          console.log(hash, "Transaction hash received");

          // Wait for the transaction receipt
          const checkReceipt = async () => {
            for (let i = 0; i < 30; i++) { // Wait for 30 iterations (approx. 60 seconds)
              const receipt = await web3.eth.getTransactionReceipt(hash);
              if (receipt) {
                console.log(receipt, "Transaction receipt received");
                if (receipt.status) {
                  resolve({
                    success: true,
                    contractAddress: receipt.contractAddress,
                    hash: hash,
                  });
                  return; // Exit function after resolving
                } else {
                  reject({ success: false, error: "Transaction failed" });
                  return;
                }
              }
              await new Promise((r) => setTimeout(r, 2000)); // Wait 2 seconds before checking again
            }
            reject({ success: false, error: "Transaction timed out" });
          };

          checkReceipt();
        })
        .catch((error) => {
          console.error("Transaction failed:", error);
          reject({ success: false, error: error.message || "Transaction error" });
        });

    } catch (err) {
      console.error("Error deploying contract:", err);
      reject({ success: false, error: err.message || "Deployment failed" });
    }
  });
}

