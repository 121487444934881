import React, { useEffect, useState } from "react";
import "../style/main.scss";
import { Icon } from "@iconify/react";
import spike65 from "../assets/icon/spiked-circle/black/65px.svg";
import spike35 from "../assets/icon/spiked-circle/black/35px.svg";
import discord from "../assets/icon/discord.svg";
import twitter from "../assets/icon/twitter.svg";
import bannerlarge from "../assets/images/artwork-example-large-1.png";
import heroStar from "../assets/images/hero-star.png";
import spotlight from "../assets/images/spotlight-header.svg";
import spotlight_ from "../assets/images/spotlight-header-mob.svg";
import arrowdown from "../assets/images/arrowdown.svg";
import Cookies from "./Cookies";
import { getFeaturedUser, getSpotlightUser, getUserData, checkIfBlacklistedByUserName } from "../firebase/firebase";
import { Link, useNavigate } from "react-router-dom";

import Carousel from "react-bootstrap/Carousel";
import { Skeleton } from "antd";

const Hero = () => {
  const [loading, setLoading] = useState(true);
  const [opacity, setOpacity] = useState(true);
  const [opacity2, setOpacity2] = useState(true);
  const [featuredUser, setFeaturedUser] = useState([]);
  const [spotlightUser, setSpotlightUser] = useState([]);
  const [featuredImages, setFeaturedImages] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [isBlackList, setIsBlacklist] = useState(true);
  const [fetching, setFetching] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 100) {
        setOpacity(false);
      } else {
        setOpacity(true);
      }

      if (window.scrollY > 600) {
        setOpacity2(false);
      } else {
        setOpacity2(true);
      }
    });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const user = await getFeaturedUser();

      const spotlightUser = await getSpotlightUser();
      setFeaturedUser(user);
      setSpotlightUser(spotlightUser[0]);
      if (spotlightUser[0] === undefined) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    let featuredImages = [];
    if (spotlightUser?.spotFeaturedImage1) {
      featuredImages.push({
        image: spotlightUser?.spotFeaturedImage1,
        Name: spotlightUser?.spotFeaturedName1,
      });
      setLoading(false);
    }
    if (spotlightUser?.spotFeaturedImage2) {
      featuredImages.push({
        image: spotlightUser?.spotFeaturedImage2,
        Name: spotlightUser?.spotFeaturedName2,
      });
      setLoading(false);
    }
    if (spotlightUser?.spotFeaturedImage3) {
      featuredImages.push({
        image: spotlightUser?.spotFeaturedImage3,
        Name: spotlightUser?.spotFeaturedName3,
      });
      setLoading(false);
    }
    if (featuredImages.length === 0) {
      setLoading(false);
    }
    setFeaturedImages(featuredImages);
  }, [spotlightUser]);

  const imageStyle = {
    opacity: loading ? 0 : 1,
    transition: "opacity 0.3s ease-in-out",
  };


  useEffect(() => {

    const getUser = async () => {
      let res = await getUserData(spotlightUser?.spotAddress);
      setIsBlacklist(res?.isBlackListed);
      setUser(res);

      setTimeout(() => {
        setFetching(false);
      }, 3000);
    }
    getUser();

  }, [spotlightUser])




  const checkIfWhiteListedFeaturedArtist = async () => {
    let res = await checkIfBlacklistedByUserName(featuredUser?.id);
    if (res == true) {
      setFeaturedUser([]);
    }

  }
  useEffect(() => {

    checkIfWhiteListedFeaturedArtist();
  }, [featuredUser])


 



  return (
    <>
      {!fetching && spotlightUser && spotlightUser?.spotActive && !isBlackList ? (
        <section className="hero">
          <div className="hero-content">
            <div className="top-content">
              <div className="left">
                <div style={{ position: "relative" }}>
                  {loading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "#f0f0f0",
                        borderRadius: "8px",
                      }}
                    />
                  )}

                  <img
                    src={spotlight}
                    alt="spotlight"
                    className="img-fluid desktop-img"
                    style={imageStyle}
                  />
                </div>
                <img
                  src={spotlight_}
                  alt="spotlight"
                  className="img-fluid d-none mobile-img"
                />

                {loading ? (
                  <Skeleton.Input block={false} />
                ) : (
                  <h6
                    onClick={() => {
                      if (spotlightUser?.spotUsername) {
                        navigate(`/profile/${spotlightUser.spotAddress}`, {
                          state: { loc: "hero" },
                        });
                      }
                    }}
                    className="pointer"
                  >
                    {spotlightUser?.spotUsername
                      ? "@" + spotlightUser.spotUsername
                      : "@ArtistName"}
                  </h6>
                )}
              </div>

              <div className="right">
                <p className="body-large">Follow</p>
                {spotlightUser?.spotTwitter && (
                  <Link to={spotlightUser?.spotTwitter} target="_blank">
                    <img src={twitter} alt="twitter" />
                  </Link>
                )}

                {spotlightUser?.spotDiscord && (
                  <Link to={spotlightUser?.spotDiscord} target="_blank">
                    <img src={discord} alt="discord" />
                  </Link>
                )}
              </div>
            </div>

            <div className="bottom-content">
              <div
                className="left-content overflow-hidden"
                style={{ maxWidth: "600px" }}
              >
                {loading ? (
                  <div className="d-flex flex-column">
                    <Skeleton.Avatar
                      active
                      size={603}
                      shape="square"
                      width={100}
                      className="hide-on-mobile"
                    />{" "}
                    <Skeleton.Avatar
                      active
                      size={340}
                      shape="square"
                      width={100}
                      className="hide-on-desktop"
                    />
                  </div>
                ) : (
                  <div className="pointer">
                    <Carousel indicators={false} controls={false} touch={true}>
                      {featuredImages.map((item, index) => (
                        <Carousel.Item key={index}>
                          <div>
                            <div
                              className="hero-thumbnail"
                              onClick={() =>
                                window.open(
                                  spotlightUser[`spotFeaturedLink${index + 1}`],
                                  "_blank"
                                )
                              }
                            >
                              <img
                                src={item?.image ? item?.image : bannerlarge}
                                alt="banner image"
                              // className="img-fluid img-100"
                              />
                            </div>
                            {item?.Name && (
                              <p
                                className="hover"
                                onClick={() =>
                                  window.open(
                                    spotlightUser[
                                    `spotFeaturedLink${index + 1}`
                                    ],
                                    "_blank"
                                  )
                                }
                              >
                                <img
                                  src={spike35}
                                  alt="star"
                                  className="img-35"
                                />

                                {item?.Name}
                              </p>
                            )}
                          </div>
                        </Carousel.Item>
                      ))}
                    </Carousel>
                  </div>
                )}
              </div>

              {featuredUser?.id && (
                <div
                  className="right-content trans-7"
                  style={{ opacity: opacity ? "1" : "0" }}
                >
                  <p className="body-large ">{featuredUser?.id}</p>
                  <div className="divider "></div>
                  <div className="">
                    {featuredUser?.image ? (
                      <img
                        src={featuredUser?.image}
                        alt="star"
                        className="br-50"
                        style={{
                          borderRadius: "50%",
                          width: "100px",
                          height: "100px",
                        }}
                      />
                    ) : (
                      <img src={spike35} alt="star" className="img-35" />
                    )}
                  </div>
                </div>
              )}
              {/* <img
                className="arrowdown hide-on-desktop hide-on-mobile"
                src={arrowdown}
                alt="star"
              /> */}
            </div>
          </div>
          <Cookies />
        </section>
      ) : (
        <>
          {!fetching && loading && (
            <section className="hero">
              <div className="hero-content">
                <div className="top-content">
                  <div className="left">
                    <div style={{ position: "relative" }}>
                      {loading && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            background: "#f0f0f0",
                            borderRadius: "8px",
                          }}
                        />
                      )}

                      <img
                        src={spotlight}
                        alt="spotlight"
                        className="img-fluid desktop-img"
                        style={imageStyle}
                      />
                    </div>
                    <img
                      src={spotlight_}
                      alt="spotlight"
                      className="img-fluid d-none mobile-img"
                    />

                    {loading ? (
                      <Skeleton.Input block={false} />
                    ) : (
                      <h6
                        onClick={() => {
                          if (spotlightUser?.spotUsername) {
                            navigate(`/profile/${spotlightUser.spotAddress}`);
                          }
                        }}
                        className="pointer"
                      >
                        {spotlightUser?.spotUsername
                          ? "@" + spotlightUser.spotUsername
                          : "@ArtistName"}
                      </h6>
                    )}
                  </div>

                  <div className="right">
                    <p className="body-large">Follow</p>
                    <Link
                      to={
                        spotlightUser?.spotTwitter ||
                        "https://twitter.com/thefatcatsNFT"
                      }
                      target="_blank"
                    >
                      <img src={twitter} alt="twitter" />
                    </Link>

                    <Link
                      to={
                        spotlightUser?.spotDiscord ||
                        "https://discord.gg/zAQ9RAh7db"
                      }
                      target="_blank"
                    >
                      <img src={discord} alt="discord" />
                    </Link>
                  </div>
                </div>

                <div className="bottom-content">
                  <div
                    className="left-content overflow-hidden"
                    style={{ maxWidth: "600px" }}
                  >
                    {loading ? (
                      <div className="d-flex flex-column">
                        <Skeleton.Avatar
                          active
                          size={603}
                          shape="square"
                          width={100}
                          className="hide-on-mobile"
                        />{" "}
                        <Skeleton.Avatar
                          active
                          size={340}
                          shape="square"
                          width={100}
                          className="hide-on-desktop"
                        />
                      </div>
                    ) : (
                      <div className="pointer">
                        <Carousel
                          indicators={false}
                          controls={false}
                          touch={true}
                        >
                          {featuredImages.map((item, index) => (
                            <Carousel.Item key={index}>
                              <div onClick={() => navigate(`/spotlight`)}>
                                <div className="hero-thumbnail">
                                  <img
                                    src={
                                      item?.image ? item?.image : bannerlarge
                                    }
                                    alt="banner image"
                                  // className="img-fluid img-100"
                                  />
                                </div>
                                {item?.Name && (
                                  <p className="hover">
                                    <img
                                      src={spike35}
                                      alt="star"
                                      className="img-35"
                                    />
                                    {item?.Name}
                                  </p>
                                )}
                              </div>
                            </Carousel.Item>
                          ))}
                        </Carousel>
                      </div>
                    )}
                  </div>

                  {featuredUser?.image && (
                    <div
                      className="right-content trans-7"
                      style={{ opacity: opacity ? "1" : "0" }}
                    >
                      <p className="body-large ">{featuredUser?.id}</p>
                      <div className="divider "></div>
                      <div className="">
                        <img
                          src={featuredUser?.image}
                          alt="star"
                          className="br-50"
                          style={{
                            borderRadius: "50%",
                            width: "100px",
                            height: "100px",
                          }}
                        />
                      </div>
                    </div>
                  )}
                  <img
                    className="arrowdown hide-on-desktop hide-on-mobile"
                    src={arrowdown}
                    alt="star"
                  />
                </div>
              </div>
              <Cookies />
            </section>
          )}
        </>
      )}
    </>
  );
};

export default Hero;
