import React, { useState, useEffect, useContext } from "react";
import Header from "./header";
// import thumbnail from "../assets/icon/artwork.svg";
import FLR from "../assets/icon/FLR.svg";
import sgb from "../assets/icon/SGB.svg";

import Input from "./inputs";
import Button from "./button";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import ImageUpload from "./imageUpload";

import {
  handleNotifications,
  saveCollectionStats,
  getCollectionStats,
  saveArtworkHistory,
  getArtIdByUrl,
  getUserData,
} from "../firebase/firebase";
import { Modal } from "react-bootstrap";
import art from "../assets/images/artwork-example-4.png";
import { useLocation } from "react-router-dom";
import Web3 from "web3";
import marketplaceContractABI from "../abis/Marketplace/v3/abi.json";
import { handleCollectionHistory } from "../firebase/firebase";
import { useSwitchNetwork, useNetwork } from "wagmi";
import mintContractABI from "../abis/SafeMint/v2/abi.json";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { NFTContext } from "./context/appContext";
import useScrollToTop from "../customHooks/scrollToTop";
import config from "../config";
import { Tooltip } from "antd";
import { useWalletClient } from "wagmi";
import { RpcError } from "viem";
const ListforSale = () => {
  useScrollToTop();
  const navigate = useNavigate();
  const { marketplace, erc20 } = config;

  const { data: signer } = useWalletClient();

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;
  const [formattedValue, setFormattedValue] = useState("");

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  const location = useLocation();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();
  const { chain } = useNetwork();

  const [web3, setWeb3] = useState(null);
  // const web3 = new Web3(window.ethereum);
  const { address } = useAccount();

  const [isNewtworkConnected, setIsNewtworkConnected] = useState(false);

  const { showModal, updateModal } = useContext(NFTContext);

  useEffect(() => {
    if (chain?.name === config.networks.songbird) {
      setWeb3(new Web3(config.rpc.songbird));
    } else {
      setWeb3(new Web3(config.rpc.flr));
    }

    if (chain?.name !== location?.state?.data?.selectedBlockchain) {
      setIsNewtworkConnected(false);
    } else {
      setIsNewtworkConnected(true);
    }
  }, [chain, location?.state?.data?.selectedBlockchain]);

  const [selectSale, setSelectSale] = useState("Fixed price");
  const [createFinish, setCreateFinish] = useState(false);
  const [createFinishSuccess, setCreateFinishSuccess] = useState(false);
  const [nftDetail, setNftDetail] = useState({});
  const [nftUriData, setNftUriData] = useState({});
  const [price, setPrice] = useState();
  const [artistFee, setArtistFee] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [collectionDetail, setCollectionDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [uri, setUri] = useState(null);
  const [artId, setArtId] = useState(null);
  const [username, setUsername] = useState([]);
  const [saleType, setSaleType] = useState("");
  const [potentialEarning, setPotentialEarning] = useState(null);

  useEffect(() => {
    const getData = async () => {
      let res = await getUserData(address);
      setUsername(res?.userName);
    };

    getData();
  }, [address]);

  useEffect(() => {
    const fetchArtIdByUrl = async () => {
      let res = await getArtIdByUrl(uri);
      setArtId(res);
    };

    if (uri) {
      fetchArtIdByUrl();
    }
  }, [uri]);

  useEffect(() => {
    if (location.state) {

      setUri(location.state?.nftDetails?.uri);
      setNftDetail(location?.state?.nftDetails);
      setNftUriData(location?.state?.data);
      setArtistFee(location?.state?.data?.artistFee);
    }
  }, [location]);

  useEffect(() => {
    if (nftDetail?.listedData?.firstClaim == false) {
      setSaleType("primary");
    } else {
      setSaleType("secondary");
    }
  }, [nftDetail]);

  const handleCreate = () => {
    if (!price) {
      setInputError(true);
      return;
    }

    setCreateFinish(true);
  };

  const handleSwitchChain = async () => {
    const switchTo = chains.find(
      (c) => c.name === location?.state?.data?.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };

  const handleFinish = async () => {
    try {
      setLoading(true);

      let MarketplaceAddress;
      if (
        location?.state?.data?.selectedBlockchain === config.networks.songbird
      ) {
        MarketplaceAddress = Marketplace_coston_contractAddress;
      } else if (
        location?.state?.data?.selectedBlockchain === config.networks.flare
      ) {
        MarketplaceAddress = Marketplace_coston2_contractAddress;
      }

      const contract = new web3.eth.Contract(contractABI, MarketplaceAddress);


      const mintContract = new web3.eth.Contract(
        mintContractABI,
        nftDetail?.listedData?.mintContract
      );

      const mintOwner = await mintContract.methods.owner().call();



      const priceInWei = web3.utils.toWei(price.toString(), "ether");

      setCreateFinish(false);


      const data = contract.methods.ListNft(
        nftDetail?.listedData?.mintContract,
        priceInWei,
        nftDetail?.listedData?.tokenId,
        mintOwner,
        nftDetail?.listedData?.artistFeePerAge
      )

      let transaction = {
        from: address,
        to: MarketplaceAddress,
        data: data.encodeABI(),
        // gasLimit: 3000000,

      }
      
      return new Promise((resolve, reject) => {
        signer
          .sendTransaction(transaction)
          .then(async (hash) => {
            for (let index = 0; index > -1; index++) {
              try {
                const receipt = await web3.eth.getTransactionReceipt(hash);
                if (receipt != null) {
                  if (receipt.status) {
                    resolve({ success: true, hash });

                    let transactionHash = hash;

                    const data = {
                      action: "listedSale",
                      user: username,
                      artworkUri: nftUriData,
                      from: address,
                      to: MarketplaceAddress,
                      price: price,
                      tokenId: nftDetail?.listedData?.tokenId,
                      transactionHash: transactionHash,
                    };
                    toast.success("Item listed for sale successfully!");

                    setLoading(false);
                    handleCollectionHistory(nftUriData?.selectedCollectionId, data);
                    handlecollectionStats(nftUriData?.selectedCollectionId, price);

                    let artLink = `/artwork/${location?.state?.data?.selectedBlockchain.split(" ")[0]
                      }/${nftDetail?.listedData?.mintContract}/${nftDetail?.listedData?.tokenId
                      }`;

                    handleNotifications(
                      address,
                      "listed",
                      price,
                      location?.state.data.artName,
                      transactionHash,
                      location?.state?.data?.selectedBlockchain,
                      artLink
                    );

                    let action = "Listed for sale";
                    // setCreateFinishSuccess(true);
                    // let artId = location?.state.data.artId;

                    // (transactionHash, address, action, username, artworkId, price, to
                    let res = await saveArtworkHistory(
                      transactionHash,
                      address,
                      action,
                      username,
                      artId,
                      price,
                      null
                    );
                    updateModal();
                    navigate(
                      `/artwork/${location?.state?.data?.selectedBlockchain.split(" ")[0]
                      }/${nftDetail?.listedData?.mintContract}/${nftDetail?.listedData?.tokenId
                      }`
                    );

                    // toast.error("Tranaction Failed!");
                    setLoading(false);

                    break;
                  } else {
                    reject({ success: false });
                    setLoading(false);
                  }

                  break;
                }
              } catch (error) {
                
                setLoading(false);
              }
            }
          })

          .catch((error) => {
            reject({ success: false, error });
            setLoading(false);
          });
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handlecollectionStats = async (collectionId, price) => {
    const collectionStats = await getCollectionStats(collectionId);
    if (collectionStats) {
      let floorprice = collectionStats?.floorPrice || []; // Default to empty array if floorPrice is undefined

      // Check if collectionStats.floorPrice is an array and has elements
      if (floorprice.length > 0) {
        // Check if the new price is lower than the current lowest floor price
        if (price < floorprice[floorprice.length - 1].split("-")[0]) {
          // Create a new floor price entry with current time
          floorprice = [...floorprice, `${price}-${new Date().getTime()}`];
        }
      } else {
        // If floorprice is empty, initialize it with the new price
        floorprice = [`${price}-${new Date().getTime()}`];
      }

      // Calculate minFloorPrice
      let minFloorPrice;

      if (floorprice.length === 1) {
        // If there is only one entry in floorprice, assign it to minFloorPrice
        minFloorPrice = Number(floorprice[0].split("-")[0]);
      } else {
        // Otherwise, calculate the minimum value
        minFloorPrice = Math.min(
          ...floorprice.map((item) => Number(item.split("-")[0]))
        );
      }


      const data = {
        collectionId: collectionId,
        artworkCount: collectionStats.artworkCount,
        createdAt: collectionStats.createdAt,
        creatorEarning: collectionStats.creatorEarning,
        volume: collectionStats.volume,
        SGBvolume: collectionStats.SGBvolume,
        FLRvolume: collectionStats.FLRvolume,
        USDvolume: collectionStats.USDvolume,
        floorPrice: floorprice,
        minFloorPrice: minFloorPrice, // Assigning minFloorPrice here
        listedCount: (collectionStats.listedCount || 0) + 1, // Incrementing listedCount
        saleCount: collectionStats.saleCount,
        owners: collectionStats.owners,
      };

      saveCollectionStats(data);
    }
  };

  useEffect(() => { }, [nftDetail, nftUriData, price, artistFee, totalEarning]);

  // let platfromFee = (parseInt(price) * 2) / 100;
  // let artistFee = (parseInt(price) * nftUriData?.artistFee) / 100;
  // let total = parseInt(price) - platfromFee - artistFee;
  const handlePrice = () => {
    const priceValue = parseInt(price); // Parse price once

    if (priceValue > 0) {
      let total = 0;

      if (saleType === "primary") {
        total = priceValue * 0.85; // 85% for primary sales
      } else {
        total = priceValue * 0.95; // 95% for other sales
      }

      setTotalEarning(total); // Set the calculated total earnings
    } else {
      setTotalEarning(0); // If price is 0 or less, set total earning to 0
    }
  };

  const handleModalClose = () => {
    setCreateFinish(false);
  };

  const handleCreateFinishSuccess = () => {
    setCreateFinishSuccess(false);
  };
  useEffect(() => {
    handlePrice();
  }, [price]);

  const formatValue = (num) => {
    if (num >= 1e12) return `${(num / 1e12).toFixed(2)} trillion`;
    if (num >= 1e9) return `${(num / 1e9).toFixed(2)} billion`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(2)} million`;
    return num.toLocaleString(); // Default to comma-separated value for smaller numbers
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value.replace(/,/g, "");
    const numberValue = parseFloat(inputValue);

    if (!isNaN(numberValue)) {
      setPrice(inputValue);
      setFormattedValue(formatValue(numberValue));
    } else {
      setPrice(inputValue);
      setFormattedValue(""); // Clear formatted value for invalid input
    }
  };
  return (
    <>
      <section className="create-artwork">
        <Header head="List item" />

        <div className="list-forSale">
          <div className="create-artwork-content  ">
            <h4 className="medium-head">List for sale</h4>

            <div className="upload-file mt-40">
              {/* <p className="body-large fw-bold">Image</p> */}
              {location?.state?.data.image ? (
                <img
                  src={location?.state.data.image}
                  alt="art"
                  className="img-fluid mt-3"
                />
              ) : (
                <img
                  src={location?.state?.data.previewImg}
                  alt="art"
                  className="img-fluid mt-3"
                />
              )}
            </div>
            <div>
              <p className="body-large fw-bold mt-40 ">
                <i> {location?.state?.data.artName}</i>
              </p>
              <p className=" mt-25 ">
                {location?.state.data.selectedCollection}
              </p>
            </div>



            {/* Traits */}
            <div className="artwork-traits">
              <p className="body-large fw-bold mt-40 d-flex justify-content-between">
                <span>Set a price</span>
              </p>

              <div className="add-traits mt-3 d-flex flex-column">
                <div className="d-flex pt-2 add-traits-content">
                  <div className="type left w-100">
                    <div className="input-box br-20 ">
                      <Input
                        type="Number"
                        placeholder="Amount"
                        className="inputtype1"
                        value={price}
                        maxLength={10}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>

                  <div className="delete  current-btn">
                    <Button
                      width="84px"
                      height="41px"
                      className="br-30 btn-prime  fw-normal border-1  v-center h-center off-border  "
                      imageSrc={
                        location?.state?.data?.selectedBlockchain ===
                          config.networks.songbird
                          ? sgb
                          : FLR
                      }
                      text={
                        location?.state?.data?.selectedBlockchain ===
                          config.networks.songbird
                          ? "SGB"
                          : "FLR"
                      }
                      imageClassName="me-1"
                      onClick={() => { }}
                    />
                  </div>
                </div>
                {inputError && (
                  <p className="warning w-100 mt-1">Price is required *</p>
                )}
              </div>
            </div>

            {/* Listing Summary */}
            <div className="listing-Summary">
              <p className="body-large fw-bold mt-60 d-flex justify-content-between">
                Listing Summary
              </p>

              <div className="summary-box v-center flex-column mt-3">
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Listing type
                    <span>{selectSale}</span>
                  </p>
                </div>
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between ">
                    Listing price
                    <span>
                      {/* {price?.length > 10 ? (
                        <Tooltip title={price}>
                          {price?.substring(0, 10)}
                        </Tooltip>
                      ) : (
                        price
                      )}{" "} */}
                      {formattedValue}
                      {location?.state?.data?.selectedBlockchain ===
                        config.networks.songbird
                        ? " SGB"
                        : " FLR"}
                    </span>
                  </p>
                </div>
                {saleType == "primary" && (
                  <div className="box w-100">
                    <p className="body-medium v-center  justify-content-between ">
                      Royalties <span>5%</span>
                    </p>
                  </div>
                )}
              </div>

              <div className="summary-box v-center flex-column mt-5">
                {saleType == "primary" ? (
                  <div className="box w-100">
                    <p className="body-medium v-center  justify-content-between ">
                      Catalyst fee
                      <span>15%</span>
                    </p>
                  </div>
                ) : (
                  <div className="box w-100">
                    <p className="body-medium v-center  justify-content-between ">
                      Creator Royalties
                      <span>5%</span>
                    </p>
                  </div>
                )}
                <div className="box w-100">
                  <p className="body-medium v-center  justify-content-between fw-bold ">
                    Total potential earnings
                    <span>
                      {totalEarning.toFixed(2)}{" "}
                      {location?.state?.data?.selectedBlockchain ===
                        config.networks.songbird
                        ? "SGB"
                        : "FLR"}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            {/* Create artwork button */}

            <Button
              text="List for sale"
              className={`btn-prime   br-30 font-18 mt-50 ${price < 1 || loading ? "btn-ternary" : "btn-primary"
                }`}
              height="50px"
              width="100%"
              disabled={price < 1 || loading}
              onClick={handleCreate}
            />
          </div>
        </div>
      </section>

      <Modal
        show={createFinish}
        onHide={handleModalClose}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              approve listing
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done approve-listing-modal v-center flex-column">
            <div className="listed-item ">
              <div className="left">
                {nftUriData && nftUriData?.image ? (
                  <img src={nftUriData.image} alt="img" className="border-0" />
                ) : (
                  <img
                    src={nftUriData.previewImg}
                    alt="img"
                    className="border-0"
                  />
                )}
              </div>

              <div className="right">
                <label className="larger fw-bold text-black no-text-transform">
                  {nftUriData?.artName}
                </label>
                <p className="body-medium">
                  {nftUriData?.selectedCollection} Collection
                  {/* {nftUriData?.selectedCollectionId} */}
                </p>
                <h6 className="no-text-transform font-normal fw-bold mt-2 pt-1">
                  {/* {price}{" "}   */}
                  {formattedValue}{" "}
                  {location?.state?.data?.selectedBlockchain ===
                    config.networks.songbird
                    ? " SGB "
                    : " FLR "}
                  {/* <span className="ms-2 body-medium text-medium-grey fw-normal">
                    ($178.6)
                  </span> */}
                </h6>
              </div>
            </div>

            <label className="mt-4 pt-1 no-text-transform text-black">
              Final step…
            </label>
            <p className="body-medium mt-2 pb-4">
              Open your wallet to review and confirm this listing.
            </p>
            {isNewtworkConnected ? (
              <Button
                text="Open your wallet"
                className="btn-prime br-30 font-14 mt-50 btn-primary px-4"
                height="50px"
                width="170px"
                disabled={price <= 0 || loading}
                onClick={handleFinish}
              />
            ) : (
              <Button
                text={`Switch Network to ${location?.state?.data?.selectedBlockchain}`}
                className="btn-prime br-30 font-14 mt-50 btn-primary px-4"
                width="170px"
                height="50px"
                onClick={handleSwitchChain}
              />
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={createFinishSuccess}
        onHide={handleCreateFinishSuccess}
        className="sign-modal "
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label htmlFor="" className="medium">
              finished
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="newArt-done v-center flex-column">
            <h5 className="italic-head mb-3 text-bold text-bold">All Done</h5>

            <p className="body-medium">
              You listed <span className="fw-500">{nftUriData?.artName}</span>{" "}
              from{" "}
              <span className="fw-500">{nftUriData?.selectedCollection}</span>
            </p>

            {nftUriData && nftUriData?.image ? (
              <img src={nftUriData.image} alt="img" />
            ) : (
              <img src={nftUriData.previewImg} alt="img" />
            )}

            <Button
              text="View Listing"
              className="btn-prime btn-primary"
              width="131px"
              height="36px"
              onClick={() => navigate("/explore")}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ListforSale;
