const environment = "production";

const config = {
  marketplace: {
    coston:
      environment === "production"
        ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS
        : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS,
    coston2:
      environment === "production"
        ? process.env.REACT_APP_PROD_MARKETPLACE_CONTRACTADDRESS2
        : process.env.REACT_APP_LOCAL_MARKETPLACE_CONTRACTADDRESS2,
  },
  erc20: {
    coston:
      environment === "production"
        ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS
        : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS,
    coston2:
      environment === "production"
        ? process.env.REACT_APP_PROD_ERC20_TOKEN_CONTRACTADDRESS2
        : process.env.REACT_APP_LOCAL_ERC20_TOKEN_CONTRACTADDRESS2,
  },
  pinata: {
    apiKey:
      environment === "production"
        ? process.env.REACT_APP_PROD_PINATA_API_KEY
        : process.env.REACT_APP_LOCAL_PINATA_API_KEY,
    apiSecret:
      environment === "production"
        ? process.env.REACT_APP_PROD_PINATA_API_SECRET
        : process.env.REACT_APP_LOCAL_PINATA_API_SECRET,
    gateway:
      environment === "production"
        ? process.env.REACT_APP_PINATA_GATEWAY_PRODUCTION
        : process.env.REACT_APP_PINATA_GATEWAY_STAGING,
  },
  emailjs: {
    publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
    serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
  },
  // rpc: {
  //   songbird: environment === 'production'
  //     ? process.env.REACT_APP_PROD_SONGBIRD_RPC_URL
  //     : process.env.REACT_APP_LOCAL_SONGBIRD_RPC_URL,
  //   flr: environment === 'production'
  //     ? process.env.REACT_APP_PROD_FLARE_RPC_URL
  //     : process.env.REACT_APP_LOCAL_FLARE_RPC_URL
  // },
  rpc: {
    songbird: "https://songbird-api.flare.network/ext/C/rpc",
    flr: "https://flare-api.flare.network/ext/C/rpc",
  },
  mainnetCollections: [
    {
      contractAddress: "0x0e759AA7166AB3b2b81AbD6d9eD16ac83368F97e",
      selectedNetwork: "Songbird Mainnet",
      name: "The Fat Cats",
    },
    {
      contractAddress: "0x12c40516c7bf32002FF0e3431082C9e28Ab76066",
      selectedNetwork: "Songbird Mainnet",
      name: "The Fat Leopards",
    },
    {
      contractAddress: "0xFdD87A263ba929E14Dd0A2D879D9C66d5c8fF3ae",
      selectedNetwork: "Songbird Mainnet",
      name: "The Fat Tigers",
    },
    {
      contractAddress: "0x93365AACe3db5407B0976C0a6C5F46B21BAd3923",
      selectedNetwork: "Flare Mainnet",
      name: "The Fat Kittens",
    },
  ],
  networks: {
    // songbird: environment === 'production' ? "Songbird Mainnet" : "Coston",
    // flare: environment === 'production' ? "Flare Mainnet" : "Coston2"
    flare: "Flare Mainnet",
    songbird: "Songbird Mainnet",
  },
};

export default config;
