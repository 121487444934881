import React, { useState, useEffect, useContext } from "react";
import "../../style/main.scss";
import { Modal } from "react-bootstrap";
import user from "../../assets/images/face-4.png";
import Button from "../button";
import {
  unfollowArtist,
  checkIfFollowed,
  handleFollow,
  getImageByUsername,
  getAllUsers,
  getUserDataByUserName,
  getUserData,
} from "../../firebase/firebase";
import placeholder from "../../assets/images/profile-1.svg";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { NFTContext } from "../context/appContext";
import { Skeleton } from "antd";

const FollowModal = ({
  userName,
  id,
  show,
  handleModal1,
  data,
  dataType,
  setShowFollowModal,
}) => {
  // id and address

  // follow and un follow

  // check if followed

  const { address } = useAccount();
  const [isFollowed, setIsFollowed] = useState([]);
  const [fetchig, setFetching] = useState(false);
  const [accountAddress, setAccountAddress] = useState(false);

  const [images, setImages] = useState({});
  const [userAddresses, setUserAddresses] = useState({});
  const [username, setUsername] = useState(" ");
  // let username = localStorage?.getItem("userName");
  const navigate = useNavigate({ forceReload: true });

  const [userData, setUserData] = useState([]);
  const { reloadProfile, reload } = useContext(NFTContext);

  useEffect(() => {
    // if (id) {
    //   setAccountAddress(id)
    // }
    // else {
    setAccountAddress(address);
    // }
  }, [id, address]);

  useEffect(() => {
    const getUserName = async () => {
      let data = await getUserData(accountAddress);
      setUsername(data?.userName);
    };
    getUserName();
  }, [accountAddress]);

  useEffect(() => {
    try {
      const followed = data?.map(() => false);
      setIsFollowed(followed);
    } catch (error) {
      console.error("Error setting followed status:", error);
    }
  }, [data]);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();

      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = (accountAddress) => {
    if (address === accountAddress) {
      return "You";
    }

    const user = userData?.find((user) => user?.id === accountAddress);
    const artistName = user ? user?.userName : accountAddress;

    return "@" + artistName;
  };
  useEffect(() => {
    setFetching(true);
    if (data?.length == 0) {
      setFetching(false);
      return;
    }
    if (dataType === "following") {
      data.forEach(async (item, index) => {
        const isFollowedBack = await checkIfFollowed(accountAddress, item);
        setIsFollowed((prevIsFollowed) => {
          const updatedIsFollowed = [...prevIsFollowed];
          updatedIsFollowed[index] = isFollowedBack;
          return updatedIsFollowed;
        });
        setFetching(false);
      });
    } else if (dataType === "followers") {
      data.forEach(async (item, index) => {
        const isFollowedBack = await checkIfFollowed(accountAddress, item);

        setIsFollowed((prevIsFollowed) => {
          const updatedIsFollowed = [...prevIsFollowed];
          updatedIsFollowed[index] = isFollowedBack;
          return updatedIsFollowed;
        });
        setFetching(false);
      });
    }
  }, [dataType, data, username]);

  useEffect(() => {
    const fetchData = async () => {
      await getImagesAndAddresses();
    };
    fetchData();
  }, [data]);

  const getImagesAndAddresses = async () => {
    setImages([null]);
    try {
      for (let index = 0; index < data.length; index++) {
        const item = data[index];
        const image = await getImageByUsername(item);
        const userData = await getUserDataByUserName(item);

        setImages((prevImages) => ({
          ...prevImages,
          [index]: image,
        }));
        setUserAddresses((prevUserAddresses) => ({
          ...prevUserAddresses,
          [index]: userData?.documentId,
        }));
      }
    } catch (error) {
      console.error("Error fetching images or user data:", error);
      // Handle error if needed
    }
  };

  const [loading, setLoading] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(null);
  const followhandle = async (index, item) => {
    setLoadingIndex(index);

    try {
      setLoading(true);
      if (isFollowed[index]) {
        await unfollowArtist(accountAddress, item);
        setLoading(false);
      } else {
        let res = await handleFollow(accountAddress, item);
        setLoading(false);
      }
      setIsFollowed((prevIsFollowed) => {
        const newIsFollowed = [...prevIsFollowed];
        newIsFollowed[index] = !prevIsFollowed[index];
        return newIsFollowed;
      });
      reload();
    } catch (error) {
      setLoading(false);
    }
  };

  const handleUserClick = (item) => {
    if (item) {
      setShowFollowModal(false); // Close the modal

      const url = `/profile/${item}`;
      window.location.href = url;
      // navigate(`/profile/${item}`, { state: { reload: true } });
    }
  };
  return (
    <div>
      <Modal
        show={show}
        onHide={handleModal1}
        centered
        size="lg"
        className="follower-modal"
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <label className="medium">
              {dataType === "followers"
                ? `Followers (${data?.length})`
                : `Following (${data?.length})`}
            </label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {fetchig ? (
            <>
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "16px",
                  }}
                >
                  <Skeleton.Avatar
                    active
                    size="large"
                    style={{ marginRight: "16px" }}
                  />
                  <Skeleton.Input
                    active
                    size="small"
                    style={{ width: "150px" }}
                  />
                  <div style={{ marginLeft: "auto" }}>
                    <Skeleton.Button active size="small" shape="round" />
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              {data?.map((item, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center justify-content-between mb-2"
                >
                  <div className="follower pointer">
                    <div
                      className="follower-img"
                      onClick={() => handleUserClick(item)}
                    >
                      {images[index] ? (
                        <img
                          src={images[index]}
                          className="img-100 rounded-circle"
                        />
                      ) : (
                        <img
                          src={placeholder}
                          className="img-100 rounded-circle"
                        />
                      )}
                    </div>
                    <label
                      className="text-black text-lowercase pointer"
                      onClick={() => handleUserClick(item)}
                    >
                      {getNamebyAddress(item) == "You" ? (
                        <span className="text-capitalize">You</span>
                      ) : getNamebyAddress(item).length > 14 ? (
                        getNamebyAddress(item).substring(0, 14) + "..."
                      ) : (
                        getNamebyAddress(item)
                      )}
                    </label>
                  </div>

                  {loading && index === loadingIndex ? (
                    <Button
                      width="126px"
                      height="36px"
                      text={"Loading.."}
                      className={
                        isFollowed[index]
                          ? "btn-prime btn-primary"
                          : "btn-prime btn-secondary bg-transparent"
                      }
                      disabled={loading}
                    />
                  ) : (
                    <div
                      className="follow-btn"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {item !== accountAddress && (
                        <Button
                          width="126px"
                          height="36px"
                          text={!isFollowed[index] ? "Follow" : "Following"}
                          className={
                            isFollowed[index]
                              ? "btn-prime btn-primary"
                              : "btn-prime btn-secondary bg-transparent"
                          }
                          onClick={() => followhandle(index, item)}
                        />
                      )}
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FollowModal;
