import { useEffect, useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { query, collection, where, getDocs } from "firebase/firestore"; // Add these imports

import "../../style/main.scss";
import arrowLeft from "../../assets/icon/arrow-left.svg";
import Button from "../button";
import dummyProfile from "../../assets/images/profile-1.svg";
import add from "../../assets/icon/plus-small.svg";
import minus from "../../assets/icon/minus.svg";
import question from "../../assets/icon/question-tooltip.svg";
import error from "../../assets/icon/error.svg";
import errorWhite from "../../assets/icon/error-white.svg";
import close from "../../assets/icon/close-small.svg";
import success from "../../assets/icon/tick.svg";
import successWhite from "../../assets/icon/tick-white.svg";
import email from "../../assets/icon/email.svg";
import emailLarge from "../../assets/icon/email-large.svg";
import editpen from "../../assets/icon/edit-white.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Input from "../inputs";
import Textarea from "../shared/textarea";
import InputBox from "../shared/inputBox";
import { toast } from "react-toastify";
import {
  // auth,
  firestoredb,
  getUserData,
  setUsername,
  unlinkemail,
  uploadImg,
  deleteImg,
  updateVerifiedStatus,
  updateToken,
  updateUserMail,
  updateUsername,
} from "../../firebase/firebase";
import { useAccount, useConnect } from "wagmi";
import { doc, getDoc, getFirestore, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

import { encode } from "base-64";
import { updateEmail } from "firebase/auth";
import { NFTContext } from "../context/appContext";
const EditProfile = ({ show, handleModal, setReload }) => {
  // Form states
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);

  const [isVerified, setIsVerified] = useState(false);
  const [token, setToken] = useState("");

  const [show2, setShow2] = useState(false);
  const [load, setLoad] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [fieldsUpdated, setFieldsUpdated] = useState(true);
  const [formData, setFormData] = useState({
    userName: "",
    userMail: "",
    userHeadline: "",
    userAbout: "",
    userLocation: "",
    userTwiter: "",
    isWhiteListed: false,
    isBlackListed: false,
    verified: false,
  });

  // modal handle
  const [imgErrorMessage, setImgErrorMessage] = useState(false);
  const [show1, setShow] = useState(false);
  const [userData, setUserData] = useState("");
  const handleShow = () => setShow(true);
  const { reloadProfile, reload } = useContext(NFTContext);

  const handleClose = () => {
    setShow(false);
  };

  const tooltip = <Tooltip id="tooltip">Recommended 350x350px</Tooltip>;

  const [incorrectFile, setIncorrectFile] = useState();
  // image upload
  const handleImageChange = (e) => {
    setImgErrorMessage(false);
    const file = e.target.files[0];
    const isImage = file?.type.startsWith("image/");
    if (isImage && file.size < 1000000) {
      setSelectedImage(file);
      setImgErrorMessage(false);
    } else {
      setImgErrorMessage(true);
      if (!isImage) {
        toast.error("Incorrect file format. Please select an image file.");
        setSelectedImage(null);
      } else {
        toast.error("Image size exceeds the maximum allowed size (1MB).");
      }
    }
  };

  const handleRemoveProfilePicture = async () => {
    if (selectedImage) {
      const success = await deleteImg(selectedImage, address);
      if (success) {
        toast.success("Image removed successfully!");
        setSelectedImage(false);
      } else {
        setSelectedImage(false);
        // Handle failure, maybe show an error message
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    errorFlags.userName = false;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const checkValidation = () => {
    const error = {
      userMail: false,
      userName: false,
      userAbout: false,
      userHeadline: false,
      userLocation: false,
      userTwiter: false,
    };
    if (formData.userMail == "") {
      error["userMail"] = true;
    }
    if (formData.userName == "") {
      error["userName"] = true;
    }
    // if userName have Spaces then it will be invalid
    if (
      formData.userName.length > 0 &&
      !/^[a-zA-Z0-9._]+$/.test(formData.userName)
    ) {
      error["userName"] = true;
    }

    if (formData.userAbout == "") {
      error["userAbout"] = true;
    }
    if (formData.userHeadline == "") {
      error["userHeadline"] = true;
    }
    if (formData.userLocation == "") {
      error["userLocation"] = true;
    }
    if (
      formData?.userTwiter &&
      !(
        formData.userTwiter.startsWith("https://twitter.com") ||
        formData.userTwiter.startsWith("https://x.com")
      )
    ) {
      toast.warning("Please enter a correct Twitter link");
      setLoading(false);
      return;
    }

    return error;
  };
  const [errorFlags, setErrorFlags] = useState({
    userMail: false,
    userName: false,
    userAbout: false,
    userHeadline: false,
    userLocation: false,
    userTwitter: false, // Note the correct spelling 'userTwitter'
  });
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  const navigate = useNavigate();

  useEffect(() => {
    const test = localStorage?.getItem("catalystSigner");
    // if (test) {
    // } else {
    //   navigate("/");
    // }
  }, []);

  const getfirebasedata = async () => {
    if (address) {
      const data = await getUserData(address);

      setUserData(data);

      setFormData({
        userName: data?.userName,
        userAbout: data?.userAbout,
        userHeadline: data?.userHeadline,
        userLocation: data?.userLocation,
        userTwiter: data?.userTwiter,
        userMail: data?.userMail,
        isWhiteListed: data?.isWhiteListed,
        isBlackListed: data?.isBlackListed,
        verified: data?.verified,
      });
      localStorage.setItem("CatalystUserData", JSON.stringify(data));

      setSelectedImage(data?.image);
    }
    setLoad(false);
  };

  useEffect(() => {
    getfirebasedata();
  }, [address, load]);
  useEffect(() => {
    getfirebasedata();
  }, []);

  useEffect(() => {}, [selectedImage]);
  const [successAlert, setsuccessAlert] = useState(false);
  const [emailSendAlert, setEmailSendAlert] = useState(false);
  const [userNameError, setUserNameError] = useState(false);

  const saveChanges = async () => {
    if (setReload) setReload(true);
    setLoading(true);

    // Upload image if it's an object
    if (typeof selectedImage === "object") {
      const success = await uploadImg(selectedImage, address);
      if (setReload) setReload(true);
      localStorage.setItem("reload1", "true");
      localStorage.setItem("reload2", "true");
    }

    // Validate username (no spaces, only letters, numbers, ., _)
    if (!/^[a-zA-Z0-9._]+$/.test(formData.userName)) {
      setLoading(false);
      toast.error(
        "Username can only contain letters, numbers, periods (.), and underscores (_). No spaces allowed."
      );
      return;
    }

    // Validate Twitter/X link
    if (
      formData?.userTwiter &&
      !/^https?:\/\/(www\.)?(twitter|x)\.com\/.+/i.test(formData.userTwiter)
    ) {
      toast.warning("Please enter a valid Twitter/X profile link");
      setLoading(false);
      return;
    }

    // Check if any fields were updated
    const isDataUnchanged =
      selectedImage === userData?.image &&
      Object.entries(formData).every(
        ([key, value]) => value === userData?.[key]
      );

    if (isDataUnchanged) {
      setLoading(false);
      return;
    }

    // Ensure username is unique
    const usernameQuery = query(
      collection(firestoredb, "Users"),
      where("userName", "==", formData.userName)
    );
    const querySnapshot = await getDocs(usernameQuery);

    const isUsernameTakenByOtherUser = querySnapshot.docs.some(
      (doc) => doc.id !== address
    );

    if (isUsernameTakenByOtherUser) {
      setLoading(false);
      toast.error("Username already exists. Please choose another one.");
      return;
    }

    // Update Firestore document
    try {
      await setDoc(
        doc(firestoredb, "Users", address),
        {
          userName: formData.userName || address,
          userHeadline: formData.userHeadline || "",
          userAbout: formData.userAbout || "",
          userLocation: formData.userLocation || "",
          userTwiter: formData.userTwiter || "",
          isWhiteListed: formData.isWhiteListed || false,
          isBlackListed: formData.isBlackListed || false,
        },
        { merge: true }
      );

      toast.success("Your profile has been updated");
      getfirebasedata();

      if (setReload) setReload(true);
      localStorage.setItem("reload2", "true");
      reload();
      handleModal();

      setTimeout(() => {
        if (typeof setsuccessAlert === "function") {
          setsuccessAlert(false);
        }
      }, 5000);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }

    getUserData(address);
  };

  const [userisLogged, setUserisLogged] = useState(false);

  const handleEmailunlink = async () => {
    unlinkemail(address);
    setToken(" ");
    setTimeout(() => {
      getfirebasedata();
    }, 1000);

    toast("Email successfully unlinked", {
      toastId: "unlinkedEmail",
    });
    setShow2(false);
  };

  // const handleEditEmail = async () => {
  //   const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  //   if (!emailPattern.test(newEmail)) {
  //     toast.error("Invalid email address");
  //     return;
  //   }

  //   try {
  //     // Encode the user ID using Base64
  //     const encodedUserId = encode(address);

  //     let updatedUserData = await updateUserMail(address, newEmail);
  //     if (updatedUserData) {
  //       const response = await fetch(`${Backend_url}/send-verification`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({ email: newEmail }),
  //       });

  //       const responseData = await response.json();

  //       let res = await updateToken(address, responseData?.token);
  //       setToken(responseData?.token);
  //       toast.success("Email Verification send successfully");
  //       setReload(true);
  //     }
  //   } catch (error) {
  //     console.error("Error handling email update:", error);
  //     toast.error("Error sending verification email");
  //   }

  //   // Refresh user data after a delay
  //   setTimeout(() => {
  //     getfirebasedata();
  //   }, 1000);

  //   setShow(false);
  // };

  useEffect(() => {
    // Compare form data with user data and set changeFields state
    const hasChanges =
      selectedImage !== userData?.image ||
      formData.userName !== userData?.userName ||
      formData.userMail !== userData?.userMail ||
      formData.userAbout !== userData?.userAbout ||
      formData.userHeadline !== userData?.userHeadline ||
      formData.userLocation !== userData?.userLocation ||
      formData.userTwiter !== userData?.userTwiter ||
      formData.isWhiteListed !== userData?.isWhiteListed ||
      formData.isBlackListed !== userData?.isBlackListed;

    setFieldsUpdated(!hasChanges);
  }, [selectedImage, formData, userData]);

  const unlink = () => {
    setShow2(true);
  };
  const handleClose2 = () => {
    setShow2(false);
  };

  const clearFields = () => {
    if (userData) {
      setFormData({
        userName: userData?.userName,
        userAbout: userData?.userAbout,
        userHeadline: userData?.userHeadline,
        userLocation: userData?.userLocation,
        userTwiter: userData?.userTwiter,
        userMail: userData?.userMail,
        isWhiteListed: userData?.isWhiteListed,
        isBlackListed: userData?.isBlackListed,
        verified: userData?.verified,
      });
      setSelectedImage("");

      setSelectedImage(userData?.image);
    }

    handleModal();
    setImgErrorMessage(false);
  };
  return (
    <div>
      <Modal
        show={show}
        hide
        onHide={handleModal}
        fullscreen={true}
        className="edit-profile-modal"
        setHide
      >
        <Modal.Header>
          <div className="modal-back">
            <img
              src={arrowLeft}
              alt="arrowLeft"
              className="cursor-pointer"
              role="button"
              onClick={clearFields}
            />

            <h6>Edit profile</h6>
          </div>
          <Button
            text="Save Changes"
            className="btn-prime btn-primary"
            width="126px"
            height="36px"
            disabled={fieldsUpdated}
            loading={loading}
            onClick={saveChanges}
          />
        </Modal.Header>

        <Modal.Body>
          <div className="body-content">
            <div className="add-profile-img">
              <label className="profile-img " htmlFor="dp">
                {selectedImage && selectedImage != null ? (
                  <img
                    src={
                      typeof selectedImage == "object"
                        ? URL.createObjectURL(selectedImage)
                        : selectedImage
                    }
                    alt="Uploaded"
                    className="img-100 rounded-circle"
                  />
                ) : (
                  <img
                    src={dummyProfile}
                    alt="profile"
                    className="img-100 rounded-circle"
                  />
                )}
                <img src={editpen} alt="edit" className="editpen" />
              </label>
              <div className="d-flex flex-column">
                <label className=" medium add-pp">
                  <label
                    className="medium text-black the-first-div pointer no-text-transform"
                    onClick={handleRemoveProfilePicture}
                  >
                    {selectedImage ? (
                      <img src={minus} alt="minus" />
                    ) : (
                      <img src={add} alt="add" />
                    )}
                    {selectedImage ? " Remove picture" : " Profile picture"}
                  </label>

                  <div className="v-center ">
                    <span>(Max file size: 1mb)</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={tooltip}
                      id="tooltip"
                      disabled
                    >
                      <img src={question} alt="question" />
                    </OverlayTrigger>
                  </div>
                </label>
                <Input
                  type="file"
                  id="dp"
                  className="d-none"
                  onChange={handleImageChange}
                  accept="image/jpeg, image/png"
                />

                {imgErrorMessage ? (
                  <p className="body-medium  warning  w-190 ">
                    <img src={error} alt="error" />
                    Image file size too big. Max 1mb.
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="profile-form">
              {/* user name  */}
              <div className="mb-14">
                <InputBox
                  label="username"
                  // subLabel="Required"
                  placeholder="MyUsername_"
                  type="text"
                  name="userName" // Add name attribute for identification
                  value={
                    formData?.userName === address ? "" : formData?.userName
                  }
                  onChange={handleChange}
                  maxLength={30}
                />
                <p className="warning ps-2">
                  {errorFlags?.userName
                    ? "Username is Empty or has spaces. Please enter a valid username."
                    : ""}
                  {userNameError ? "Username Already exists" : ""}
                </p>
              </div>

              {/* Email Address */}

              {/* <div className="  margin-bottom-lg ">
                <div className="mb-14">
                  <InputBox
                    label="EMAIL ADDRESS"
                    optional="Notifications, sales, bids etc."
                    placeholder="notvalid@gmail.xyz"
                    type="mail"
                    name="userMail" // Add name attribute for identification
                    value={formData.userMail}
                    onChange={handleChange}
                    disabled={userData && userData?.userMail ? "disabled" : ""}
                    pending={
                      userData?.isuserMailSend
                        ? userData?.userMail
                          ? !userData?.verified
                            ? true
                            : false
                          : console.log(
                            "hi how are you man",
                            userData?.userMail
                          )
                        : null // Or you can provide a default value here
                    }
                    verifiedUser={
                      userData?.userMail
                        ? userData?.verified == true
                          ? true
                          : false
                        : console.log("hi how are the man ", userData?.verified)
                    }
                  />
                  {userData?.isuserMailSend && (
                    <div className="d-flex ps-2 align-items-center justify-content-between">
                      {!userData?.verified && userData?.userMail ? (
                        <p className="body-medium mt-3">
                          Please confirm your new email address using the link
                          we sent to <br></br>
                          <span>"{userData?.userMail}"</span>
                        </p>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>

                <div className="mail-confirm-btns">
                  {!userData?.isuserMailSend &&
                    formData?.userMail &&
                    !formData?.verified ? (
                    <Button
                      text="Confirm email"
                      width="165px"
                      height="36px"
                      className="btn-prime btn-primary"
                      onClick={handleConfirmEmail}
                    />
                  ) : (
                    <></>
                  )}

                  {userData?.userMail && userData?.verified ? (
                    <>
                      <Button
                        text="Unlink email"
                        width="120px"
                        height="36px"
                        className="btn-prime btn-secondary"
                        onClick={unlink}
                      />
                      <Button
                        text="Change email"
                        width="120px"
                        height="36px"
                        className="btn-prime btn-primary"
                        onClick={handleShow}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div> */}

              <div className="divider "></div>

              {/* headline for profile */}

              <div className="  margin-bottom-lg mt-50 ">
                <InputBox
                  label="HEADLINE"
                  placeholder={`This headline is more than 40 characters long.`}
                  type="text"
                  maxLength={40}
                  name="userHeadline" // Add name attribute for identification
                  value={formData.userHeadline}
                  onChange={handleChange}
                  optional="A short headline about you"
                />
                <p className="warning ps-2">
                  {/* {errorFlags.userName ? "Please put some headling" : ""} */}
                </p>
                <div className="v-center justify-content-between">
                  {/* {errorMessage ? (
                    <p className="warning w-100">
                      Max. 40 characters for headline
                    </p>
                  ) : (
                    <></>
                  )} */}
                </div>
              </div>

              {/* about profile */}
              <div className="input-form margin-bottom-lg mt-40 ">
                <div className="input-label">
                  <label htmlFor="" className="medium">
                    About
                  </label>
                  <label htmlFor="" className="medium">
                    Tell people about yourself
                  </label>
                </div>

                <Textarea
                  maxLength={500}
                  placeholder="About yourself"
                  onChange={handleChange}
                  name="userAbout"
                  value={formData.userAbout}
                />
              </div>

              {/* location */}
              <div className="  margin-bottom-lg mt-40 ">
                <InputBox
                  label="LOCATION"
                  placeholder="Add location"
                  type="url"
                  name="userLocation" // Add name attribute for identification
                  value={formData.userLocation}
                  onChange={handleChange}
                  maxLength={30}
                />
              </div>

              {/* divider */}

              <div className="divider mt-50 mb-50"></div>

              {/* Social media link */}
              <div className=" margin-bottom-lg mt-40 ">
                <InputBox
                  label="twitter"
                  placeholder="https://x.com/username"
                  type="url"
                  name="userTwiter" // Add name attribute for identification
                  value={formData.userTwiter}
                  onChange={handleChange}
                  // warningMessage="Please enter a valid Twitter URL"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show1}
        onHide={handleClose}
        centered
        // backdrop="static"
        className="email-confirm-modal"
        backdropClassName="modal-backdrop"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label>change email</label>
            {/* <label>unlink email</label> */}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={emailLarge} alt="email" className="mail" />
          <p className="body-large">
            Add your new <span>email address</span>
          </p>

          {/* <p className="body-large">
            Unlink your <span>email address</span>
          </p> */}
          <div className="input-form input-border ">
            <Input
              type="email"
              placeholder={userData?.userMail ? userData?.userMail : ""}
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
            <img src={close} alt="close" />
          </div>
          <p className="body-medium">
            Your old email address will be unlinked.
          </p>
          {/* if email is linked following will show */}

          {/* <p className="body-medium">
            Remember, you can always{" "}
            <span className="text-link-blue cursor-pointer">
              change your email settings.
            </span>
          </p> */}
          <div className="c-mail-btns">
            <Button
              text="Cancel"
              width="156px"
              height="36px"
              className="btn-prime btn-secondary"
              onClick={handleClose}
            />
            <Button
              text="Confirm email"
              width="156px"
              height="36px"
              className="btn-prime btn-primary"
              // onClick={handleEditEmail}
            />
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={show2}
        onHide={handleClose2}
        centered
        // backdrop="static"
        className="email-confirm-modal"
        backdropClassName="modal-backdrop"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <label>unlink email</label>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={emailLarge} alt="email" className="mail" />

          <p className="body-large">
            Unlink your <span>email address</span>
          </p>

          {/* if email is linked following will show */}

          <p className="body-medium">
            Remember, you can always{" "}
            <span
              onClick={() => {
                setShow(true);
                setShow2(false);
                navigate("/account-settings", {
                  state: { location: "profile" },
                });
              }}
              className="text-link-blue cursor-pointer"
            >
              change your email settings.
            </span>
          </p>
          <div className="c-mail-btns">
            <Button
              text="Cancel"
              width="156px"
              height="36px"
              className="btn-prime btn-secondary"
              onClick={handleClose2}
            />
            <Button
              text="Unlink email"
              width="156px"
              height="36px"
              className="btn-prime btn-primary"
              onClick={handleEmailunlink}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditProfile;
