import config from "../config";
import axios from "axios";

// Pin File (Image/Video) to IPFS
const pinFileToIPFS = async (file) => {
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  try {
    const formData = new FormData();

    // Append the file directly since it's already in the correct format
    formData.append("file", file);

    const response = await axios.post(url, formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        pinata_api_key: config.pinata.apiKey,
        pinata_secret_api_key: config.pinata.apiSecret,
      },
    });

    return {
      success: true,
      pinataUrl: `https://lime-famous-octopus-952.mypinata.cloud/ipfs/${response.data.IpfsHash}`,
    };
  } catch (error) {
    console.error("Pinata error:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

// Upload Image to IPFS
const uploadImage = async (imageFile) => {
  try {
    const pinataResponse = await pinFileToIPFS(imageFile);

    if (!pinataResponse.success) {
      throw new Error(pinataResponse.message || "Failed to pin image to IPFS");
    }

    return {
      success: true,
      imageUrl: pinataResponse.pinataUrl,
    };
  } catch (error) {
    console.error("Error in uploadImage:", error);
    return {
      success: false,
      error: error.message,
    };
  }
};

// Upload Video to IPFS
const uploadVideo = async (videoFile) => {
  try {
    const pinataResponse = await pinFileToIPFS(videoFile);

    if (!pinataResponse.success) {
      throw new Error(pinataResponse.message || "Failed to pin video to IPFS");
    }

    return {
      success: true,
      videoUrl: pinataResponse.pinataUrl,
    };
  } catch (error) {
    console.error("Error in uploadVideo:", error);
    return {
      success: false,
      error: error.message,
    };
  }
};

// Pin JSON to IPFS
const pinJSONToIPFS = async (JSONBody) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
  try {
    const response = await axios.post(url, JSONBody, {
      headers: {
        "Content-Type": "application/json",
        pinata_api_key: config.pinata.apiKey,
        pinata_secret_api_key: config.pinata.apiSecret,
      },
    });

    return {
      success: true,
      pinataUrl: `https://lime-famous-octopus-952.mypinata.cloud/ipfs/${response.data.IpfsHash}`,
    };
  } catch (error) {
    console.error("Pinata error:", error);
    return {
      success: false,
      message: error.message,
    };
  }
};

// Upload NFT Metadata to IPFS and then to your backend
const uploadNFT = async (nftData) => {
  try {
    const {
      artistFee,
      artistAddress,
      artistName,
      artName,
      image,
      previewImg,
      videoUrl,
      description,
      traits,
      selectedTags,
      mintedBy,
      selectedCollection,
      selectedCollectionId,
      selectedBlockchain,
      mintedAt,
    } = nftData;

    const metadata = {
      artistFee,
      artistAddress,
      artistName,
      artName,
      image,
      previewImg,
      videoUrl,
      description,
      traits,
      selectedTags,
      mintedBy,
      selectedCollection,
      selectedCollectionId,
      selectedBlockchain,
      mintedAt,
    };

    const pinataResponse = await pinJSONToIPFS(metadata);

    if (!pinataResponse.success) {
      throw new Error(
        pinataResponse.message || "Failed to pin metadata to IPFS"
      );
    }

    return {
      success: true,
      nftUrl: pinataResponse.pinataUrl,
      nftData: metadata,
    };
  } catch (error) {
    console.error("Error in uploadNFTMetadata:", error);
    return {
      success: false,
      error: error.message,
    };
  }
};

// Fetch NFT Metadata from IPFS
const fetchNFTMetadata = async (uri) => {
  try {
    const response = await axios.get(uri);

    if (!response.data) {
      throw new Error("No data received from IPFS");
    }

    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.message,
    };
  }
};

// gmail

export {
  uploadNFT,
  uploadImage,
  uploadVideo,
  pinFileToIPFS,
  pinJSONToIPFS,
  fetchNFTMetadata,
};
