import React, { useEffect, useState } from "react";
import "../../style/main.scss";
import sad from "../../assets/icon/sad-face.svg";
import metamask from "../../assets/icon/metamask.svg";
import wallet from "../../assets/icon/wallet-connect.svg";
import loader from "../../assets/icon/loader-small-white.svg";
import loaderLarge from "../../assets/icon/loader-large.svg";
import art1 from "../../assets/images/artwork-example-5.png";
import art2 from "../../assets/images/artwork-example-3.png";
import art3 from "../../assets/images/artwork-example-2.png";
import userProfile from "../../assets/images/face-5.png";
import userProfile1 from "../../assets/images/face-2.png";
import userProfile2 from "../../assets/images/face-1.png";
import userProfile3 from "../../assets/images/face.svg";
import wantToKnow from "../../assets/icon/question-tooltip.svg";
import { Modal, Offcanvas, OverlayTrigger, Tooltip } from "react-bootstrap";
import wFLR from "../../assets/icon/wrapped-FLR.svg";
import SGB from "../../assets/icon/wrapped-SGB.svg";
import sortby from "../../assets/icon/sort-by.svg";
import verified from "../../assets/icon/verified-artist-small.svg";
import dropdown from "../../assets/icon/chevron-down-extra-small.svg";
import close from "../../assets/icon/close.svg";
import tick from "../../assets/icon/tick-large-black.svg";
import placeholder from "../../assets/images/profile-1.svg";

import Button from "../button";
import ArtistPopUp from "../shared/artistpopup";
import NoContent from "../noContent";
import Web3 from "web3";
import {
  handleNotifications,
  getImageByUsername,
  getAllUsers,
} from "../../firebase/firebase";
import { toast } from "react-toastify";
import { Popover } from "antd";
import marketplaceContractABI from "../../abis/Marketplace/v3/abi.json";
import { useNetwork } from "wagmi";
import { useAccount } from "wagmi";
import { switchNetwork } from "@wagmi/core";
import { useSwitchNetwork } from "wagmi";
import _ from "lodash";
import axios from "axios";
import config from "../../config";
import Backend_url from "../../herokuUrlConfig";
import { useNavigate } from "react-router-dom";
import { fetchNFTMetadata } from "../../Services/nft";

const Offers = (props) => {
  const { chain } = useNetwork();
  const { chains, pendingChainId, switchNetwork } = useSwitchNetwork();

  const { address } = useAccount();

  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(null);

  const tooltip = <Tooltip id="tooltip">List price: 4900929 FLR</Tooltip>;
  const tipDifference = (
    <Tooltip id="differ">
      The difference between your listed price and the offer
    </Tooltip>
  );
  const [show, setShow] = useState(false);
  // transaction process states
  const [offerAlert, setOfferAllert] = useState(true);
  const [offerApproveAlert, setApproveAlert] = useState(false);
  const [offerProcessAlert, setProcessAlert] = useState(false);
  const [offerConfirmAlert, setConfirmAlert] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [reload, setReload] = useState(false);
  // click handles
  const handleShow = (offer) => {
    setSelectedOffer(offer);
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setOfferAllert(true);
    setApproveAlert(false);
    setProcessAlert(false);
    setConfirmAlert(false);
    setSelectedOffer(null);
  };
  // getting username from localhost
  let username = localStorage?.getItem("userName");

  const handleOffer = () => {
    handleAcceptOffer();
  };

  const { marketplace, erc20 } = config;

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;
  const contractABI = marketplaceContractABI;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  const [offers, setOffers] = useState([]);
  const [nftFilteredDetails, setNftFilteredDetails] = useState([]);

  // Initialize web3 with your Ethereum node URL
  // const web3 = new Web3(window.ethereum);
  const web3_coston = new Web3(config.rpc.songbird);
  const web3_coston2 = new Web3(config.rpc.flr);

  const getNftMetadata = async (tokens_uri) => {
    const json = await fetchNFTMetadata(tokens_uri);

    return {
      data: json?.data,
    };
  };

  const getImageByUser = async (username) => {
    let res = await getImageByUsername(username);

    return res;
  };

  const getSelectedUsersForOffers = async (listCount, selectedBlockchain) => {
    const web3_1 = new Web3(window.ethereum);
    const accounts = await web3_1.eth.getAccounts();

    let MarketplaceAddress;
    if (selectedBlockchain === config?.networks?.songbird) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (selectedBlockchain === config?.networks?.flare) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }

    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    const result = await contract.methods.SelectedUser(listCount).call();
    return result;
  };
  const getListedNfts = async () => {
    const contract_coston = new web3_coston.eth.Contract(
      contractABI,
      Marketplace_coston_contractAddress
    );
    const contract_coston2 = new web3_coston2.eth.Contract(
      contractABI,
      Marketplace_coston2_contractAddress
    );

    const res_coston = await contract_coston.methods.getAllListedNfts().call();
    const res_coston2 = await contract_coston2.methods
      .getAllListedNfts()
      .call();

    // Fetch and append bidding history to each offer
    const appendBiddingHistory = async (contract, items) => {
      // Fetch bidding history and filter items
      const updatedItems = await Promise.all(
        items.map(async (item) => {
          const biddingHistory = await contract.methods
            .getBiddingHistory(item?.listCount)
            .call();

          // Check if biddingHistory is empty or meets your criteria for removal
          if (biddingHistory.length === 0) {
            // If there's no bidding history, return null (to be filtered out later)
            return null;
          }

          // Return the item with its bidding history if it's valid
          return { ...item, biddingHistory };
        })
      );

      // Filter out null values (items with no bidding history)
      const filteredItems = updatedItems.filter((item) => item !== null);

      return filteredItems;
    };

    const updatedResCoston1 = await appendBiddingHistory(
      contract_coston,
      res_coston[1]
    );
    const updatedResCoston2 = await appendBiddingHistory(
      contract_coston2,
      res_coston2[1]
    );

    const offer_res = [...updatedResCoston1, ...updatedResCoston2];

    // Filter offers by address

    const filteredOffers = offer_res.filter((item) => {
      const owner = item?.newOwner;

      return owner === address;
    });

    let biddingHistoryWithMetadata = [];

    for (let item of filteredOffers) {
      const { uriData, biddingHistory, listedData, listCount } = item;

      // Fetch the metadata for the uriData
      const metadata = await getNftMetadata(uriData);

      // Fetch the image for each user in the bidding history
      let historyWithMetadata = await Promise.all(
        biddingHistory.map(async (bid) => {
          return {
            ...bid,
            listCount: listCount,
            metadata: metadata.data,
            userImage: null,
            listedData: listedData, // Include listedData in the bidding history
          };
        })
      );

      // Get selected users for offers

      const selectedBlockchain = metadata.data.selectedBlockchain; // Assuming listedData has blockchain info

      const selectedUserResult = await getSelectedUsersForOffers(
        listCount,
        selectedBlockchain
      );

      // Add 'selected' field if applicable and filter the bids
      let selectedBidCount = selectedUserResult > 0 ? selectedUserResult : null;
      if (selectedBidCount) {
        historyWithMetadata = historyWithMetadata.map((bid) => {
          if (bid.bidCount === selectedBidCount) {
            return {
              ...bid,
              selected: true,
              selectedBidCount: selectedBidCount,
            };
          }
          if (bid.bidCount !== selectedBidCount) {
            return {
              ...bid,
              selected: false,
              selectedBidCount: selectedBidCount,
            };
          }

          return bid;
        });
        historyWithMetadata = historyWithMetadata.filter(
          (bid) => bid.selected || bid.bidCount !== selectedBidCount
        );
      }
      historyWithMetadata = historyWithMetadata.filter(
        (bid) => bid.selected || bid.bidCount !== selectedBidCount
      );

      // Add the processed bidding history to the final result
      biddingHistoryWithMetadata.push(...historyWithMetadata);
    }

    const sortedOffers = [...biddingHistoryWithMetadata].sort((a, b) => {
      if (a.withdrawn && !b.withdrawn) return 1; // Move withdrawn items to the bottom
      if (!a.withdrawn && b.withdrawn) return -1; // Keep non-withdrawn items on top
      return 0; // Keep the order unchanged for non-withdrawn and withdrawn items
    });
    setOffers(biddingHistoryWithMetadata);
  };

  useEffect(() => {
    getListedNfts();
    setReload(false);
  }, [reload === true]);

  // ---------------------------------------
  const priceOptions = [
    { value: "currentOffer", label: "Offer" },
    { value: "offerDiffernce", label: "Difference" },
    { value: "offerDate", label: "Date" },
    { value: "offerFrom", label: "From" },
    { value: "offerAction", label: "Action" },
  ];

  const [activeOffer, setActiveOffer] = useState("currentOffer");
  const [activeOfferFilter, setActiveOfferFilter] = useState("All offers");
  const [priceShow, setPriceShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [offerMobileFilter, setOfferMobileFilter] = useState(false);

  const handleIPriceTick = (tick) => {
    setActiveOffer(tick);
    setPriceShow(false);
  };
  const handleOfferTick = (tick) => {
    setActiveOfferFilter(tick);
    setOfferMobileFilter(false);
  };

  // Offer mobile Filter handle
  const offerHandleShow = () => setPriceShow(true);
  const offerHandleHide = () => setPriceShow(false);
  //------------------

  const offerFilter = [
    { value: "Offers made", label: "Offers made" },
    { value: "Offers received", label: "Offers received" },
    { value: "Expired offers", label: "Expired offers" },
    { value: "All offers", label: "All offers" },
  ];

  const showMobileSortFilter = () => {
    setOfferMobileFilter(true);
    setPriceShow(false);
  };
  const hideMobileSortFilter = () => {
    setOfferMobileFilter(false);
    setPriceShow(false);
  };

  function convertUnixTimestampToDate(unixTimestamp) {
    if (unixTimestamp) {
      // Convert Unix timestamp to milliseconds
      const milliseconds = parseInt(unixTimestamp) * 1000;

      // Create a new Date object
      const date = new Date(milliseconds);

      // Get the day and month
      const day = date.toLocaleDateString("en-US", { weekday: "short" });
      const month = date.toLocaleDateString("en-US", { month: "short" });
      // const month = date.toLocaleDateString('en-US', { month: 'short' });

      // Return the formatted date string
      return `${day}, ${month},${date.getFullYear()}`;
    }
  }

  const handleAcceptOffer = async () => {
    const web3_1 = new Web3(window.ethereum);
    // alert(bidCount);
    const accounts = await web3_1.eth.getAccounts();
    let MarketplaceAddress;

    if (
      selectedOffer?.metadata.selectedBlockchain === config?.networks?.songbird
    ) {
      MarketplaceAddress = Marketplace_coston_contractAddress;
    } else if (
      selectedOffer?.metadata.selectedBlockchain === config?.networks?.flare
    ) {
      MarketplaceAddress = Marketplace_coston2_contractAddress;
    }
    const contract = new web3_1.eth.Contract(contractABI, MarketplaceAddress);

    const bidCount = selectedOffer?.bidCount;
    const auctionListId = selectedOffer?.listCount;

    try {
      setLoading(true);
      const transaction = contract.methods.selectUser(auctionListId, bidCount);
      const result = await transaction.send({
        from: accounts[0],
      });

      setReload(true);

      // await getOwner();
      let transactionHash = result?.transactionHash;

      // notification for accepting offer
      handleNotifications(
        selectedOffer.user,
        `offerAccepted`,
        ` ${selectedOffer?.price / 1000000000000000000}`,
        selectedOffer?.metadata?.artName,
        transactionHash,
        selectedOffer?.metadata.selectedBlockchain
      );
      setOfferAllert(false);
      setApproveAlert(true);

      setTimeout(() => {
        setApproveAlert(false);
        setProcessAlert(true);
      }, 2500);

      setTimeout(() => {
        setApproveAlert(false);
        setProcessAlert(false);
        setConfirmAlert(true);
      }, 7000);
      setLoading(false);
    } catch (error) {
      toast.error("Transaction Failed!");
      setLoading(false);
    }
  };
  // const handleSwitchChain = async () => {
  //   const switchTo = chains.find(
  //     (c) => c.name === nftData[0]?.metadata.data.selectedBlockchain
  //   );
  //   if (switchTo) {
  //     switchNetwork?.(switchTo.id);
  //   }
  // };

  const handleSwitchChain = async (item) => {
    const switchTo = chains.find(
      (c) => c.name === item?.metadata?.selectedBlockchain
    );
    if (switchTo) {
      switchNetwork?.(switchTo.id);
    }
  };

  const [sgb, setSgb] = useState(0);
  const [flr, setFlr] = useState(0);

  let key = process.env.REACT_APP_CRYPTO_KEY;

  const fetchSgbPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=SGB&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["SGB"] && data["SGB"].USD !== undefined) {
        const priceInUSD = data["SGB"].USD;
        setSgb(priceInUSD.toFixed(2));
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  const fetchFlrPrice = async () => {
    try {
      const response = await axios.get(
        `https://min-api.cryptocompare.com/data/pricemulti?fsyms=FLR&tsyms=USD&api_key=${key}`
      );
      const data = response.data;
      if (data["FLR"] && data["FLR"].USD !== undefined) {
        const priceInUSD = data["FLR"].USD;
        setFlr(priceInUSD.toFixed(2));
      } else {
        return;
      }
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    // Throttle the functions to limit API calls to once per minute
    const throttledFetchSgbPrice = _.throttle(fetchSgbPrice, 60000);
    const throttledFetchFlrPrice = _.throttle(fetchFlrPrice, 60000);

    // Call the functions immediately
    throttledFetchSgbPrice();
    throttledFetchFlrPrice();

    // Set intervals to call the functions every minute
    const intervalSgb = setInterval(throttledFetchSgbPrice, 60000);
    const intervalFlr = setInterval(throttledFetchFlrPrice, 60000);

    // Clear intervals on component unmount
    return () => {
      clearInterval(intervalSgb);
      clearInterval(intervalFlr);
    };
  }, []);

  const [userData, setUserData] = useState([]);
  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();
      setUserData(users);
    };
    fetchUserData();
  }, []);
  const getNamebyAddress = () => {
    if (address && !props?.id) {
      return "You haven't ";
    } else {
      const user = userData?.find((user) => user?.id === props?.id);
      const artistName = user ? user?.userName : null;

      if (!artistName) {
        return "@" + props?.id + " hasn't ";
      } else {
        return "@" + artistName + " hasn't ";
      }
    }
  };

  return (
    <div>
      <div className="offers pb-5">
        {/* Filtered offers based on the conditions */}
        {(() => {
          const filteredOffers = offers.filter(
            (item) => item.selected !== false && item?.user !== address
          );

          if (filteredOffers.length < 1) {
            return (
              <div className="no-content pt-5 mt-5">
                <p className="body-large">
                  {getNamebyAddress()} received any <span>offers</span> yet.
                </p>
              </div>
            );
          }

          return (
            <div className="offer-table">
              {/* Sorting filter for mobile and desktop can be added back here */}

              <div className="offer-table-content">
                {/* table heading */}
                <div className="offer-table-head">
                  <label className="small v-center c1 ">ITEM</label>
                  <label className="small v-center c2 hide-on-mobile">
                    Offer
                  </label>
                  <label className="small v-center c4 hide-on-mobile">
                    Date
                  </label>
                  <label className="small v-center c5 hide-on-mobile">
                    From
                  </label>
                  <label className="small v-center c6 hide-on-mobile"></label>
                </div>

                <div className="offer-table-body">
                  {filteredOffers.map((item, index) => {
                    return (
                      <div className="offer-table-content" key={index}>
                        {/* Item Name */}
                        <div className="c1 art">
                          <div
                            onClick={() =>
                              navigate(
                                `/artwork/eth/${item?.metadata?.selectedCollectionId}/${item?.listedData?.tokenId}`
                              )
                            }
                            className="art-img"
                          >
                            <img
                              src={
                                item?.metadata?.image ||
                                item?.metadata?.previewImg
                              }
                              alt="art"
                              className="img-100"
                            />
                          </div>
                          <label
                            onClick={() =>
                              navigate(
                                `/artwork/eth/${item?.metadata?.selectedCollectionId}/${item?.listedData?.tokenId}`
                              )
                            }
                            className="text-black"
                          >
                            {item?.metadata?.artName}
                          </label>
                        </div>

                        {/* Offers */}
                        <div
                          className={`c2 offer d-flex align-items-center ${
                            activeOffer !== "currentOffer" && "item-hide"
                          }`}
                        >
                          <img
                            src={
                              item?.metadata?.selectedBlockchain ===
                              config?.networks?.songbird
                                ? SGB
                                : wFLR
                            }
                            alt={
                              item?.metadata?.selectedBlockchain ===
                              config?.networks?.songbird
                                ? "SGB"
                                : "wFLR"
                            }
                            className="img-fluid"
                          />
                          <OverlayTrigger placement="top" overlay={tooltip}>
                            <label className="text-black">
                              {item?.price / 1000000000000000000}
                            </label>
                          </OverlayTrigger>
                          <p>
                            {item?.metadata?.selectedBlockchain ===
                            config?.networks?.songbird
                              ? "SGB"
                              : "FLR"}
                          </p>
                        </div>

                        {/* Date */}
                        <div
                          className={`v-center c4 ${
                            activeOffer !== "offerDate" && "item-hide"
                          }`}
                        >
                          <label className="text-black text-capitalize">
                            {convertUnixTimestampToDate(item.biddingTime)}
                          </label>
                        </div>

                        {/* Offer From */}
                        <div
                          className={`v-center c5 fromOffer ${
                            activeOffer !== "offerFrom" && "item-hide"
                          }`}
                        >
                          <div className="art-img">
                            <img
                              src={item?.userImage || placeholder}
                              alt="art"
                              className="img-100 rounded-circle"
                            />
                          </div>
                          <Popover
                            placement="top"
                            trigger="hover"
                            content={
                              <ArtistPopUp
                                userProfile={item?.userImage}
                                verified={verified}
                                artistName={item?.userName}
                              />
                            }
                          >
                            <p
                              onClick={() => navigate(`/profile/${item?.user}`)}
                              className="text-black fw-semibold cursor-pointer text-lowercase"
                            >
                              {item?.userName}
                            </p>
                          </Popover>
                        </div>

                        {/* Offer Status */}
                        <div className="v-center c6">
                          {item.withdrawn ? (
                            <Button
                              text="Withdrawn"
                              className="btn-prime btn-secondary"
                              width="121px"
                              height="36px"
                              disabled
                            />
                          ) : item?.metadata?.selectedBlockchain ===
                            chain.name ? (
                            <Button
                              text={item.selected ? "Pending" : "Accept"}
                              className={
                                item.selected
                                  ? "btn-prime btn-ternary"
                                  : "btn-prime btn-primary"
                              }
                              width="121px"
                              height="36px"
                              disabled={item.selected}
                              onClick={() => handleShow(item)}
                            />
                          ) : (
                            <Button
                              text={`Switch to ${item?.metadata?.selectedBlockchain}`}
                              className="btn-prime btn-primary"
                              width="121px"
                              height="36px"
                              onClick={() => handleSwitchChain(item)}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })()}
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        // backdrop="static"
        keyboard={false}
        className="offer-modal "
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {/* offer-accept-alert */}
            {offerAlert ? <label className="medium ">ACCEPT</label> : <></>}

            {/* While approving offer alert */}
            {offerApproveAlert ? (
              <label className="medium">approve</label>
            ) : (
              <></>
            )}

            {/* While Processing... offer alert */}
            {offerProcessAlert ? (
              <label className="medium ">Processing...</label>
            ) : (
              <></>
            )}

            {/* When offer is confirmed */}
            {offerConfirmAlert ? (
              <label className="medium ">confirmed</label>
            ) : (
              <></>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* offer-accept-alert */}
          {offerAlert ? (
            <div className="accept-alert ">
              <div className="offer-modal-top">
                <div className="labels d-flex justify-content-between">
                  <label className="medium text-black ">item</label>
                  <label className="medium text-black ">subtotal</label>
                </div>

                <div className="d-flex justify-content-between align-items-center top-content">
                  <div className="collections d-flex align-items-center">
                    <div className="collection-img me-3">
                      <img
                        src={selectedOffer?.metadata?.image}
                        alt="collection"
                        className="img-100"
                      />
                    </div>
                    <div className="collection-name d-flex flex-column">
                      <label className="">
                        {selectedOffer?.metadata?.selectedCollection}
                      </label>
                      <label className="text-black">
                        {selectedOffer?.metadata?.artName}
                      </label>
                    </div>
                  </div>

                  <div className="value-wrapper">
                    <label htmlFor="" className="text-black">
                      {selectedOffer?.metadata?.selectedBlockchain ===
                      config?.networks?.songbird ? (
                        <img src={SGB} alt="wFLR" className="img-fluid " />
                      ) : (
                        <img src={wFLR} alt="wFLR" className="img-fluid " />
                      )}
                      {selectedOffer?.price / 1000000000000000000}
                    </label>
                    <p className="body-medium mt-1 text-medium-grey">
                      {selectedOffer?.metadata?.selectedBlockchain ===
                      config?.networks?.songbird
                        ? `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            sgb
                          ).toFixed(2)})`
                        : `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            flr
                          ).toFixed(2)})`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="offer-fee">
                <label className="medium text-black ">fees</label>
                <div className="fee">
                  <label className="medium text-light-grey text-capitalize w-100">
                    Catalyst Fee
                    <img src={wantToKnow} alt="question" />
                    <div className="divider"></div>
                  </label>
                  <label className="medium text-light-grey">5%</label>
                </div>
                <div className="fee">
                  <label className="medium text-light-grey text-capitalize w-100">
                    Creator Royalty
                    <img src={wantToKnow} alt="question" />
                    <div className="divider2"></div>
                  </label>
                  <label className="medium text-light-grey">10.0%</label>
                </div>
              </div>
              <div className="offer-modal-bottom">
                <label className="text-black">Total Earnings</label>
                <p className="body-extra-small text-light-grey">
                  Your earnings after fees
                </p>
                <div className="value-wrapper">
                  {selectedOffer?.metadata?.selectedBlockchain ===
                  config?.networks?.songbird ? (
                    <img src={SGB} alt="wFLR" className="img-fluid " />
                  ) : (
                    <img src={wFLR} alt="wFLR" className="img-fluid " />
                  )}{" "}
                  <label htmlFor="" className="text-black">
                    {selectedOffer?.price / 1000000000000000000}
                  </label>
                  <p className="body-medium mt-1 text-medium-grey">
                    <p className="body-medium mt-1 text-medium-grey">
                      {selectedOffer?.metadata?.selectedBlockchain ===
                      config?.networks?.songbird
                        ? `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            sgb
                          ).toFixed(2)})`
                        : `($${(
                            (selectedOffer?.price / 1000000000000000000) *
                            flr
                          ).toFixed(2)})`}
                    </p>
                  </p>
                </div>
                <div className="accept-offer">
                  <Button
                    text="Accept offer"
                    className="btn-prime btn-primary"
                    width="146px"
                    height="36px"
                    loading={loading}
                    onClick={handleOffer}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* While approving offer alert */}

          {/* ------------------------------------------------------------------------- */}

          {/* This modal should only show if this hasn’t already been confirmed earlier? 
          Need to check with developers how this logic should work and when these approvals 
          should happen. */}

          {/* ------------------------------------------------------------------------- */}

          {offerApproveAlert ? (
            <div className="offer-approve-alert">
              <label htmlFor="" className="medium text-black">
                Approve your WFLR for transfer
              </label>
              <p className="approve-offer-details body-medium">
                To approve The Catalyst to trade this token, you must first
                complete a free (plus gas) transaction. Confirm it in your
                wallet and keep this tab open. You might notice a very large
                number being requested for approval - this is simply the maximum
                amount, meaning you’ll never have to do this approval again. It
                also doesn’t allow The Catalyst to transfer that amount to you -
                the amount you sign in the next step is all that can be traded
                on your behalf.
                <span className="text-medium-grey "> Read more</span>
                <a href="" className="body-medium text-link-blue">
                  {" "}
                  here.
                </a>
              </p>
              <hr />
              <div className="approve-wallets">
                <img src={metamask} alt="metamask" />
                <img src={wallet} alt="wallet" />
              </div>
              <label className="text-black w-100 text-center">
                Waiting for blockchain confirmation…
              </label>
              <Button
                className="btn-prime btn-primary m-auto loader-btn  "
                width="138px"
                height="36px"
                imageSrc={loader}
              />
            </div>
          ) : (
            <></>
          )}

          {/* When offer is in process */}
          {offerProcessAlert ? (
            <div className="offer-processing-alert ">
              <div></div>
              <div className="text-center">
                <img src={loaderLarge} alt="loader" className="rotate-360 " />
              </div>
              <div>
                <p className="text-medium0grey body-medium text-center">
                  Please wait…
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          {/* When offer is confirmed */}
          {offerConfirmAlert ? (
            <div className="offer-confirmed ">
              <p className="text-center">
                Success! You just sold{" "}
                <label className="text-black">
                  {" "}
                  {selectedOffer?.metadata?.artName}.
                </label>
              </p>
              <div className="artwork-img">
                <img
                  src={selectedOffer?.metadata?.image}
                  alt="artwork"
                  className="img-100"
                />
              </div>
              <div className="transaction">
                <p className="body-medium text-center text-link-blue  text-decoration-underline fw-500">
                  View transaction on the blockchain
                </p>
                <p className="body-small text-center text-link-blue fw-500 wallet-address">
                  0x566c…0687
                </p>
                <Button
                  text="Finished"
                  className="btn-prime btn-primary "
                  width="146px"
                  height="36px"
                  onClick={handleClose}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal.Body>
      </Modal>

      {/* List Price menu */}
      <Offcanvas
        show={priceShow}
        onHide={offerHandleHide}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">change</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={offerHandleHide}
              />
            </div>
          </div>

          <div className="share">
            {priceOptions.map((option) => (
              <label
                key={option.value}
                className={`no - text - transform h - 64 text - black ${
                  activeOffer === option.value ? "fw-bold" : "fw-normal"
                } `}
                onClick={() => handleIPriceTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${
                    activeOffer === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                  } `}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>

      {/* Offer Sorting Filter mobile menu */}
      <Offcanvas
        show={offerMobileFilter}
        onHide={hideMobileSortFilter}
        placement="bottom"
        className="sub-menu-offcanvas"
      >
        <div className="more-menu-sm price-more-menu">
          <div className="menu-head">
            <label className="text-black">sort by</label>
            <div className="close-btn cursor-pointer">
              <img
                src={close}
                alt="close"
                className="img-24"
                onClick={hideMobileSortFilter}
              />
            </div>
          </div>

          <div className="share">
            {offerFilter.map((option) => (
              <label
                key={option.value}
                className={`no - text - transform h - 64 text - black ${
                  activeOfferFilter === option.value ? "fw-bold" : "fw-normal"
                } `}
                onClick={() => handleOfferTick(option.value)}
              >
                {option.label}
                <img
                  src={tick}
                  alt="tick"
                  className={`${
                    activeOfferFilter === option.value
                      ? "active opacity-100"
                      : "opacity-0"
                  } `}
                />
              </label>
            ))}
          </div>
        </div>
      </Offcanvas>
    </div>
  );
};

export default Offers;
