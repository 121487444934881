import React, { useEffect, useState, useContext } from "react";
import "../../style/main.scss";
import Button from "../button";
import star24 from "../../assets/icon/spiked-circle/black/24px.svg";
import location from "../../assets/icon/location.svg";
import twitter from "../../assets/icon/twitter.svg";
import arrow from "../../assets/icon/chevron-down-extra-small.svg";
import EditProfile from "./editProfile";
import { getUserData, getAllUsers } from "../../firebase/firebase";
import { useAccount } from "wagmi";
import marketplaceContractABI from "../../abis/Marketplace/abi.json";
import Web3 from "web3";
import { useNetwork } from "wagmi";
import config from "../../config";
import { Skeleton, Row, Col } from "antd";
// import AppContext from "antd/es/app/context";
import { NFTContext } from "../context/appContext";

const ProfileTab = ({
  id,
  accountAddress,
  userDataByUserName,
  setReload,
  reload,
}) => {
  // const { address: accountAddress } = useAccount();
  const [address, setAddress] = useState(accountAddress);
  const [loading, setLoading] = useState(false);
  const [fetchingCounters, setFetchingCounters] = useState(false);
  useEffect(() => {
    if (!id) {
      setAddress(accountAddress);
    } else {
      setAddress(id);
    }
  }, [accountAddress, id]);
  const [User, setUser] = useState([]);
  const { collectedNumber } = useContext(NFTContext);

  useEffect(() => {
    const fetchUserData = async () => {
      const users = await getAllUsers();

      setUser(users);
    };
    fetchUserData();
  }, []);
  const [showBidModal, setShowBidModal] = useState(false);
  const [show1, setShow1] = useState(false);
  const [collected, setCollected] = useState(0);
  const [sold, setSold] = useState(0);
  const [userData, setUserData] = useState("");
  const [isWhitelisted, setIsWhitelisted] = useState(false);

  const handleBidModal = () => {
    setShowBidModal((prev) => !prev);
  };

  // network name
  const { chain } = useNetwork();

  // contract address of marketplace

  const { marketplace, erc20 } = config;

  const Marketplace_coston_contractAddress = marketplace.coston;
  const Marketplace_coston2_contractAddress = marketplace.coston2;

  const coston_Token = erc20.coston;
  const coston2_Token = erc20.coston2;
  // marketplace contract address
  const contractABI = marketplaceContractABI;

  const getCollected = async () => {
    setFetchingCounters(true);
    try {
      // Initialize Web3 instances
      const web3Coston2 = new Web3(config.rpc.flr);
      const web3Coston = new Web3(config.rpc.songbird);

      // Initialize contracts
      const contractCoston2 = new web3Coston2.eth.Contract(
        contractABI,
        Marketplace_coston2_contractAddress
      );
      const contractCoston = new web3Coston.eth.Contract(
        contractABI,
        Marketplace_coston_contractAddress
      );

      // Fetch data from both contracts
      const [
        resultCoston2Sold,
        resultCostonSold,
        resultCoston2Buy,
        resultCostonBuy,
      ] = await Promise.all([
        contractCoston2.methods.userSoldRecord(address).call(),
        contractCoston.methods.userSoldRecord(address).call(),
        contractCoston2.methods.userBuyRecord(address).call(),
        contractCoston.methods.userBuyRecord(address).call(),
      ]);

      const soldCoston2 = parseFloat(resultCoston2Sold) || 0;
      const soldCoston = parseFloat(resultCostonSold) || 0;
      const collectedCoston2 = parseFloat(resultCoston2Buy) || 0;
      const collectedCoston = parseFloat(resultCostonBuy) || 0;

      // Combine results
      const combinedSold = soldCoston2 + soldCoston;
      const combinedCollected = collectedCoston2 + collectedCoston;

      setSold(combinedSold);
      setCollected(combinedCollected);
    } catch (error) {
      return;
    } finally {
      setFetchingCounters(false);
    }
  };

  useEffect(() => {
    getCollected();
  }, [address]);

  const handleShowBio = () => {
    setShow1(!show1);
  };

  // getting data from firebase
  const getfirebasedata = async () => {
    setLoading(true);
    try {
      const data = await getUserData(address);
      if (data) {
        setIsWhitelisted(data?.isWhiteListed);
      }
      setUserData(data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (address) {
      getfirebasedata();
      setReload(false);
    }
  }, [address, userDataByUserName, reload]);

  // storing username in localstorage
  const username = userData?.userName;
  localStorage.setItem("userName", username);

  const getNamebyAddress = () => {
    if (!id) {
      return "You haven't ";
    }
    const user = User?.find((user) => user?.id === id);
    const artistName = user?.userName;

    if (!artistName) {
      // return "@" + id + " hasn't ";
      return (
        <>
          <strong>@{id}</strong> hasn't
        </>
      );
    } else {
      const truncatedName = artistName;
      //return "@" + truncatedName + " hasn't ";
      return (
        <>
          <strong>@{truncatedName}</strong> hasn't
        </>
      );
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "30px",
          }}
        >
          {/* Left Section - About */}
          <div style={{ flex: 1, maxWidth: "300px" }}>
            <Skeleton.Input
              active
              size="small"
              style={{ width: "80px", marginBottom: "8px" }}
            />{" "}
            {/* ABOUT Title */}
            <Skeleton.Input
              active
              size="small"
              style={{ width: "200px", marginBottom: "4px" }}
            />
            <Skeleton.Input active size="small" style={{ width: "250px" }} />
          </div>

          {/* Right Section - Stats */}
          <div style={{ display: "flex", gap: "20px" }}>
            {/* Skeleton Circle 1 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Skeleton.Avatar active size={64} shape="circle" />
              <Skeleton.Input
                active
                size="small"
                style={{ width: "80px", height: "16px" }}
              />
            </div>
            {/* Skeleton Circle 2 */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <Skeleton.Avatar active size={64} shape="circle" />
              <Skeleton.Input
                active
                size="small"
                style={{ width: "80px", height: "16px" }}
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="profile-about">
              <div className="left-content">
                <div className="about-head">
                  <label>
                    about <img src={star24} alt="star" />
                  </label>
                  {userDataByUserName ? (
                    <p className="body-small">
                      {userDataByUserName?.userLocation === "" ? null : (
                        <>
                          {userDataByUserName?.userLocation}
                          <span>
                            <img src={location} alt="location" />
                          </span>
                        </>
                      )}
                    </p>
                  ) : (
                    <p className="body-small">
                      {userData?.userLocation ? (
                        <>
                          {userData?.userLocation}
                          <span>
                            <img src={location} alt="location" />
                          </span>
                        </>
                      ) : null}
                    </p>
                  )}
                </div>

                {/* if profile is not filled */}
                {userDataByUserName ? (
                  <>
                    <div className="about-details ">
                      <p
                        className="body-large fw-normal"
                        // style={{
                        //   whiteSpace: "pre-wrap",
                        //   overflowWrap: "break-word",
                        //   wordWrap: "break-word",
                        //   wordBreak: "break-all",
                        // }}
                      >
                        {userDataByUserName?.userAbout == "" ? (
                          <>{getNamebyAddress()} added a bio yet.</>
                        ) : (
                          <>{userDataByUserName?.userAbout}</>
                        )}
                      </p>
                    </div>
                  </>
                ) : userData?.userAbout ? (
                  // userData.userAbout
                  <div className="about-details ">
                    {show1 ? (
                      <>
                        <p
                          // style={{
                          //   whiteSpace: "pre-wrap",
                          //   overflowWrap: "break-word",
                          //   wordWrap: "break-word",
                          //   wordBreak: "break-all",
                          // }}
                          className="body-medium"
                        >
                          {userData.userAbout}
                        </p>
                        <div className="readmore-layer ">
                          <div className="divider full">
                            <Button
                              text="READ LESS"
                              width="115px"
                              height="25px"
                              className="btn-prime btn-secondary read-btn"
                              imageSrc={arrow}
                              onClick={handleShowBio}
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <p
                          className="body-medium"
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                            wordBreak: "break-all",
                          }}
                        >
                          {userData?.userAbout?.slice(0, 400)}
                        </p>
                        <div className="readmore-layer ">
                          <div className="divider truncated">
                            <Button
                              text="READ MORE"
                              width="115px"
                              height="25px"
                              className="btn-prime btn-secondary read-btn"
                              imageSrc={arrow}
                              onClick={handleShowBio}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {userData?.userTwiter && (
                      <div className="social-icons">
                        <a
                          href={
                            userData?.userTwiter ? userData.userTwiter : "#"
                          }
                          target="_blank"
                        >
                          <img src={twitter} alt="twitter" />
                        </a>
                      </div>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="user-bio">
                      <p className="body-large ">
                        {getNamebyAddress()}added a bio yet.
                      </p>
                      {userDataByUserName !== null && (
                        <Button
                          text="Add a bio"
                          className="btn-prime btn-secondary"
                          height="36px"
                          width="126px"
                          onClick={handleBidModal}
                        />
                      )}
                    </div>
                  </>
                )}
                {userDataByUserName?.userTwiter && (
                  <div className="about-details m-0 p-0">
                    <div className="social-icons">
                      <a href={userDataByUserName?.userTwiter} target="_blank">
                        <img src={twitter} alt="Twitter" />
                      </a>
                    </div>
                  </div>
                )}
                {/* if profile is filled */}
              </div>

              {/* Total collect and sold arts */}
              {fetchingCounters && !collected ? (
                <>
                  <Row justify="center" align="middle" gutter={[32, 16]}>
                    {/* First Circle */}
                    <Col>
                      <div style={{ textAlign: "center" }}>
                        <Skeleton.Avatar active size={80} shape="circle" />
                        <div style={{ marginTop: "8px" }}>
                          <Skeleton.Input
                            style={{ width: 100 }}
                            active
                            size="small"
                          />
                        </div>
                      </div>
                    </Col>
                    {/* Second Circle */}
                    <Col>
                      <div style={{ textAlign: "center" }}>
                        <Skeleton.Avatar active size={80} shape="circle" />
                        <div style={{ marginTop: "8px" }}>
                          <Skeleton.Input
                            style={{ width: 100 }}
                            active
                            size="small"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <div className="right-content">
                  <div className="counter created">
                    <div className="value">
                      <h5>0</h5>
                    </div>
                    <label className="small">Created</label>
                  </div>{" "}
                  <div className="counter">
                    <div className="value">
                      <h5>{collectedNumber || 0}</h5>
                    </div>
                    <label className="small">collected</label>
                  </div>
                  <div className="counter">
                    <div className="value">
                      <h5>{sold}</h5>
                    </div>
                    <label className="small">sold</label>
                  </div>
                </div>
              )}
            </div>

            <EditProfile
              show={showBidModal}
              handleModal={handleBidModal}
              setReload={setReload}
            />
          </div>
        </>
      )}
    </>
  );
};

export default ProfileTab;
