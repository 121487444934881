import React, { useState, useEffect, useContext } from "react";
import Headers from "../components/profile/header";
import ProfileTabs from "../components/profile/profileTabs";
import { useParams, useNavigate } from "react-router-dom";
import useScrollToTop from "../customHooks/scrollToTop";
import {
  getUserData,
  getFollowersByAddress,
  getFollowingByAddress,
  checkIfFollowed,
} from "../firebase/firebase";
import { useAccount } from "wagmi";
import { useLocation } from "react-router-dom";
import { NFTContext } from "../components/context/appContext";
import { set } from "lodash";
const Profile = () => {
  const navigate = useNavigate();
  const { address: accountAddress } = useAccount();
  const { address } = useParams();
  const location = useLocation();
  const [refreshFollow, setRefreshFollow] = useState(false);

  const [id, setId] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [userDataByUserName, setUserDataByUserName] = useState("");
  const [profReload, setProfReload] = useState(false);
  const [followersData, setFollowersData] = useState({ count: 0, data: [] });
  const [followingData, setFollowingData] = useState({ count: 0, data: [] });
  const [selectedTab, setSelectedTab] = useState(null);
  const { reloadProfile } = useContext(NFTContext);
  const [load, setLoad] = useState(false);
  const [fromSpotlight, setFromSpotlight] = useState(false);
  const [fetchingData, setFetchingData] = useState(null);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (location?.state?.loc == "hero") {
      setFromSpotlight(true);
    }
    setSelectedTab(location.state?.tab);
  }, [location]);

  const checkIfBlackListed = async (adr) => {
    try {
      // Assume getUserData fetches user data from a service or API.
      let res = await getUserData(adr);
      setUserData(res);
    } catch (error) {
      console.error("An error occurred:", error);
      return false; // or throw the error if you want to handle it higher up
    }
  };

  useEffect(() => {
    checkIfBlackListed(address);
    checkIfBlackListed(id);
    checkIfBlackListed(accountAddress);
  }, [address, id, accountAddress]);

  useEffect(() => {
    if (address) {
      const formattedAddress = address.toLowerCase().trim();
      const formattedAccountAddress = accountAddress?.toLowerCase().trim();
      if (formattedAddress !== formattedAccountAddress) {
        setId(address);
      }
      if (formattedAddress === formattedAccountAddress) {
        setUserAddress(address);
        setFromSpotlight(false);
        setId(null);
        setLoad(true);
      }
      if (!accountAddress || accountAddress === undefined) {
        setId(address);
        setLoad(true);
      }
    }
  }, [address, accountAddress]);

  useEffect(() => {
    if (!window.ethereum) {
      return;
    }
    const handleAccountsChanged = async (accounts) => {
      try {
        if (!accounts || accounts.length === 0) return;
        const newAccountAddress = accounts[0].toLowerCase().trim();
        const currentAddress = userAddress || address;

        if (newAccountAddress !== currentAddress && !fromSpotlight) {
          await navigate(`/profile/${newAccountAddress}`);
          setLoad(true);
          setUserAddress(newAccountAddress);
          setId(null);
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };

    window.ethereum.on("accountsChanged", handleAccountsChanged);

    return () => {
      window.ethereum.removeListener("accountsChanged", handleAccountsChanged);
    };
  }, [navigate, userAddress, address, fromSpotlight, window]);

  useEffect(() => {
    const fetchData = async () => {
      if (!accountAddress) {
        setFetchingData(true);
        const res = await getUserData(id);
        setUserDataByUserName(res);

        const followersRes = await getFollowersByAddress(id);
        setFollowersData(followersRes || { count: 0, data: [] });

        const followingRes = await getFollowingByAddress(id);
        setFollowingData(followingRes || { count: 0, data: [] });
        setFetchingData(false);
      } else {
        try {
          if (
            id &&
            id != null &&
            accountAddress.toLowerCase() !== address.toLowerCase()
          ) {
            setFetchingData(true);
            const res = await getUserData(id);
            setUserDataByUserName(res);

            const followersRes = await getFollowersByAddress(id);
            setFollowersData(followersRes || { count: 0, data: [] });

            const followingRes = await getFollowingByAddress(id);
            setFollowingData(followingRes || { count: 0, data: [] });
            setFetchingData(false);
          } else if (accountAddress) {
            setFetchingData(true);
            const res = await getUserData(accountAddress);
            setUserDataByUserName(res);

            const followersRes = await getFollowersByAddress(accountAddress);
            setFollowersData(followersRes || { count: 0, data: [] });

            const followingRes = await getFollowingByAddress(accountAddress);
            setFollowingData(followingRes || { count: 0, data: [] });
            setFetchingData(false);
          }
        } catch (error) {
          return;
        }
      }
    };

    fetchData();
  }, [id, accountAddress, reloadProfile, refreshFollow]);

  useScrollToTop();

  return (
    <div>
      <div className="pb-4 profile-page">
        <Headers
          id={id}
          fetchingData={fetchingData}
          setUserDataByUserName={setUserDataByUserName}
          userDataByUserName={userDataByUserName}
          reload={profReload}
          setReload={setProfReload}
          address={userAddress}
          followersData={followersData}
          followingData={followingData}
          setRefreshFollow={setRefreshFollow}
          refreshFollow={refreshFollow}
        />
        <ProfileTabs
          id={id}
          setUserDataByUserName={setUserDataByUserName}
          userDataByUserName={userDataByUserName}
          reload={profReload}
          setReload={setProfReload}
          address={userAddress}
          selectedTab={selectedTab}
          load={load}
        />
      </div>
    </div>
  );
};

export default Profile;
